import React from "react";
import { StateConsumer } from "../../context";
import CreateUser from "./CreateUser";
import WaitForStart from "./WaitForStart";

class Lobby extends React.Component {
  render() {
    return (
      <StateConsumer>
        {state => {
          if (state.workshop.student.id === null) {
            return <CreateUser />;
          } else {
            return <WaitForStart />;
          }
        }}
      </StateConsumer>
    );
  }
}

export default Lobby;
