import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { FriendsFonts } from "../../clients/Teacher/theme";

interface ListItem {
  label: string;
  text: string;
}

interface Props {
  items: ListItem[];
  align: "left" | "right";
  font?: "arial" | "capitaine";
  customSize?: number;
}

const Label = styled.div`
  padding: 0 2rem;
  margin-top: -0.3rem;

  > h5 {
    text-align: ${(props: { align: string }) => props.align};
    width: 100%;
    white-space: nowrap;
  }
`;

export const List: React.SFC<Props> = props => {
  return (
    <table>
      <tbody>
        {props.items.map(item => (
          <tr key={item.label}>
            <td>
              <Label align={props.align || "right"}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{
                    fontSize: props.customSize || undefined
                  }}
                >
                  {item.label}
                </Typography>
              </Label>
            </td>
            <td>
              <Typography
                paragraph
                style={{
                  margin: 0,
                  fontFamily:
                    props.font === "capitaine"
                      ? FriendsFonts.Capitaine
                      : FriendsFonts.Arial
                }}
              >
                {item.text}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
