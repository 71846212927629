import { Grid, Typography } from "@material-ui/core";
import { FyraHörn } from "friends-shared";
import React from "react";
import { List } from "../../../../../../../shared/components/List";
import { useCurrentSituationIndex } from "../../../../../hooks";
import CompletedStudents from "../../common/CompletedStudents";
import { Dashboard } from "../../common/Dashboard";

interface Props {
  slide: FyraHörn.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  const situation = useCurrentSituationIndex();

  return (
    <Dashboard steg={props.slide}>
      <Grid item xs={7}>
        <Typography
          className="typography-bold"
          variant="h4"
          color="primary"
          gutterBottom
        >
          Situation {situation}
        </Typography>

        <Typography gutterBottom paragraph>
          {props.slide.questions[0].text}
        </Typography>

        <Typography
          className="typography-bold"
          variant="h4"
          color="primary"
          style={{ marginTop: "2rem" }}
          gutterBottom
        >
          Svarsalternativ
        </Typography>

        <List
          align="right"
          items={Object.values(props.slide.questions[0].areas).map(area => ({
            label: area.label,
            text: area.text
          }))}
        />
      </Grid>

      <Grid item xs={5}>
        <CompletedStudents slide={props.slide} />
      </Grid>
    </Dashboard>
  );
};
