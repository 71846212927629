import { Typography } from "@material-ui/core";
import { FyraHörn, Övningstyp } from "friends-shared";
import React from "react";
import { Assets } from "../../../../../../../shared/assets";
import { getAsset } from "../../../../../../../shared/util/clients";
import {
  useCurrentSituationIndex,
  useCurrentSlideIndex
} from "../../../../../hooks";
import { Dashboard } from "../../common/Dashboard";
import Introduktion from "../../common/Introduktion";

interface Props {
  slide: FyraHörn.Slide.Instruktioner;
}

export const Instruktioner: React.SFC<Props> = props => {
  const currentSlideIndex = useCurrentSlideIndex();
  const situation = useCurrentSituationIndex();

  if (currentSlideIndex === 0) {
    return (
      <Introduktion
        steg={props.slide}
        image={Assets.Uppdrag[Övningstyp.FyraHörn].cover}
      />
    );
  }

  if (currentSlideIndex === 1) {
    return (
      <Dashboard steg={props.slide}>
        <div style={{ margin: "2rem auto", maxWidth: "25rem" }}>
          <Typography
            className="typography-bold"
            variant="h5"
            color="secondary"
            align="center"
            gutterBottom
          >
            Så här fungerar det
          </Typography>

          <Typography paragraph align="center">
            {props.slide.descriptions.teacher.value}
          </Typography>
        </div>
      </Dashboard>
    );
  }

  return (
    <Dashboard steg={props.slide}>
      <div style={{ margin: "2rem auto", maxWidth: "25rem" }}>
        <Typography
          className="typography-bold"
          variant="h4"
          color="secondary"
          align="center"
          gutterBottom
        >
          Situation {situation}
        </Typography>

        <Typography paragraph align="center">
          {props.slide.descriptions.teacher.value}
        </Typography>
      </div>
    </Dashboard>
  );
};
