import { merge, Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import io from "socket.io-client";
import { Log } from "./util/logging";

export abstract class Socket {
  instance: SocketIOClient.Socket | null = null;

  connect() {
    const query = {
      href: window.location.href
    }

    if (process.env.NODE_ENV === "development") {
      const port = process.env.REACT_APP_DEVELOPMENT_SOCKET_PORT;
      const { protocol, hostname } = window.location;

      this.instance = io(`${protocol}//${hostname}:${port}`, { query });
    } else {
      this.instance = io({ query });
    }

    (window as any).io = this.instance.io;
  }

  disconnect() {
    if (this.instance) {
      this.instance.disconnect();
    }
  }

  authenticate(sessionId: string) {
    return this.emit("authenticate", sessionId);
  }

  async leaveWorkshop(): Promise<void> {
    return this.emit("leave workshop");
  }

  protected emit(event: string, payload?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.instance) {
        throw new Error("No available socket!");
      }

      Log.socket.send(event, payload);

      this.instance.emit(event, payload || null, (error?: any) => {
        return error ? reject(error) : resolve();
      });
    });
  }

  on<T>(event: string | string[]): Observable<T> {
    if (event instanceof Array) {
      return merge(...event.map(name => this.on<T>(name)));
    }

    if (!this.instance) {
      throw new Error("Socket is not started!");
    }

    const stream = new Subject<T>();

    const emitter = (data: T) => {
      Log.socket.recieve(event, data);
      stream.next(data);
    };

    this.instance.on(event, emitter);

    return stream.asObservable().pipe(
      finalize(() => {
        if (this.instance) {
          this.instance.removeListener(event, emitter);
        }
      })
    );
  }
}
