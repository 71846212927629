import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { FriendsColors } from "../theme";
import { getAsset } from "../util/clients";
import { PlatsRating } from "./PlatsRating";

const PlatsContainer = styled.div``;

const PlatsContent = styled.div`
  position: relative;

  height: 0;
  width: 100%;
  padding-top: 100%;
`;

const PlatsImageContainer = styled.div`
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  > * {
    width: 100%;
    height: 100%;
  }
`;

interface PlatsImageProps {
  url: string;
}

const PlatsImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  box-shadow: 0px 1px 20px 0px #00000017;

  transform: scale(0.75);
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-color: ${FriendsColors.yellow};
  background-image: ${(props: PlatsImageProps) => `url(${props.url})`};
`;

const PlatsLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25vmin;

  height: 1.5rem;
  position: relative;

  > * {
    margin: 0;
  }
`;

const Action = styled.div`
  position: absolute;
  right: 0;
  /*
  filter: drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 5px white)
    drop-shadow(0px 0px 5px white) drop-shadow(0px 0px 5px white);
  transform: translateZ(0); */

  &:before {
    content: "";
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    width: 1rem;
    height: 1rem;

    background-color: #f8f8f8;
    box-shadow: 0 0 0.5rem 1rem #f8f8f8;
  }
`;

const PlatsRatingContainer = styled.div`
  &,
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;
    user-select: none;
  }

  /* display: flex;
  align-items: center;
  justify-content: center; */
`;

// const PlatsRating = styled.div`
//   background-color: ${(props: PlatsRatingProps) => props.color};
//   transform: ${(props: PlatsRatingProps) => `scale(${props.scale})`};
//   border-radius: 50%;
//   width: 25%;
//   padding-top: 25%;
//   box-shadow: 0 0 10rem 0 rgba(0, 0, 0, 0.15);
// `;

interface Props {
  small?: boolean;
  fullWidth?: boolean;
  title: string;
  image?: string;
  commentsComponent?: React.ReactNode;
  rating: {
    green: number | null;
    red: number | null;
  };
}

export const Plats: React.SFC<Props> = props => {
  const url = props.image ? getAsset(props.image) : "";

  return (
    <PlatsContainer>
      <PlatsLabel>
        <Typography
          variant="h6"
          align="center"
          style={{
            color: FriendsColors.purple,
            fontSize: props.small ? "0.85rem" : "2.25vmin",
            fontWeight: "bold",
            position: "absolute"
          }}
        >
          {props.title}
        </Typography>

        {props.commentsComponent && <Action>{props.commentsComponent}</Action>}
      </PlatsLabel>

      <PlatsContent>
        <PlatsImageContainer>
          <PlatsImage url={url} />
        </PlatsImageContainer>

        <PlatsRatingContainer>
          <PlatsRating
            rating={props.rating ? props.rating.red : null}
            position="left"
          />
          <PlatsRating
            rating={props.rating ? props.rating.green : null}
            position="right"
          />
        </PlatsRatingContainer>
      </PlatsContent>
    </PlatsContainer>
  );
};
