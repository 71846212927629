import { Grid, Typography } from "@material-ui/core";
import { FyraHörn, Slidetyp } from "friends-shared";
import React from "react";
import { useCurrentSituationIndex, useLatestSlide } from "../../../../../hooks";
import { DiscussionBox, DiscussionText } from "../../../../lobby/Discussion";
import { Dashboard } from "../../common/Dashboard";
import { FyraHörnOverview as FyraHornOverview } from "./components/FyraHörnOverview";

interface Props {
  slide: FyraHörn.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  const situation = useCurrentSituationIndex();
  const aktivitet = useLatestSlide<FyraHörn.Slide.Aktivitet>(
    Slidetyp.Aktivitet
  );

  if (!aktivitet) {
    return null;
  }

  return (
    <Dashboard
      steg={props.slide}
      subtitle={props.slide.descriptions.teacher.value}
    >
      <Grid item xs={7}>
        <Typography
          className="typography-bold"
          variant="h5"
          color="secondary"
          gutterBottom
        >
          Situation {situation}
        </Typography>

        <Typography style={{ marginBottom: "2rem" }} paragraph>
          {aktivitet.questions[0].text}
        </Typography>

        <FyraHornOverview slide={aktivitet} />
      </Grid>

      <Grid item xs={5}>
        <Typography
          className="typography-bold"
          color="secondary"
          variant="h5"
          gutterBottom
        >
          Diskutera resultatet
        </Typography>

        <DiscussionText />
        <DiscussionBox />
      </Grid>
    </Dashboard>
  );
};
