import { Grid, Typography } from "@material-ui/core";
import { TryggKlass } from "friends-shared";
import React, { useContext } from "react";
import { FriendsWordcloud } from "../../../../../../../shared/components/Wordcloud";
import { FriendsContext } from "../../../../../context";
import CompletedStudents from "../../common/CompletedStudents";
import { Dashboard } from "../../common/Dashboard";

interface Props {
  slide: TryggKlass.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  const state = useContext(FriendsContext);

  return (
    <Dashboard steg={props.slide}>
      <Grid item xs={2} />

      <Grid item xs={5}>
        <Typography
          className="typography-bold"
          variant="h5"
          color="primary"
          gutterBottom
        >
          Så här fungerar det
        </Typography>

        <Typography paragraph>
          Nu svarar eleverna på frågan genom att välja ett eller flera
          alternativ. Här ser du deras val bilda ett ordmoln. Ju fler som valt
          ett ord desto större blir det. Du kan även peka på orden i ordmolnet
          för att se hur många som klickat på dem.
        </Typography>

        <FriendsWordcloud
          size="small"
          answers={state.workshop.exerciseAnswers}
        />

        <Typography paragraph>
          När alla har svarat kan du trycka på Visa resultat så visas ordmolnet
          för alla.
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <CompletedStudents />
      </Grid>

      <Grid item xs={2} />
    </Dashboard>
  );
};
