import { Grid, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Mobilhand1 from "../../../../shared/assets/Common/Mobilhand1.svg";
import Mobilhand2 from "../../../../shared/assets/Common/mobilhand2.svg";
import FriendsLogotype from "../../../../shared/components/Logotype";
import { withScreen } from "../../Screen";
import { FriendsColors } from "../../theme";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  margin: 0 auto;

  > * {
    height: 100%;
  }
`;

interface ImagesProps {
  left: string;
  right: string;
}

const Images = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100vmin;
  margin: 0 auto;

  &:before,
  &:after {
    width: 35vmin;
    height: 35vmin;

    display: block;
    position: absolute;
    bottom: 0;

    background-repeat: no-repeat;
  }

  &:before {
    content: "";
    left: 5%;
    background-image: url(${(props: ImagesProps) => props.left});
  }

  &:after {
    content: "";
    right: 0;
    background-image: url(${(props: ImagesProps) => props.right});
  }
`;

@withScreen({
  colors: {
    primary: {
      main: FriendsColors.lightbeige
    },
    secondary: {
      main: FriendsColors.red
    },
    background: {
      default: FriendsColors.red
    }
  },
  headerLogo: null
})
class Finished extends React.Component {
  render() {
    return (
      <Container>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={32}
        >
          <Grid item>
            <Typography
              className="typography-bold"
              color="primary"
              variant="h1"
              align="center"
              style={{ fontSize: 140 }}
            >
              Bra jobbat!
            </Typography>
          </Grid>

          <Grid item>
            <Typography paragraph color="primary" align="center">
              Nu är ni klara med uppdragen. Tack för idag!
            </Typography>
          </Grid>

          <Grid item>
            <Grid container justify="center">
              <Grid item style={{ height: "10vmin" }}>
                <FriendsLogotype color="yellow" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Images left={Mobilhand1} right={Mobilhand2} />
      </Container>
    );
  }
}

export default Finished;
