import * as React from "react";
import { FriendsContext } from "../../../../context";
import { KommentarInput } from "./KommentarScreen";

export interface FriendsFormProps<T> {
  question: T;
  questionIndex: number;
  totalQuestions: number;

  previous(): void;
  next(): void;
  setAnswer(text: string): void;
  setComment(comment: string): void;
  setDefaultValue(value: string): void;
  setView(view: "form" | "comment"): void;

  readonly answer: string;
  readonly comment: string;
  readonly isFirstQuestion: boolean;
  readonly isLastQuestion: boolean;
}

interface Props<T> {
  questions: T[];
}

export function withFriendsForm<P extends FriendsFormProps<any>>(
  Component: React.ComponentType<P>
) {
  const FormLike: React.FC<Props<any>> = props => {
    const context = React.useContext(FriendsContext);

    const [view, setView] = React.useState<"form" | "comment">("form");
    const [answer, setAnswer] = React.useState("");
    const [comment, setComment] = React.useState("");
    const [defaultValue, setDefaultValue] = React.useState("");

    React.useEffect(() => {
      const answers = context.workshop.slideAnswers;
      const answer = answers.find(
        answer => answer.questionIndex === context.workshop.questionIndex
      );

      setAnswer((answer && answer.text) || defaultValue);
      setComment((answer && answer.comment) || "");
    }, [
      defaultValue,
      context.workshop.slideAnswers,
      context.workshop.questionIndex
    ]);

    const previous = () => {
      context.actions.questions.previous();
    };

    const next = () => {
      context.actions.questions.submit({
        comment: comment,
        text: answer,
        question: context.workshop.questionIndex
      });

      context.actions.questions.next();
    };

    if (view === "comment") {
      return (
        <KommentarInput
          comment={comment}
          cancel={() => setView("form")}
          submit={comment => {
            setView("form");
            setComment(comment);
          }}
        />
      );
    }

    const question = props.questions[context.workshop.questionIndex];

    return (
      <Component
        {...(props as any)}
        question={question}
        answer={answer}
        comment={comment}
        isFirstQuestion={context.workshop.questionIndex === 0}
        isLastQuestion={
          context.workshop.questionIndex + 1 === props.questions.length
        }
        questionIndex={context.workshop.questionIndex}
        totalQuestions={props.questions.length}
        previous={() => previous()}
        next={() => next()}
        setAnswer={answer => setAnswer(answer)}
        setComment={comment => setComment(comment)}
        setView={view => setView(view)}
        setDefaultValue={value => {
          setDefaultValue(value);
          setAnswer(answer || value);
        }}
      />
    );
  };

  return FormLike;
}
