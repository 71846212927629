import { Grid, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { FriendsAvatar } from "../../../../../shared/components/Avatar";
import { ResponsiveScreen, ScreenProps } from "../../../Screen";
import { FriendsButton } from "../../../theme";

const AvatarContainer = styled.div`
  width: 70vw;
  height: 70vw;
  max-width: 17.5rem;
  max-height: 17.5rem;

  position: relative;
  margin: 0 auto;
  margin-top: 2rem;

  > * {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

interface Props extends ScreenProps {
  avatar: string;
  submit(): void;
  cancel(): void;
}

export const ConfirmAvatar: React.FC<Props> = props => {
  const Footer = (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <FriendsButton
          fullWidth
          color="primary"
          variant="contained"
          onClick={props.cancel}
        >
          Tillbaka
        </FriendsButton>
      </Grid>

      <Grid item xs={12}>
        <FriendsButton
          fullWidth
          color="primary"
          variant="contained"
          onClick={props.submit}
        >
          Klar
        </FriendsButton>
      </Grid>
    </Grid>
  );

  return (
    <ResponsiveScreen logotype="yellow" footer={Footer}>
      <>
        <Typography
          className="typography-bold"
          variant="h2"
          color="secondary"
          align="center"
          gutterBottom
        >
          Hej!
          <br />
          Där är du ju!
        </Typography>

        <AvatarContainer>
          <FriendsAvatar index={props.avatar} />
        </AvatarContainer>
      </>
    </ResponsiveScreen>
  );
};
