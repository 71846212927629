import { Grid, Typography } from "@material-ui/core";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import React from "react";
import styled from "styled-components";
import FriendsLogotype from "../../../shared/components/Logotype";
import { withScreen } from "../Screen";
import { FriendsButton, FriendsColors } from "../theme";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 600px;
  margin: 0 auto;

  > * {
    height: 100%;
  }
`;

const LogotypeContainer = styled.div`
  height: 3rem;
  margin-bottom: -0.25rem;
  display: flex;
  justify-content: center;
`;

interface Props {
  lärarhandledningUrl: string | null;
  onProceed(): void;
}

@withScreen({
  colors: {
    primary: {
      main: FriendsColors.lightbeige
    },
    secondary: {
      main: FriendsColors.purple
    },
    background: {
      default: FriendsColors.purple
    }
  },
  headerLogo: null
})
class WelcomeScreen extends React.Component<Props> {
  render() {
    return (
      <Container>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={16}
        >
          <Grid item>
            <LogotypeContainer>
              <FriendsLogotype color="lightyellow" />
            </LogotypeContainer>
          </Grid>

          <Grid item>
            <Typography
              className="typography-bold"
              color="primary"
              variant="h1"
              align="center"
              style={{ marginBottom: "2rem" }}
            >
              Uppdrag Trygghet
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              gutterBottom
              color="primary"
              variant="h3"
              align="center"
            >
              Välkommen!
            </Typography>

            <Typography
              gutterBottom
              paragraph
              color="primary"
              align="center"
              style={{ maxWidth: 500 }}
            >
              Vad kul att du vill göra uppdrag om trygghet tillsammans med dina
              elever! Här kan du spara ner lärarhandledningen:{" "}
              <FriendsButton
                component="a"
                disabled={!this.props.lärarhandledningUrl}
                href={this.props.lärarhandledningUrl || ""}
                variant="outlined"
                color="primary"
                style={{ marginTop: "0.75em" }}
              >
                Ladda ner
                <GetAppRoundedIcon
                  style={{
                    marginRight: "-0.5em",
                    paddingLeft: "0.5rem"
                  }}
                />
              </FriendsButton>
            </Typography>

            <Typography
              gutterBottom
              paragraph
              color="primary"
              align="center"
              style={{ maxWidth: 500, marginTop: "2.5rem" }}
            >
              Gå vidare för att först fylla i lite info om skolan.
            </Typography>

            <Grid container justify="center">
              <Grid item>
                <FriendsButton
                  color="primary"
                  variant="contained"
                  onClick={this.props.onProceed}
                >
                  Vidare
                </FriendsButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default WelcomeScreen;
