import { Grid } from "@material-ui/core";
import { SkalFrågor } from "friends-shared";
import React from "react";
import styled from "styled-components";
import CompletedStudents from "../../common/CompletedStudents";
import { Dashboard } from "../../common/Dashboard";
import { QuestionsOverview } from "./components/QuestionsOverview";

const Container = styled.div`
  max-width: 875px;
  margin: 0 auto;
`;

interface Props {
  slide: SkalFrågor.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  return (
    <Dashboard
      steg={props.slide}
      subtitle={props.slide.descriptions.teacher.value}
    >
      <Grid item xs={12}>
        <Container>
          <Grid container spacing={32}>
            <Grid item xs={6}>
              <QuestionsOverview steg={props.slide} />
            </Grid>

            <Grid item xs={6}>
              <CompletedStudents />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Dashboard>
  );
};
