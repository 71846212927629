import React from "react";
import styled from "styled-components";
import FriendsAvatar1 from "../assets/avatars/Friends_avatar_1.svg";
import FriendsAvatar10 from "../assets/avatars/Friends_avatar_10.svg";
import FriendsAvatar11 from "../assets/avatars/Friends_avatar_11.svg";
import FriendsAvatar12 from "../assets/avatars/Friends_avatar_12.svg";
import FriendsAvatar13 from "../assets/avatars/Friends_avatar_13.svg";
import FriendsAvatar14 from "../assets/avatars/Friends_avatar_14.svg";
import FriendsAvatar15 from "../assets/avatars/Friends_avatar_15.svg";
import FriendsAvatar16 from "../assets/avatars/Friends_avatar_16.svg";
import FriendsAvatar17 from "../assets/avatars/Friends_avatar_17.svg";
import FriendsAvatar18 from "../assets/avatars/Friends_avatar_18.svg";
import FriendsAvatar19 from "../assets/avatars/Friends_avatar_19.svg";
import FriendsAvatar2 from "../assets/avatars/Friends_avatar_2.svg";
import FriendsAvatar20 from "../assets/avatars/Friends_avatar_20.svg";
import FriendsAvatar21 from "../assets/avatars/Friends_avatar_21.svg";
import FriendsAvatar22 from "../assets/avatars/Friends_avatar_22.svg";
import FriendsAvatar23 from "../assets/avatars/Friends_avatar_23.svg";
import FriendsAvatar24 from "../assets/avatars/Friends_avatar_24.svg";
import FriendsAvatar25 from "../assets/avatars/Friends_avatar_25.svg";
import FriendsAvatar26 from "../assets/avatars/Friends_avatar_26.svg";
import FriendsAvatar27 from "../assets/avatars/Friends_avatar_27.svg";
import FriendsAvatar28 from "../assets/avatars/Friends_avatar_28.svg";
import FriendsAvatar29 from "../assets/avatars/Friends_avatar_29.svg";
import FriendsAvatar3 from "../assets/avatars/Friends_avatar_3.svg";
import FriendsAvatar30 from "../assets/avatars/Friends_avatar_30.svg";
import FriendsAvatar31 from "../assets/avatars/Friends_avatar_31.svg";
import FriendsAvatar32 from "../assets/avatars/Friends_avatar_32.svg";
import FriendsAvatar33 from "../assets/avatars/Friends_avatar_33.svg";
import FriendsAvatar34 from "../assets/avatars/Friends_avatar_34.svg";
import FriendsAvatar35 from "../assets/avatars/Friends_avatar_35.svg";
import FriendsAvatar36 from "../assets/avatars/Friends_avatar_36.svg";
import FriendsAvatar37 from "../assets/avatars/Friends_avatar_37.svg";
import FriendsAvatar38 from "../assets/avatars/Friends_avatar_38.svg";
import FriendsAvatar39 from "../assets/avatars/Friends_avatar_39.svg";
import FriendsAvatar4 from "../assets/avatars/Friends_avatar_4.svg";
import FriendsAvatar40 from "../assets/avatars/Friends_avatar_40.svg";
import FriendsAvatar41 from "../assets/avatars/Friends_avatar_41.svg";
import FriendsAvatar42 from "../assets/avatars/Friends_avatar_42.svg";
import FriendsAvatar43 from "../assets/avatars/Friends_avatar_43.svg";
import FriendsAvatar44 from "../assets/avatars/Friends_avatar_44.svg";
import FriendsAvatar45 from "../assets/avatars/Friends_avatar_45.svg";
import FriendsAvatar46 from "../assets/avatars/Friends_avatar_46.svg";
import FriendsAvatar47 from "../assets/avatars/Friends_avatar_47.svg";
import FriendsAvatar48 from "../assets/avatars/Friends_avatar_48.svg";
import FriendsAvatar5 from "../assets/avatars/Friends_avatar_5.svg";
import FriendsAvatar6 from "../assets/avatars/Friends_avatar_6.svg";
import FriendsAvatar7 from "../assets/avatars/Friends_avatar_7.svg";
import FriendsAvatar8 from "../assets/avatars/Friends_avatar_8.svg";
import FriendsAvatar9 from "../assets/avatars/Friends_avatar_9.svg";

export const FriendsAvatarCollection = [
  FriendsAvatar1,
  FriendsAvatar2,
  FriendsAvatar3,
  FriendsAvatar4,
  FriendsAvatar5,
  FriendsAvatar6,
  FriendsAvatar7,
  FriendsAvatar8,
  FriendsAvatar9,
  FriendsAvatar10,
  FriendsAvatar11,
  FriendsAvatar12,
  FriendsAvatar13,
  FriendsAvatar14,
  FriendsAvatar15,
  FriendsAvatar16,
  FriendsAvatar17,
  FriendsAvatar18,
  FriendsAvatar19,
  FriendsAvatar20,
  FriendsAvatar21,
  FriendsAvatar22,
  FriendsAvatar23,
  FriendsAvatar24,
  FriendsAvatar25,
  FriendsAvatar26,
  FriendsAvatar27,
  FriendsAvatar28,
  FriendsAvatar29,
  FriendsAvatar30,
  FriendsAvatar31,
  FriendsAvatar32,
  FriendsAvatar33,
  FriendsAvatar34,
  FriendsAvatar35,
  FriendsAvatar36,
  FriendsAvatar37,
  FriendsAvatar38,
  FriendsAvatar39,
  FriendsAvatar40,
  FriendsAvatar41,
  FriendsAvatar42,
  FriendsAvatar43,
  FriendsAvatar44,
  FriendsAvatar45,
  FriendsAvatar46,
  FriendsAvatar47,
  FriendsAvatar48
];

interface Props {
  index: string;
  onClick?: (name: string) => void;
}

const Avatar = styled.div`
  background-color: white;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  border: 1px solid whitesmoke;
  border-radius: 100%;
`;

const Square = styled.div`
  width: 100%;
  height: 100%;

  max-width: 20rem;
  max-height: 20rem;
  margin: 0 auto;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    float: left;
  }
`;

export class FriendsAvatar extends React.Component<Props> {
  render() {
    return (
      <Square>
        <Avatar
          onClick={() =>
            this.props.onClick && this.props.onClick(this.props.index)
          }
          style={{
            backgroundImage: `url(${
              FriendsAvatarCollection[+this.props.index]
            })`
          }}
        />
      </Square>
    );
  }
}
