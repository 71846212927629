import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { FriendsContext } from "../../../../context";
import { FriendsButton, FriendsInput } from "../../../../theme";

interface Props {
  comment: string;
  submit(comment: string): void;
  cancel(): void;
}

export const KommentarInput: React.FC<Props> = props => {
  const context = React.useContext(FriendsContext);
  const [comment, setComment] = React.useState(props.comment);
  const ref = React.useRef<HTMLTextAreaElement>();

  React.useEffect(() => {
    const element = ref.current;

    if (element && element.value && element.value.length) {
      element.focus();
      element.setSelectionRange(element.value.length, element.value.length);
    }
  }, []);

  return (
    <div style={{ paddingTop: "1rem" }}>
      <Typography variant="h6" color="primary">
        Uppdrag {context.workshop.exercise.currentExerciseIndex + 1}
      </Typography>

      <Typography
        className="typography-bold"
        variant="h5"
        gutterBottom
        color="primary"
      >
        Kommentera?
      </Typography>

      <Typography paragraph>
        Här kan du kommentera. Kom ihåg att inte skriva några namn. Dina svar är
        anonyma och visas endast för läraren.
      </Typography>

      <FriendsInput
        fullWidth
        multiline
        rows="5"
        placeholder="Skriv här..."
        value={comment}
        onChange={event => setComment(event.target.value)}
        inputProps={{ maxLength: 300 }}
        inputRef={ref}
        style={{
          backgroundColor: "white",
          boxSizing: "border-box",
          padding: "1rem",
          marginBottom: "1rem"
        }}
      />

      <Grid container spacing={16} direction="column">
        <Grid item>
          <FriendsButton
            fullWidth
            color="secondary"
            variant="contained"
            onClick={() => props.submit(comment)}
          >
            Spara
          </FriendsButton>
        </Grid>

        <Grid item>
          <FriendsButton
            fullWidth
            color="secondary"
            variant="contained"
            onClick={() => props.cancel()}
          >
            Avbryt
          </FriendsButton>
        </Grid>
      </Grid>
    </div>
  );
};
