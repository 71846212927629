import { FormControl, Grid, Typography } from "@material-ui/core";
import { studentGender, studentGrade } from "friends-shared";
import React, { useState } from "react";
import { ResponsiveScreen } from "../../../Screen";
import {
  FriendsButton,
  FriendsInputLabel,
  FriendsMenuItem,
  FriendsSelect
} from "../../../theme";

const grades = Object.entries(studentGrade);
const genders = Object.entries(studentGender);

interface Props {
  submit(state: { grade: string; sex: string }): void;
}

export const CreateUserForm: React.FC<Props> = props => {
  const [grade, setGrade] = useState("");
  const [sex, setSex] = useState("");

  const Footer = (
    <FriendsButton
      fullWidth
      color="primary"
      variant="contained"
      disabled={!grade || !sex}
      onClick={() => props.submit({ grade, sex })}
    >
      Fortsätt
    </FriendsButton>
  );

  return (
    <ResponsiveScreen logotype="yellow" footer={Footer}>
      <>
        <Typography
          className="typography-bold"
          variant="h2"
          color="secondary"
          align="center"
          style={{ marginTop: "2rem", marginBottom: "3rem" }}
        >
          Vem är du?
        </Typography>

        <Grid container spacing={24} direction="column">
          <Grid item>
            <FormControl fullWidth>
              <FriendsInputLabel shrink>
                Vilken årskurs går du i?
              </FriendsInputLabel>
              <FriendsSelect
                displayEmpty
                value={grade}
                onChange={change => setGrade(change.target.value)}
              >
                <FriendsMenuItem value="" disabled>
                  Välj årskurs
                </FriendsMenuItem>

                {grades.map(([year, name]) => (
                  <FriendsMenuItem key={year} value={year}>
                    {name}
                  </FriendsMenuItem>
                ))}
              </FriendsSelect>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <FriendsInputLabel shrink>
                Hur identifierar du dig?
              </FriendsInputLabel>

              <FriendsSelect
                displayEmpty
                value={sex}
                onChange={change => setSex(change.target.value)}
              >
                <FriendsMenuItem value="" disabled>
                  Välj bland alternativen
                </FriendsMenuItem>

                {genders.map(([value, name]) => (
                  <FriendsMenuItem key={value} value={value}>
                    {name}
                  </FriendsMenuItem>
                ))}
              </FriendsSelect>
            </FormControl>
          </Grid>
        </Grid>
      </>
    </ResponsiveScreen>
  );
};
