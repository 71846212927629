import { Grid, Typography } from "@material-ui/core";
import { AbstractSlide, Slidetyp } from "friends-shared";
import React, { useContext } from "react";
import { ClientState, FriendsContext } from "../../../../context";

const calculateNumberOfCompletedStudentsInExercise = ({
  workshop
}: ClientState) => {
  if (!workshop.exercise.data) {
    return null;
  }

  const index = workshop.exercise.currentSlideIndex;
  const slide = workshop.exercise.data.slides[index];
  const answers = workshop.exerciseAnswers;

  if (slide.typ === Slidetyp.Aktivitet) {
    return answers.filter(
      answer => answer.questionIndex === slide.questions.length - 1
    ).length;
  }

  return null;
};

const calculateNumberOfCompletedStudentsInSlide = (
  { workshop }: ClientState,
  slide: AbstractSlide
) => {
  if (!workshop.exercise.data) {
    return null;
  }

  const slides = workshop.exercise.data.slides as AbstractSlide[];
  const slideIndex = slides.indexOf(slide);

  return workshop.exerciseAnswers.filter(answer => {
    return answer.slideIndex === slideIndex;
  }).length;
};

interface Props {
  slide?: AbstractSlide;
}

const CompletedStudents: React.SFC<Props> = props => {
  const state = useContext(FriendsContext);

  const result = props.slide
    ? calculateNumberOfCompletedStudentsInSlide(state, props.slide)
    : calculateNumberOfCompletedStudentsInExercise(state);

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Typography
          className="typography-bold"
          color="primary"
          align="center"
          variant="h5"
        >
          Antal elever som svarat
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography align="center" variant="h3" color="textSecondary">
          {result}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CompletedStudents;
