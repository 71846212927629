import { Grid, Typography } from "@material-ui/core";
import { SkalFrågor, Slidetyp } from "friends-shared";
import React from "react";
import { List } from "../../../../../../../../shared/components/List";

interface Props {
  steg: SkalFrågor.Slide;
}

export const QuestionsOverview: React.SFC<Props> = props => {
  if (props.steg.typ !== Slidetyp.Aktivitet) {
    return null;
  }

  return (
    <>
      <Typography
        className="typography-bold"
        variant="h5"
        color="primary"
        style={{ marginBottom: "1.5rem" }}
      >
        Uppdragets påståenden
      </Typography>

      <Grid container spacing={16}>
        <List
          align="left"
          items={Object.values(props.steg.questions).map((question, index) => ({
            label: `${index + 1}.`,
            text: question.text.full
          }))}
        />
      </Grid>
    </>
  );
};
