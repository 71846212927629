import { withTheme, WithTheme } from "@material-ui/core";
import { FyraHörn } from "friends-shared";
import React from "react";
import { Layer, Rect, Stage } from "react-konva";
import { ResizeObserver } from "resize-observer";
import DrawableTextCircle from "../../../../../../../../shared/components/canvas/DrawableTextCircle";

interface Props extends WithTheme {
  areas: {
    topLeft: FyraHörn.Slide.Aktivitet.Area;
    topRight: FyraHörn.Slide.Aktivitet.Area;
    bottomLeft: FyraHörn.Slide.Aktivitet.Area;
    bottomRight: FyraHörn.Slide.Aktivitet.Area;
  };
}

interface State {
  resizeObserver: ResizeObserver | null;
  canvas: {
    width: number;
    height: number;
  };
}

class FyraHörnInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      resizeObserver: null,
      canvas: {
        width: 0,
        height: 0
      }
    };
  }

  componentDidMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    if (this.state.resizeObserver) {
      this.state.resizeObserver.disconnect();

      this.setState({
        resizeObserver: null
      });
    }
  }

  render() {
    const { width, height } = this.state.canvas;
    const scale = 0.75;

    return (
      <>
        <div
          ref="canvas"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }}
        >
          <Stage ref="stage" width={width} height={height}>
            <Layer
              scale={{ x: scale, y: scale }}
              x={(width * (1 - scale)) / 2}
              y={(height * (1 - scale)) / 2}
            >
              <Rect
                x={0}
                y={0}
                width={width}
                height={height}
                stroke={this.props.theme.palette.primary.main}
                strokeWidth={4}
              />

              <DrawableTextCircle
                text={this.props.areas.topLeft.label}
                color="primary"
                grow="right"
                position={{ x: 0, y: 0 }}
              />

              <DrawableTextCircle
                text={this.props.areas.topRight.label}
                color="primary"
                grow="left"
                position={{ x: width, y: 0 }}
              />

              <DrawableTextCircle
                text={this.props.areas.bottomLeft.label}
                color="primary"
                grow="right"
                position={{ x: 0, y: height }}
              />

              <DrawableTextCircle
                text={this.props.areas.bottomRight.label}
                color="primary"
                grow="left"
                position={{ x: width, y: height }}
              />
            </Layer>
          </Stage>
        </div>
      </>
    );
  }

  private handleResize() {
    const canvas = this.refs.canvas as HTMLElement;

    const handler = new ResizeObserver(() => {
      const { clientWidth, clientHeight } = canvas;

      this.setState({
        canvas: {
          width: clientWidth,
          height: clientHeight
        }
      });
    });

    handler.observe(canvas);

    this.setState({
      resizeObserver: handler
    });
  }
}

export default withTheme()(FyraHörnInput);
