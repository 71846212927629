import React from 'react';
import ConnectToWorkshop from './ConnectToWorkshop';

class Start extends React.Component {
  render() {
    return (
      <ConnectToWorkshop></ConnectToWorkshop>
    );
  }
}

export default Start;
