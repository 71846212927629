import joinUrl from "url-join";

export enum Client {
  Teacher,
  Student,
  Projector
}

const routes: Record<Client, string | undefined> = {
  [Client.Teacher]: process.env.REACT_APP_TEACHER_ROOT,
  [Client.Student]: process.env.REACT_APP_STUDENT_ROOT,
  [Client.Projector]: process.env.REACT_APP_PROJECTOR_ROOT
};

export function getClientRoute(client: Client): string {
  const route = routes[client];

  if (route) {
    return route;
  }

  throw new Error("Client link is undefined!");
}

export function getClientLink(client: Client, code?: string) {
  const route = getClientRoute(client);
  const params = code ? `?code=${code}` : ""
  const url = joinUrl(window.location.origin, route, params);

  // Remove trailing slash
  return url.replace(/\/$/, "");
}

export function openClientInNewWindow(client: Client) {
  window.open(
    getClientLink(client),
    "_blank",
    "location=yes,height=600,width=800,scrollbars=yes,status=yes"
  );
}

export function getAsset(asset: string) {
  const { protocol, hostname } = window.location;

  const port =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT_SOCKET_PORT
      : window.location.port;

  return `${protocol}//${hostname}:${port}/${asset}`;
}
