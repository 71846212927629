import React from "react";
import styled from "styled-components";
import LogotypeYellow from "../assets/logo/friends_logo_ljusgul_rgb-01.svg";
import LogotypeDarkYellow from "../assets/logo/friends_logo_morkgul_rgb-01.svg";

const Logotypes = {
  yellow: LogotypeDarkYellow,
  lightyellow: LogotypeYellow
};

const Logotype = styled.img`
  height: 100%;
`;

const FriendsLogotype: React.SFC<{ color: keyof typeof Logotypes }> = props => {
  return <Logotype src={Logotypes[props.color]} />;
};

export default FriendsLogotype;

export type FriendsLogotypeColor = keyof typeof Logotypes;
