"use strict";

exports.__esModule = true;
exports.studentGender = {
  "male": "Kille",
  "female": "Tjej",
  "other": "Annat alternativ",
  "unsure": "Osäker",
  "ignored": "Vill ej svara"
};
exports.studentGrade = {
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "År 1 på gymnasiet": "År 1 på gymnasiet",
  "År 2 på gymnasiet": "År 2 på gymnasiet",
  "År 3 på gymnasiet": "År 3 på gymnasiet"
};

var Student = function () {
  function Student() {}

  return Student;
}();

exports.Student = Student;