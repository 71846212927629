import pathToRegexp from "path-to-regexp";
import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  withRouter
} from "react-router-dom";

interface RouteWithSessionId {
  sessionId: string;
}

interface Props {
  basename: string;
  sessionId: string | null;
  sessionIdChange: (sessionId: string | null) => void;
}

class SessionUrlListener extends React.Component<
  Props & RouteComponentProps<RouteWithSessionId>
> {
  private get currentSessionId() {
    if (this.props.match) {
      return this.props.match.params.sessionId || null;
    }

    return null;
  }

  render() {
    if (this.props.sessionId === this.currentSessionId) {
      return null;
    }

    if (this.props.sessionId) {
      return <Redirect to={"/" + this.props.sessionId} />;
    } else {
      return <Redirect to="/" />;
    }
  }
}

const SessionUrlListenerWithRouter = withRouter(SessionUrlListener);

class SessionUrlHandler extends React.Component<Props> {
  render() {
    return (
      <BrowserRouter basename={this.props.basename}>
        <Route path="/:sessionId">
          <SessionUrlListenerWithRouter
            basename={this.props.basename}
            sessionId={this.props.sessionId}
            sessionIdChange={id => this.props.sessionIdChange(id)}
          />
        </Route>
      </BrowserRouter>
    );
  }
}

export function getSessionIdFromURL(basename: string): string | null {
  const regexp = pathToRegexp(`/${basename}/:sessionId`);
  const result = regexp.exec(window.location.pathname);

  if (result) {
    return result[1];
  }

  return null;
}

export default SessionUrlHandler;
