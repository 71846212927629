import { SkalFrågor } from "friends-shared";
import React from "react";
import { AktivitetScreen } from "../../common/AktivitetScreen";
import FleraFragorForm from "./components/FleraFrågor";

interface Props {
  slide: SkalFrågor.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  return (
    <AktivitetScreen
      idleHeader="Bra!"
      idleText="Din lärare startar strax ert nästa uppdrag."
      component={<FleraFragorForm questions={props.slide.questions} />}
    />
  );
};
