import { Grid, Grow, MuiThemeProvider } from "@material-ui/core";
import { Theme, ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import React, { ComponentType, FC, useEffect, useMemo, useRef } from "react";
import Helmet from "react-helmet";
import { Subject } from "rxjs";
import styled from "styled-components";
import FriendsLogotype, {
  FriendsLogotypeColor
} from "../../shared/components/Logotype";
import { FriendsButton, FriendsColors, updateTheme } from "./theme";

const ButtonsContainer = styled.div`
  margin: 1rem 0;
`;

const Screen = styled.div`
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ScreenHeader = styled.header`
  height: 1.75rem;
  margin-bottom: 1.25rem;
`;

export interface ScreenProps {
  buttonEvents?: Subject<string>;
}

export function withScreen(configuration: {
  headerLogo: FriendsLogotypeColor | null;
  buttons: string[];
  preventVerticalOverflow?: boolean;
  colors: {
    primary: string;
    secondary: string;
    background: string;
    textPrimary?: string;
    textSecondary?: string;
  };
}): <P extends ScreenProps>(
  WrappedComponent: ComponentType<React.PropsWithChildren<P>>
) => void {
  return <P extends ScreenProps>(
    WrappedComponent: ComponentType<React.PropsWithChildren<P>>
  ) => {
    const NewScreenComponent: FC = props => {
      const buttonEvents = useRef(new Subject<string>());

      const theme = useMemo(() => {
        let overrides: ThemeOptions = {
          palette: {
            primary: {
              main: configuration.colors.primary
            },
            secondary: {
              main: configuration.colors.secondary
            },
            text: {
              ...(configuration.colors.textPrimary
                ? { primary: configuration.colors.textPrimary }
                : {}),
              ...(configuration.colors.textSecondary
                ? { secondary: configuration.colors.textSecondary }
                : {})
            }
          }
        };

        return updateTheme(overrides);
      }, [configuration]);

      useEffect(() => {
        const element = document.getElementById("scroll-container");

        if (element) {
          element.scrollTo(0, 0);
        }
      });

      return (
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <meta
              name="theme-color"
              content={configuration.colors.background}
            />
          </Helmet>

          <Grow in={true}>
            <Screen
              id="scroll-container"
              style={{
                backgroundColor: configuration.colors.background,
                overflowY: configuration.preventVerticalOverflow
                  ? "hidden"
                  : "inherit"
              }}
            >
              <div>
                <ScreenHeader>
                  {configuration.headerLogo && (
                    <FriendsLogotype color={configuration.headerLogo} />
                  )}
                </ScreenHeader>

                <WrappedComponent
                  buttonEvents={buttonEvents.current}
                  {...(props as any)}
                />
              </div>

              <ButtonsContainer>
                <Grid container spacing={8}>
                  {configuration.buttons.map(button => (
                    <Grid key={button} item xs={12}>
                      <FriendsButton
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={() => buttonEvents.current.next(button)}
                      >
                        {button}
                      </FriendsButton>
                    </Grid>
                  ))}
                </Grid>
              </ButtonsContainer>
            </Screen>
          </Grow>
        </MuiThemeProvider>
      );
    };

    return NewScreenComponent;
  };
}

interface ScreenConfiguration {
  colors?: Partial<PaletteOptions>;
  logotype?: FriendsLogotypeColor;
  footer?: React.ReactNode;
  fixedHeight?: boolean;
}

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  z-index: 1;
  height: 1.75rem;

  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;

  position: relative;

  .screen-fixed-height {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
  }
`;

const Footer = styled.div`
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
`;

const defaultColors = {
  background: {
    default: FriendsColors.lightgrey
  },
  primary: {
    main: FriendsColors.purple
  },
  secondary: {
    main: FriendsColors.lime
  }
};

const useTheme = (palette: Partial<PaletteOptions> = defaultColors) => {
  const [theme, setTheme] = React.useState<Theme>(
    updateTheme({
      palette
    })
  );

  React.useEffect(() => {
    setTheme(
      updateTheme({
        palette
      })
    );
  }, [palette]);

  return theme;
};

export const ResponsiveScreen: React.FC<ScreenConfiguration> = props => {
  const theme = useTheme(props.colors);

  return (
    <MuiThemeProvider theme={theme}>
      <Helmet>
        <meta name="theme-color" content={theme.palette.background.default} />
      </Helmet>

      <Grow in={true}>
        <Container
          id="scroll-container"
          style={{
            backgroundColor: theme.palette.background.default
          }}
        >
          {props.logotype && (
            <Header>
              <FriendsLogotype color={props.logotype} />
            </Header>
          )}

          <Content>
            <div className={props.fixedHeight ? "screen-fixed-height" : ""}>
              {props.children}
            </div>
          </Content>

          {props.footer && <Footer>{props.footer}</Footer>}
        </Container>
      </Grow>
    </MuiThemeProvider>
  );
};
