import { MuiThemeProvider } from "@material-ui/core";
import React, { Component } from "react";
import { ReconnectOverlay } from "../../shared/components/ReconnectOverlay";
import App from "./App";
import { StateProvider } from "./context";
import { socket } from "./socket";
import { theme } from "./theme";

class Teacher extends Component {
  render() {
    return (
      <StateProvider>
        <MuiThemeProvider theme={theme}>
          <App />
          <ReconnectOverlay socket={socket} />
        </MuiThemeProvider>
      </StateProvider>
    );
  }
}

export default Teacher;
