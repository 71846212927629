import { Grid, Typography } from "@material-ui/core";
import { Activity, Answer } from "friends-shared";
import React from "react";
import styled from "styled-components";
import FriendsLogotype from "../../../../shared/components/Logotype";
import { FriendsWordcloud } from "../../../../shared/components/Wordcloud";
import { FriendsColors } from "../../theme";

const A4 = styled.div`
  background-color: white;
  position: relative;
  min-width: 297mm;
  max-height: 209mm;

  width: 100%;
  height: 100%;
`;

const PrintSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
`;

const PrintTitle = styled(PrintSection)`
  top: 5%;
  bottom: 90%;
`;

const PrintWordcloud = styled(PrintSection)`
  top: 10%;
  bottom: 35%;
`;

const PrintActivities = styled(PrintSection)`
  top: 65%;
  bottom: 0%;
  left: 5%;
  right: 5%;
`;

const PrintLogotype = styled(PrintSection)`
  top: 5%;
  left: 5%;
  width: 4%;
  height: 4%;
`;

interface Props {
  answers: Answer[];
  activities: Activity[];
}

const purpleStyle = {
  color: FriendsColors.purple
};

export default class PrintWordcloudAndActivities extends React.Component<Props> {
  render() {
    return (
      <A4>
        <PrintTitle>
          <Typography
            className="typography-bold"
            variant="h3"
            align="center"
            style={purpleStyle}
          >
            Trygg klass
          </Typography>
        </PrintTitle>

        <PrintWordcloud>
          <FriendsWordcloud
            size="small"
            answers={this.props.answers}
            disableTransition
          />
        </PrintWordcloud>

        <PrintActivities>
          <Grid container wrap="nowrap" spacing={32}>
            {this.props.activities.map(activity => (
              <Grid item key={activity.id} style={{ flex: 1 }}>
                <Typography
                  className="typography-bold"
                  gutterBottom
                  variant="h6"
                  style={{ wordBreak: "break-word", ...purpleStyle }}
                >
                  {activity.title}
                </Typography>

                <Typography
                  paragraph
                  style={{ wordBreak: "break-word", ...purpleStyle }}
                >
                  {activity.description}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </PrintActivities>

        <PrintLogotype>
          <FriendsLogotype color="yellow" />
        </PrintLogotype>
      </A4>
    );
  }
}
