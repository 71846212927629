const createLogger = (icon: string, label: string, color: string) => {
  const unsafeConsole = console as any;
  const target = unsafeConsole.context ? unsafeConsole.context(label) : console;

  const colorText = `font-weight: bold; color: ${color}`;
  const resetText = `font-weight: bold; color: #444`;

  return (name: string, value?: any) => {
    const message = `${icon} %c${label} %c${name}`;

    target.log(message, colorText, resetText, value);
  };
};

export const Log = {
  socket: {
    send: createLogger("🔴", "send event", "#e22e29"),
    recieve: createLogger("🔵", "receive event", "#008ee8")
  },
  session: createLogger("🔶", "session", "#ff9300")
};
