import { Grid, withTheme, WithTheme } from "@material-ui/core";
import { FyraHörn } from "friends-shared";
import Konva from "konva";
import React from "react";
import { Layer, Rect, Stage } from "react-konva";
import { ResizeObserver } from "resize-observer";
import DrawableTextCircle from "../../../../../../../../shared/components/canvas/DrawableTextCircle";
import { FriendsContext } from "../../../../../../context";
import { FriendsButton } from "../../../../../../theme";
import { FriendsFormProps, withFriendsForm } from "../../../common/Form";
import DrawableAvatar from "./DrawableAvatar";

interface Question {
  areas: {
    topLeft: FyraHörn.Slide.Aktivitet.Area;
    topRight: FyraHörn.Slide.Aktivitet.Area;
    bottomLeft: FyraHörn.Slide.Aktivitet.Area;
    bottomRight: FyraHörn.Slide.Aktivitet.Area;
  };
}

interface Props extends WithTheme, FriendsFormProps<Question> {}

interface State {
  resizeObserver: ResizeObserver | null;
  canvas: {
    width: number;
    height: number;
  };
}

class FyraHörnInput extends React.Component<Props, State> {
  static contextType = FriendsContext;
  context!: React.ContextType<typeof FriendsContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      resizeObserver: null,
      canvas: {
        width: 0,
        height: 0
      }
    };
  }

  componentDidMount() {
    this.handleResize();
    this.handleDragAndDrop();
  }

  componentWillUnmount() {
    if (this.state.resizeObserver) {
      this.state.resizeObserver.disconnect();

      this.setState({
        resizeObserver: null
      });
    }
  }

  render() {
    const { width, height } = this.state.canvas;
    const question = this.props.question;

    const avatarSize = 75;
    const scale = 0.75;

    const areaWidth = width * 0.4;
    const areaHeight = width * 0.4;

    return (
      <>
        <Grid container direction="column" spacing={32}>
          <Grid item style={{ position: "relative", paddingTop: "0" }}>
            <div
              ref="canvas"
              style={{
                position: "absolute",
                left: 0,
                right: 0
              }}
            >
              <Stage ref="stage" width={width} height={height}>
                <Layer
                  scale={{ x: scale, y: scale }}
                  x={(width * (1 - scale)) / 2}
                  y={(height * (1 - scale)) / 2}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    stroke={this.props.theme.palette.primary.main}
                    strokeWidth={4}
                  />

                  <DrawableTextCircle
                    text={this.props.question.areas.topLeft.label}
                    color="secondary"
                    grow="right"
                    position={{ x: 0, y: 0 }}
                    active={
                      this.props.answer ===
                      this.props.question.areas.topLeft.label
                    }
                  />

                  <DrawableTextCircle
                    text={this.props.question.areas.topRight.label}
                    color="secondary"
                    grow="left"
                    position={{ x: width, y: 0 }}
                    active={
                      this.props.answer ===
                      this.props.question.areas.topRight.label
                    }
                  />

                  <DrawableTextCircle
                    text={this.props.question.areas.bottomLeft.label}
                    color="secondary"
                    grow="right"
                    position={{ x: 0, y: height }}
                    active={
                      this.props.answer ===
                      this.props.question.areas.bottomLeft.label
                    }
                  />

                  <DrawableTextCircle
                    text={this.props.question.areas.bottomRight.label}
                    color="secondary"
                    grow="left"
                    position={{ x: width, y: height }}
                    active={
                      this.props.answer ===
                      this.props.question.areas.bottomRight.label
                    }
                  />
                </Layer>

                <Layer name="areas">
                  <Rect
                    name={question.areas.topLeft.label}
                    width={areaWidth}
                    height={areaHeight}
                    x={0}
                    y={0}
                  />

                  <Rect
                    name={question.areas.topRight.label}
                    width={areaWidth}
                    height={areaHeight}
                    x={width - areaWidth}
                    y={0}
                  />

                  <Rect
                    name={question.areas.bottomLeft.label}
                    width={areaWidth}
                    height={areaHeight}
                    x={0}
                    y={height - areaHeight}
                  />

                  <Rect
                    name={question.areas.bottomRight.label}
                    width={areaWidth}
                    height={areaHeight}
                    x={width - areaWidth}
                    y={height - areaHeight}
                  />
                </Layer>

                <Layer>
                  <DrawableAvatar
                    avatar={+this.context.workshop.student.avatar}
                    name="avatar"
                    x={width / 2}
                    y={height / 2}
                    width={avatarSize}
                    height={avatarSize}
                    offsetX={avatarSize / 2}
                    offsetY={avatarSize / 2}
                    draggable
                    dragBoundFunc={position => {
                      if (position.x - avatarSize < 0) {
                        position.x = avatarSize;
                      }

                      if (position.x + avatarSize > width) {
                        position.x = width - avatarSize;
                      }

                      if (position.y - avatarSize < 0) {
                        position.y = avatarSize;
                      }

                      if (position.y + avatarSize > height) {
                        position.y = height - avatarSize;
                      }

                      return position;
                    }}
                  />
                </Layer>
              </Stage>
            </div>
          </Grid>

          <Grid container spacing={16} direction="column">
            <Grid item>
              <FriendsButton
                disabled={!this.props.answer}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => this.props.setView("comment")}
              >
                Kommentera
              </FriendsButton>
            </Grid>

            <Grid item>
              <FriendsButton
                disabled={!this.props.answer}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => this.props.next()}
              >
                {this.props.isLastQuestion ? "Slutför" : "Nästa"}
              </FriendsButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  private handleResize() {
    const canvas = this.refs.canvas as HTMLElement;

    const handler = new ResizeObserver(() => {
      const { clientWidth, clientHeight } = canvas;
      const parent = canvas.parentElement;

      canvas.style.height = `${clientWidth}px`;

      if (parent) {
        const padding = {
          top: parseFloat(
            window.getComputedStyle(parent).getPropertyValue("padding-top")
          ),
          bottom: parseFloat(
            window.getComputedStyle(parent).getPropertyValue("padding-bottom")
          )
        };

        parent.style.height = `${clientWidth + padding.top + padding.bottom}px`;
      }

      this.setState({
        canvas: {
          width: clientWidth,
          height: clientHeight
        }
      });
    });

    handler.observe(canvas);

    this.setState({
      resizeObserver: handler
    });
  }

  private handleDragAndDrop() {
    const stage = (this.refs.stage as Stage).getStage();

    stage.on("dragend", () => {
      try {
        const layer = stage.find(".areas")[0] as Konva.Layer;
        const avatar = stage.find("Image")[0] as Konva.Image;

        const match = layer.getIntersection(avatar.getPosition());
        const result = match && match.name();

        this.props.setAnswer(result);
      } catch (error) {
        console.warn(error);
      }
    });
  }
}

export default withFriendsForm(withTheme()(FyraHörnInput));
