import { Grid, Typography } from "@material-ui/core";
import { TryggaOchOtryggaPlatser, Övningstyp } from "friends-shared";
import React from "react";
import { Assets } from "../../../../../../../shared/assets";
import CompletedStudents from "../../common/CompletedStudents";
import { Dashboard } from "../../common/Dashboard";
import { PlatserOverview } from "./components/PlatserOverview";

interface Props {
  slide: TryggaOchOtryggaPlatser.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  return (
    <Dashboard steg={props.slide}>
      <Grid item xs={3}>
        <Typography
          className="typography-bold"
          variant="h5"
          color="primary"
          gutterBottom
        >
          Så här fungerar det
        </Typography>

        <Typography paragraph>
          Här visas elevernas svar i realtid. Under varje bild kan du klicka på
          pratbubblan för att läsa elevernas kommentarer till den specifika
          platsen.
        </Typography>

        <img
          src={Assets.Uppdrag[Övningstyp.TryggaOchOtryggaPlatser].instruktion}
          alt="Kommentarer"
        />
      </Grid>

      <Grid item xs={6}>
        <PlatserOverview />
      </Grid>

      <Grid item xs={3}>
        <CompletedStudents />
      </Grid>
    </Dashboard>
  );
};
