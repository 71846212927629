import { FyraHörn, Slidetyp } from "friends-shared";
import React, { Component } from "react";
import { ScreenProps, withScreen } from "../../../../Screen";
import { FriendsColors } from "../../../../theme";
import { Aktivitet } from "./slides/Aktivitet";
import { Instruktioner } from "./slides/Introduktion";
import { Resultat } from "./slides/Resultat";

interface Props extends ScreenProps {
  slide: FyraHörn.Slide;
}

@withScreen({
  buttons: [],
  colors: {
    primary: FriendsColors.purple,
    secondary: FriendsColors.lime,
    background: FriendsColors.lightgrey,
    textPrimary: FriendsColors.purple,
    textSecondary: FriendsColors.white
  },
  headerLogo: "yellow"
})
export default class extends Component<Props> {
  render() {
    const { slide } = this.props;

    switch (slide.typ) {
      case Slidetyp.Instruktioner:
        return <Instruktioner slide={slide} />;

      case Slidetyp.Aktivitet:
        return <Aktivitet slide={slide} />;

      case Slidetyp.Resultat:
        return <Resultat slide={slide} />;

      default:
        return null;
    }
  }
}
