import { Grid } from "@material-ui/core";
import { Answer, SkalFrågor } from "friends-shared";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import { Radar } from "../../../../../../../../shared/components/plots/Radar/Radar";
import { PrintableRadarChart } from "../../../../../../common/Printable/PrintableRadarChart";
import { FriendsButton } from "../../../../../../theme";

const PrintContainer = styled.div`
  position: fixed;
  top: -10000px;
`;

const printStyle = `
  @page {
    size: A4 landscape;
    margin: 0mm;
  }

  @media print {
    html, body {
      height: 100%;
      width: 100%;
      position: relative;
    }

    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;

interface Props {
  slide: SkalFrågor.Slide.Aktivitet;
  answers: Answer[];
}

export const SaveRadarChart: React.SFC<Props> = props => {
  const componentRef = useRef<PrintableRadarChart>(null);

  return (
    <Grid container spacing={32} style={{ padding: "2rem" }}>
      <Grid item xs={12}>
        <Radar slide={props.slide} slideAnswers={props.answers} />
      </Grid>

      <Grid item xs={12}>
        {
          <ReactToPrint
            pageStyle={printStyle}
            trigger={() => (
              <FriendsButton fullWidth variant="contained" color="primary">
                Spara spindeldiagram
              </FriendsButton>
            )}
            content={() => componentRef.current as any}
          />
        }

        <PrintContainer>
          <PrintableRadarChart
            answers={props.answers}
            slide={props.slide}
            ref={componentRef}
          />
        </PrintContainer>
      </Grid>
    </Grid>
  );
};

export default SaveRadarChart;
