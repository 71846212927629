import { Grid, Typography } from "@material-ui/core";
import { FyraHörn, Slidetyp, Övningstyp } from "friends-shared";
import React, { useContext } from "react";
import styled from "styled-components";
import { FriendsContext } from "../../../../../../context";
import { useLatestSlide } from "../../../../../../hooks";
import FyraHornInput from "./FyraHörnInput";

interface Props {
  slide: FyraHörn.Slide.Aktivitet | FyraHörn.Slide.Resultat;
}

const Container = styled.div`
  position: absolute;
  padding: 7rem 3rem 0 3rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .texts-left {
    margin-top: 5vmin;
    margin-left: 5vmin;
    margin-bottom: 5vmin;
  }

  .texts-right {
    margin-top: 5vmin;
    margin-right: 5vmin;
    margin-bottom: 5vmin;
  }
`;

export const AktivitetAndResultat: React.SFC<Props> = props => {
  const aktivitet = useLatestSlide<FyraHörn.Slide.Aktivitet>(
    Slidetyp.Aktivitet
  );
  const { workshop } = useContext(FriendsContext);

  if (!aktivitet) {
    return null;
  }

  const exercise = workshop.exercise.data;

  if (!exercise || exercise.type !== Övningstyp.FyraHörn) {
    return null;
  }

  const exerciseAktiviteter = exercise.slides.filter(
    slide => slide.typ === Slidetyp.Aktivitet
  );
  const lastAktivitetIndex = exerciseAktiviteter.indexOf(aktivitet);
  const question = aktivitet.questions[0];

  const answers = workshop.exerciseAnswers.filter(answer => {
    return answer.slideIndex === exercise.slides.indexOf(aktivitet);
  });

  return (
    <Container>
      <Grid container wrap="nowrap" spacing={16} style={{ height: "100%" }}>
        <Grid item xs={3} className="texts-left">
          <Grid
            container
            spacing={32}
            wrap="nowrap"
            direction="column"
            justify="space-between"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography paragraph>{question.areas.topLeft.text}</Typography>
            </Grid>

            <Grid item>
              <Typography paragraph>
                {question.areas.bottomLeft.text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%"
            }}
          >
            <Typography
              className="typography-bold"
              variant="h3"
              color="primary"
              gutterBottom
              align="center"
            >
              Fråga {lastAktivitetIndex + 1} av {exerciseAktiviteter.length}
            </Typography>

            <FyraHornInput
              showResult={props.slide.typ === Slidetyp.Resultat}
              areas={aktivitet.questions[0].areas}
              answers={answers}
              color="secondary"
            />
          </div>
        </Grid>

        <Grid item xs={3} className="texts-right">
          <Grid
            container
            spacing={32}
            wrap="nowrap"
            direction="column"
            justify="space-between"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography paragraph>{question.areas.topRight.text}</Typography>
            </Grid>

            <Grid item>
              <Typography paragraph>
                {question.areas.bottomRight.text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
