import { Typography } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface Props {
  title: string;
  subtitle: string;
}

class TitleAndSubtitle extends React.Component<Props> {
  render() {
    return (
      <Container>
        <Typography
          className="typography-bold"
          variant="h1"
          color="textPrimary"
          gutterBottom
          style={{ lineHeight: 1.2 }}
        >
          {this.props.title}
        </Typography>

        <Typography color="textSecondary" variant="h4">
          {this.props.subtitle}
        </Typography>
      </Container>
    );
  }
}

export default TitleAndSubtitle;
