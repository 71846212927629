import { Student } from "friends-shared";
import React from "react";
import { StateConsumer } from "../../context";
import { ConfirmAvatar } from "./CreateUser/ConfirmAvatar";
import { CreateUserForm } from "./CreateUser/Form";
import { CreateUserIntro } from "./CreateUser/Intro";
import { SelectAvatar } from "./CreateUser/SelectAvatar";

interface State {
  hasSkippedIntro: boolean;
  grade: string;
  avatar: string;
  sex: string;
}

class CreateUser extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      hasSkippedIntro: false,
      grade: "",
      avatar: "",
      sex: ""
    };
  }

  render() {
    return (
      <StateConsumer>
        {state => {
          if (!this.state.hasSkippedIntro) {
            return (
              <CreateUserIntro
                submit={() => this.setState({ hasSkippedIntro: true })}
              />
            );
          }

          if (!this.state.grade || !this.state.sex) {
            return (
              <CreateUserForm
                submit={({ grade, sex }) => {
                  this.setState({
                    grade,
                    sex
                  });
                }}
              />
            );
          }

          if (this.state.avatar === "") {
            return (
              <SelectAvatar
                submit={avatar => {
                  this.setState({
                    avatar
                  });
                }}
              />
            );
          }

          return (
            <ConfirmAvatar
              avatar={this.state.avatar}
              cancel={() => this.setState({ avatar: "" })}
              submit={() =>
                state.actions.createStudent({
                  grade: this.state.grade,
                  avatar: this.state.avatar,
                  sex: this.state.sex
                } as Student)
              }
            />
          );
        }}
      </StateConsumer>
    );
  }
}

export default CreateUser;
