import delay from "delay";
import Konva from "konva";
import React from "react";
import { animated, Keyframes, Spring } from "react-spring/konva";
import useImage from "use-image";
import { FriendsAvatarCollection } from "../../../../../../../../shared/components/Avatar";
import { Area } from "./FyraHörnInput";

interface BetterAvatarProps {
  area: Area;
  shaking: boolean;
  avatar: number;
}

interface AvatarProps {
  position: Konva.Vector2d;
  shaking: boolean;

  avatar: number;
}
const avatarSize = 50;

const generatePosition = (area: Area) => {
  const random = () => Math.random() * (0.8 - 0.2) + 0.2;

  return {
    x: area.width * random() + area.x,
    y: area.height * random() + area.y
  };
};

const Container: any = Keyframes.Spring(async (next: Function) => {
  const generateIdlePosition = () => {
    return {
      x: 20 * Math.random() - 10,
      y: 20 * Math.random() - 10
    };
  };

  const wait = () => delay(750 + 1000 * Math.random());

  while (true) {
    let position = {
      x: 0,
      y: 0
    };

    await wait();
    await next({
      from: { rotation: -10 },
      to: { rotation: 10 },
      config: {
        duration: 100
      }
    });

    position = generateIdlePosition();

    await next({
      from: { x: 0, y: 0 },
      to: { x: position.x, y: position.y },
      config: {
        duration: 200
      }
    });

    await wait();
    await next({
      from: { rotation: 10 },
      to: { rotation: -10 },
      config: {
        duration: 150
      }
    });

    await wait();
    await next({
      from: { x: position.x, y: position.y },
      to: { x: 0, y: 0 },
      config: {
        duration: 200
      }
    });

    await wait();
    await next({
      from: { rotation: 10 },
      to: { rotation: -5 },
      config: {
        duration: 100
      }
    });

    await wait();
    await next({
      from: { rotation: -5 },
      to: { rotation: 5 },
      config: {
        duration: 100
      }
    });
    await next({
      from: { scaleX: 1 },
      to: { scaleX: -1 },
      config: {
        duration: 0
      }
    });

    await wait();
    await next({
      from: { scaleX: -1 },
      to: { scaleX: 1 },
      config: {
        duration: 0
      }
    });

    position = generateIdlePosition();
    await wait();
    await next({
      from: { x: 0, y: 0 },
      to: { x: position.x, y: position.y },
      config: {
        duration: 200
      }
    });

    await wait();
    await next({
      from: { x: position.x, y: position.y },
      to: { x: 0, y: 0 },
      config: {
        duration: 200
      }
    });
  }
});

const Avatar: React.SFC<AvatarProps> = props => {
  const [avatarImage] = useImage(FriendsAvatarCollection[props.avatar]);

  return (
    <Container native config={{ duration: 500, tension: 10, friction: 5 }}>
      {(idleStyle: any) => (
        <Spring
          native
          config={{ tension: 10, friction: 5 }}
          from={{
            scaleX: 0,
            scaleY: 0
          }}
          to={{
            x: ((idleStyle.x && idleStyle.x.value) || 0) + props.position.x,
            y: ((idleStyle.y && idleStyle.y.value) || 0) + props.position.y,
            scaleX: 1,
            scaleY: 1
          }}
        >
          {style => (
            <animated.Image
              {...idleStyle}
              {...style}
              image={avatarImage}
              width={avatarSize}
              height={avatarSize}
              offsetX={avatarSize / 2}
              offsetY={avatarSize / 2}
            />
          )}
        </Spring>
      )}
    </Container>
  );
};

interface State {
  position: Konva.Vector2d;
}

class BetterAvatar extends React.Component<BetterAvatarProps, State> {
  constructor(props: BetterAvatarProps) {
    super(props);

    this.state = {
      position: generatePosition(props.area)
    };
  }

  componentDidUpdate({ area }: BetterAvatarProps) {
    if (
      area.x !== this.props.area.x ||
      area.y !== this.props.area.y ||
      area.width !== this.props.area.width ||
      area.height !== this.props.area.height
    ) {
      this.setState({
        position: generatePosition(this.props.area)
      });
    }
  }

  render() {
    if (
      !this.props.area.x &&
      !this.props.area.y &&
      !this.props.area.height &&
      !this.props.area.width
    ) {
      return null;
    }

    return (
      <Avatar
        shaking={this.props.shaking}
        avatar={this.props.avatar}
        position={this.state.position}
      />
    );
  }
}

export default BetterAvatar;
