import { Grid, Tooltip, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import styled from "styled-components";
import Heart from "../../../../shared/components/Heart";
import { Client, getClientLink } from "../../../../shared/util/clients";
import { copyToClipboard } from "../../../../shared/util/copy-to-clipboard";
import { FriendsContext } from "../../context";
import { withScreen } from "../../Screen";
import { FriendsButton, FriendsColors } from "../../theme";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 550px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  onComplete(): void;
}

interface State {
  screen: "initial" | "configuration" | "connection";
}

const openNewProjectorWindow = (code: string) => {
  const url = getClientLink(Client.Projector, code);

  window.open(
    url,
    "projektorvy",
    "resizable, scrollbars, width=1024, height=768"
  );
};

const ManualInstructionsDetails = styled.details`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 450px;
  margin-top: 0.5rem;

  summary {
    text-align: center;
    cursor: pointer;
    user-select: none;
    outline: none;
    opacity: 0.7;

    &::-webkit-details-marker {
      display: none;
    }

    &:hover {
      opacity: 1;
    }

    & > p {
      font-size: 1rem;
    }
  }

  & > p {
    margin-top: 0.5em;
    background: rgba(0, 0, 0, 0.05);
    padding: 1em;
    text-align: center;

    ol {
      text-align: left;
    }
  }
`;

const ManualInstructions = () => {
  const context = useContext(FriendsContext);

  return (
    <ManualInstructionsDetails>
      <summary>
        <Typography style={{ display: "inline-block" }}>
          Fungerar det inte?
        </Typography>
      </summary>

      <Typography style={{ marginTop: "0.5em" }}>
        Om det inte öppnas något fönster när du klickar på knappen eller ifall
        det är något annat som strular så kan du prova att öppna huvudvyn
        manuellt.
        <ol>
          <li>Öppna ett nytt webbläsarfönster</li>

          <li>
            Gå in på{" "}
            <Tooltip title="Kopiera länk">
              <FriendsButton
                style={{ padding: 0 }}
                variant="text"
                color="primary"
                onClick={() => copyToClipboard(getClientLink(Client.Projector))}
              >
                <b style={{ fontWeight: 600 }}>
                  {getClientLink(Client.Projector)}
                </b>
              </FriendsButton>
            </Tooltip>{" "}
          </li>

          <li>
            Ange koden{" "}
            <Tooltip title="Kopiera kod">
              <FriendsButton
                style={{ padding: 0 }}
                variant="text"
                color="primary"
                onClick={() =>
                  copyToClipboard(context.workshop.codes.projector)
                }
              >
                <b style={{ fontWeight: 600 }}>
                  {context.workshop.codes.projector}
                </b>
              </FriendsButton>
            </Tooltip>
          </li>
        </ol>
      </Typography>
    </ManualInstructionsDetails>
  );
};

@withScreen({
  colors: {
    primary: {
      main: FriendsColors.purple
    },
    background: {
      default: FriendsColors.lightgrey
    }
  },
  headerLogo: "yellow"
})
class WorkshopSetupScreen extends React.Component<Props, State> {
  static contextType = FriendsContext;
  context!: React.ContextType<typeof FriendsContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      screen: "initial"
    };
  }

  private renderInitialScreen() {
    return (
      <Grid container justify="center" alignItems="center" spacing={24}>
        <Grid item xs={12}>
          <Heart color={FriendsColors.lime} />
        </Grid>

        <Grid item xs={12}>
          <Typography
            className="typography-bold"
            align="center"
            variant="h1"
            color="primary"
          >
            Tack!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography paragraph align="center">
            Gå vidare för instruktioner om hur du startar huvudvyn!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <FriendsButton
                variant="contained"
                color="primary"
                onClick={() => this.setState({ screen: "configuration" })}
              >
                Vidare
              </FriendsButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  // Öppna ett nytt webbläsarfönster
  // Instruktioner kommer då att visas för eleverna
  private renderWindowConfigurationScreen() {
    return (
      <Grid container justify="center" alignItems="center" spacing={24}>
        <Grid item xs={12}>
          <Typography
            className="typography-bold"
            align="center"
            variant="h1"
            color="primary"
          >
            Starta huvudvy
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography paragraph gutterBottom align="center">
            Klicka på knappen nedan för att öppna huvudvyn. Flytta sedan det nya
            fönstret så att det är synligt för eleverna. Instruktioner för att
            utvidga din skärm finns i lärarhandledningen.
          </Typography>

          <FriendsButton
            variant="outlined"
            color="primary"
            onClick={() =>
              openNewProjectorWindow(this.context.workshop.codes.projector)
            }
          >
            Öppna huvudvyn
          </FriendsButton>

          <ManualInstructions />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            style={{ maxWidth: 400, margin: "1.5em auto 0 auto" }}
          >
            <Grid item>
              <Typography
                variant="body2"
                paragraph
                gutterBottom
                align="center"
                style={{ padding: "0 2rem" }}
              >
                Den här vyn ska endast vara synlig för dig som
                lärare/handledare.
              </Typography>

              <Typography paragraph gutterBottom align="center"></Typography>
            </Grid>

            <Grid item>
              <FriendsButton
                variant="contained"
                color="primary"
                onClick={() => this.props.onComplete()}
              >
                Vidare
              </FriendsButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  private getView() {
    switch (this.state.screen) {
      case "initial":
        return this.renderInitialScreen();
      case "configuration":
        return this.renderWindowConfigurationScreen();
      default:
        return null;
    }
  }

  render() {
    const view = this.getView();

    return <Container>{view}</Container>;
  }
}

export default WorkshopSetupScreen;
