import { Grid } from "@material-ui/core";
import { Answer, Slidetyp, TryggaOchOtryggaPlatser } from "friends-shared";
import React, { useContext, useEffect, useState } from "react";
import { animated, Transition } from "react-spring";
import styled from "styled-components";
import { Plats } from "../../../../../../../../shared/components/Plats";
import { getAsset } from "../../../../../../../../shared/util/clients";
import { CommentsButton } from "../../../../../../common/CommentsButton";
import { FriendsContext } from "../../../../../../context";
import { useLatestSlide } from "../../../../../../hooks";

type Question = TryggaOchOtryggaPlatser.Slide.Aktivitet["questions"][number];

const normalizeAnswer = (question: Question, answers: Answer[]) => {
  let tryggaSvar = 0;
  let otryggaSvar = 0;

  for (const answer of answers) {
    const value = Number(answer.text);

    if (value === question.min) {
      tryggaSvar += 1;
    }

    if (value > question.min) {
      otryggaSvar += value * 0.5;
    }
  }

  const maxValue = Math.max(tryggaSvar, otryggaSvar);

  const normalizedResults = {
    trygga: tryggaSvar / maxValue,
    otrygga: otryggaSvar / maxValue
  };

  const totalValue = normalizedResults.trygga + normalizedResults.otrygga;

  return {
    green: normalizedResults.trygga / totalValue,
    red: normalizedResults.otrygga / totalValue
  };
};

const usePlatserResults = () => {
  const { workshop } = useContext(FriendsContext);
  const aktivitet = useLatestSlide<TryggaOchOtryggaPlatser.Slide.Aktivitet>(
    Slidetyp.Aktivitet
  );

  if (!aktivitet) {
    return [];
  }

  return aktivitet.questions.map((question, questionIndex) => {
    const questionAnswers = workshop.exerciseAnswers.filter(
      answer => answer.questionIndex === questionIndex
    );

    const comments = questionAnswers.filter(answer => !!answer.comment);

    const { red, green } = normalizeAnswer(question, questionAnswers);

    return {
      question,
      comments,
      result: {
        red,
        green
      }
    };
  });
};

const usePreloadedImages = () => {
  const [isImagesLoaded, setIsImagesLoaded] = useState(false);
  const platser = usePlatserResults();

  const urls = platser.map(plats => plats.question.location.imageUrl);
  const resolves = urls.map(url => {
    return new Promise<void>(resolve => {
      const image = new Image();

      image.onload = () => resolve();
      image.onerror = () => resolve();
      image.oncancel = () => resolve();

      image.src = getAsset(url);
    });
  });

  useEffect(() => {
    Promise.all(resolves).then(() => setIsImagesLoaded(true));
  });

  return isImagesLoaded;
};

const Container = styled.div`
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
`;

export const PlatserOverview: React.SFC = () => {
  const isImagesLoaded = usePreloadedImages();
  const results = usePlatserResults();

  if (!isImagesLoaded) {
    return null;
  }

  return (
    <Grid item style={{ position: "relative", height: "100%" }}>
      <Container>
        <Transition
          native
          items={results}
          keys={(item: any) => item.question.location.name}
          trail={100}
          config={{ tension: 450, friction: 20 }}
          from={{ transform: "scale(0)" }}
          enter={{ transform: "scale(1)" }}
        >
          {(item: typeof results[number]) => (props: React.CSSProperties) => {
            return (
              <animated.div style={props}>
                <Plats
                  small
                  fullWidth
                  rating={{
                    red: item.result.red,
                    green: item.result.green
                  }}
                  key={item.question.location.imageUrl}
                  title={item.question.location.name}
                  image={item.question.location.imageUrl}
                  commentsComponent={
                    item.comments.length > 0 && (
                      <CommentsButton
                        answers={item.comments}
                        title={item.question.location.name}
                      />
                    )
                  }
                />
              </animated.div>
            );
          }}
        </Transition>
      </Container>
    </Grid>
  );
};
