import {
  Button,
  Chip,
  Input,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import {
  createMuiTheme,
  createStyles,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

export const FriendsFonts = {
  Arial: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
  Capitaine: "Capitaine"
};

export const FriendsColors = {
  lightbeige: "#fff29c",
  lightgrey: "#f8f8f8",
  purple: "#941d80",
  red: "#d60e51",
  lime: "#009c8f",
  blue: "#38a8e0",
  white: "#fff",
  yellow: "#fad501"
};

const sliderStyling: any = {
  MuiSlider: {
    thumb: {
      width: 36,
      height: 36
    },
    track: {
      height: 5
    }
  }
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: FriendsColors.purple
    },
    secondary: {
      main: FriendsColors.purple
    },
    text: {
      primary: "#545454"
    }
  },
  typography: {
    fontFamily: FriendsFonts.Capitaine,
    useNextVariants: true,
    fontSize: 16
  },
  overrides: {
    ...sliderStyling,

    MuiButton: {
      label: {
        fontSize: "1rem",
        fontWeight: 500
      },
      contained: {
        boxShadow: "none",

        "&:active, &:hover": {
          boxShadow: "none"
        }
      }
    },

    MuiChip: {
      root: {
        fontSize: "1rem"
      }
    },

    MuiTypography: {
      h1: {
        fontSize: 52
      },
      h2: {
        fontSize: 40
      },
      h3: {
        fontSize: 34
      },
      h4: {
        fontSize: 32
      },
      h5: {
        fontSize: 28
      },
      h6: {
        fontSize: 21,
        lineHeight: 1.2
      },
      paragraph: {
        fontFamily: FriendsFonts.Arial
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 16
      }
    },
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: "1.75rem"
        }
      }
    }
  }
});

export const updateTheme = (override: ThemeOptions): Theme => {
  return createMuiTheme({
    ...theme,
    ...override
  });
};

export const FriendsInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      fontFamily: FriendsFonts.Arial
    },
    error: {},
    disabled: {},
    focused: {},
    underline: {
      "&:before": {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        opacity: 0.75
      },
      "&:after": {
        borderBottom: `2px solid ${theme.palette.primary.main}`
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: `2px solid ${theme.palette.primary.light}`,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          borderBottom: `1px solid ${theme.palette.primary.main}`
        }
      }
    }
  })
)(Input);

export const FriendsButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0.4rem 1.75rem"
    },
    label: {
      textTransform: "none",
      fontSize: 22
    },
    contained: {
      color: theme.palette.background.default
    },
    text: {
      borderRadius: 3,
      border: 0
    }
  })
)(Button);

export const FriendsSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      fontFamily: FriendsFonts.Arial
    },
    select: {
      padding: "1rem 1.25rem",
      fontSize: 14,
      color: theme.palette.primary.main
    },
    icon: {
      color: theme.palette.primary.main,
      marginRight: ".5rem"
    }
  })
)(Select);

export const FriendsInputLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.7)"
    }
  })
)(InputLabel);

export const FriendsMenuItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: ".75rem",
      color: theme.palette.primary.main,
      fontSize: 16,
      fontFamily: FriendsFonts.Arial
    }
  })
)(MenuItem);

export const FriendsChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderWidth: 2,
      background: "rgba(0, 0, 0, 0.05)"
    },
    outlinedPrimary: {
      borderColor: "transparent",
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "white"
    },
    clickable: {
      background: "transparent"
    }
  })
)(Chip);
