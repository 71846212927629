import { Student } from "friends-shared";
import { Socket } from "../../shared/socket";

class StudentSocket extends Socket {
  async joinWorkshop(code: string) {
    return this.emit("join workshop as student", code);
  }

  async createStudent(student: Student): Promise<void> {
    return this.emit("create student", student);
  }

  async submitAnswer(answer: {
    text: string;
    comment?: string;
    question: number;
  }): Promise<void> {
    return this.emit("submit answer", answer);
  }

  async submitMessage(message: string): Promise<void> {
    return this.emit("submit message", message);
  }
}

const socket = new StudentSocket();

export { socket };
