import { Grid, Grow, Typography } from "@material-ui/core";
import React from "react";
import {
  FriendsAvatar,
  FriendsAvatarCollection
} from "../../../../../shared/components/Avatar";
import { ResponsiveScreen, ScreenProps } from "../../../Screen";

interface Props extends ScreenProps {
  submit(avatar: string): void;
}

export const SelectAvatar: React.FC<Props> = props => {
  return (
    <ResponsiveScreen logotype="yellow">
      <>
        <Typography
          className="typography-bold"
          variant="h2"
          color="secondary"
          align="center"
          style={{ margin: "2rem 0 2.5rem 0" }}
        >
          Välj din avatar
        </Typography>

        <Grid container spacing={16}>
          {FriendsAvatarCollection.map((_, avatar) => (
            <Grid item xs={4} key={avatar}>
              <Grow
                in={true}
                timeout={100 * avatar}
                style={{
                  transformOrigin: "0 0 0"
                }}
              >
                <FriendsAvatar
                  index={avatar as any}
                  onClick={() => props.submit("" + avatar)}
                />
              </Grow>
            </Grid>
          ))}
        </Grid>
      </>
    </ResponsiveScreen>
  );
};
