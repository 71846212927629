import { Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import styled from "styled-components";
import { FriendsContext } from "../../context";
import { withScreen } from "../../Screen";
import { FriendsButton, FriendsColors } from "../../theme";
import { SaveExercises } from "./SaveExercises";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 400px;
  margin: 0 auto;

  > * {
    height: 100%;
  }
`;

const ExitButton: React.FC = () => {
  const context = useContext(FriendsContext);

  return (
    <FriendsButton
      color="primary"
      variant="outlined"
      onClick={context.actions.leaveWorkshop}
      size="large"
      style={{
        transform: "scale(1.15)"
      }}
    >
      Avsluta
    </FriendsButton>
  );
};

@withScreen({
  colors: {
    primary: {
      main: FriendsColors.lightbeige
    },
    secondary: {
      main: FriendsColors.purple
    },
    background: {
      default: FriendsColors.purple
    }
  },
  headerLogo: "yellow"
})
class Finished extends React.Component {
  render() {
    return (
      <Container>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={16}
        >
          <Grid item>
            <Typography
              className="typography-bold"
              color="primary"
              variant="h1"
              align="center"
              gutterBottom
            >
              Bra jobbat!
            </Typography>
          </Grid>

          <Grid item>
            <Typography gutterBottom paragraph color="primary" align="center">
              Nu är ni klara med alla uppdragen. Avrunda tillsammans med
              eleverna och berätta om hur och när ni kommer följa upp det ni har
              gjort idag. Tack!
            </Typography>

            <Typography gutterBottom paragraph color="primary" align="center">
              Här kan du spara ner ert resultat. Glöm inte att radera eventuella
              personuppgifter.
            </Typography>

            <SaveExercises />
          </Grid>

          <Grid item>
            <div style={{ paddingTop: "2.5rem" }}>
              <Typography
                gutterBottom
                variant="h5"
                color="primary"
                align="center"
              >
                Glöm inte att avsluta workshopen!
              </Typography>

              <Typography gutterBottom paragraph color="primary" align="center">
                Efter att du sparat ner allt resultat, avsluta workshopen så
                rensas alla fritextsvar.
              </Typography>

              <div style={{ paddingTop: "0.75rem", textAlign: "center" }}>
                <ExitButton />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Finished;
