import { Typography } from "@material-ui/core";
import { AbstractSlide } from "friends-shared";
import * as React from "react";
import styled from "styled-components";
import { StateConsumer } from "../../../../context";

const Container = styled.div`
  max-width: 160vh;
  margin: 0 auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Text = styled.div`
  position: absolute;
  z-index: 1;
  left: 10%;
  top: 30%;
  width: 70%;
  bottom: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Photo = styled.div`
  position: absolute;
  bottom: 0;
  width: 50%;
  right: 0;
  top: 50%;

  background-position: bottom;
  background-repeat: no-repeat;
`;

interface Props {
  steg: AbstractSlide;
  image: string;
}

class Introduktion extends React.Component<Props> {
  render() {
    const { steg } = this.props;

    return (
      <StateConsumer>
        {state => {
          const exercise = state.workshop.exercise.data;

          if (exercise) {
            const title = exercise.name;
            const index = state.workshop.exercise.currentExerciseIndex + 1;

            return (
              <Container>
                <Text>
                  <Typography
                    className="typography-bold"
                    variant="h3"
                    color="primary"
                    gutterBottom
                  >
                    Uppdrag {index}
                  </Typography>

                  <Typography
                    className="typography-bold"
                    variant="h2"
                    color="primary"
                    gutterBottom
                  >
                    {title}
                  </Typography>

                  <Typography paragraph color="textPrimary">
                    {steg.descriptions.projector.value}
                  </Typography>
                </Text>

                <Photo
                  style={{
                    backgroundImage: `url(${this.props.image})`
                  }}
                />
              </Container>
            );
          }
        }}
      </StateConsumer>
    );
  }
}

export default Introduktion;
