import { CircularProgress, Fade, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Socket } from "../socket";

const LoadingScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    color: #fff29c !important;
    transform: scale(1.5);
  }
`;

const useSocketState = (socket: Socket) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const onEvent = () => {
      if (socket.instance) {
        setConnected(socket.instance.connected);
      }
    };

    if (socket.instance) {
      socket.instance.on("connect", onEvent);
      socket.instance.on("disconnect", onEvent);

      return () => {
        if (socket.instance) {
          socket.instance.removeListener("connect", onEvent);
          socket.instance.removeListener("disconnect", onEvent);
        }
      };
    }
  });

  return connected;
};

interface Props {
  socket: Socket;
}

export const ReconnectOverlay: React.SFC<Props> = props => {
  const isConnected = useSocketState(props.socket);

  return (
    <Fade in={!isConnected} unmountOnExit mountOnEnter>
      <LoadingScreenContainer>
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <ProgressContainer>
              <CircularProgress />
            </ProgressContainer>
          </Grid>
        </Grid>
      </LoadingScreenContainer>
    </Fade>
  );
};
