import { TryggaOchOtryggaPlatser } from "friends-shared";
import React from "react";
import { DiskussionsInput } from "../../common/DiskussionsInput";

interface Props {
  slide: TryggaOchOtryggaPlatser.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  return <DiskussionsInput />;
};
