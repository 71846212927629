"use strict";

exports.__esModule = true;

var Message = function () {
  function Message() {}

  return Message;
}();

exports.Message = Message;