import { withTheme, WithTheme } from "@material-ui/core";
import { Answer, FyraHörn } from "friends-shared";
import React from "react";
import { Layer, Rect, Stage } from "react-konva";
import { ResizeObserver } from "resize-observer";
import DrawableTextCircle from "../../../../../../../../shared/components/canvas/DrawableTextCircle";
import { FriendsContext } from "../../../../../../context";
import DrawableAvatar from "./DrawableAvatar";

export interface Area {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Props extends WithTheme {
  showResult: boolean;
  answers: Answer[];
  color?: "primary" | "secondary";
  areas: {
    topLeft: FyraHörn.Slide.Aktivitet.Area;
    topRight: FyraHörn.Slide.Aktivitet.Area;
    bottomLeft: FyraHörn.Slide.Aktivitet.Area;
    bottomRight: FyraHörn.Slide.Aktivitet.Area;
  };
}

interface State {
  resizeObserver: ResizeObserver | null;
  canvas: {
    width: number;
    height: number;
  };
}

class FyraHörnInput extends React.Component<Props, State> {
  static contextType = FriendsContext;
  context!: React.ContextType<typeof FriendsContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      resizeObserver: null,
      canvas: {
        width: 0,
        height: 0
      }
    };
  }

  componentDidMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    if (this.state.resizeObserver) {
      this.state.resizeObserver.disconnect();

      this.setState({
        resizeObserver: null
      });
    }
  }

  private get areas() {
    const areaWidth = this.state.canvas.width * 0.4;
    const areaHeight = this.state.canvas.height * 0.4;

    return {
      topLeft: {
        x: 0,
        y: 0,
        width: areaWidth,
        height: areaHeight
      },
      topRight: {
        x: this.state.canvas.width - areaWidth,
        y: 0,
        width: areaWidth,
        height: areaHeight
      },
      bottomLeft: {
        width: areaWidth,
        height: areaHeight,
        x: 0,
        y: this.state.canvas.height - areaHeight
      },
      bottomRight: {
        width: areaWidth,
        height: areaHeight,
        x: this.state.canvas.width - areaWidth,
        y: this.state.canvas.height - areaHeight
      }
    };
  }

  private get idleArea() {
    const areaWidth = this.state.canvas.width * 0.3;
    const areaHeight = this.state.canvas.height * 0.3;

    return {
      width: areaWidth,
      height: areaHeight,
      x: this.state.canvas.width / 2 - areaWidth / 2,
      y: this.state.canvas.height / 2 - areaHeight / 2
    };
  }

  private getArea(answer: string) {
    if (!this.props.showResult) {
      return this.idleArea;
    }

    type Key = keyof Props["areas"];

    const areaKeys = Object.keys(this.props.areas) as Key[];
    const match = areaKeys.find(areaKey => {
      return this.props.areas[areaKey].label === answer;
    });

    if (!match) {
      return this.idleArea;
    }

    return this.areas[match];
  }

  render() {
    const { width, height } = this.state.canvas;
    const scale = 0.75;

    const color = this.props.color || "primary";

    return (
      <>
        <div
          ref="canvas"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }}
        >
          <Stage ref="stage" width={width} height={height}>
            <Layer
              scale={{ x: scale, y: scale }}
              x={(width * (1 - scale)) / 2}
              y={(height * (1 - scale)) / 2}
            >
              <Rect
                x={0}
                y={0}
                width={width}
                height={height}
                stroke={this.props.theme.palette[color].main}
                strokeWidth={4}
              />

              <DrawableTextCircle
                text={this.props.areas.topLeft.label}
                color={color}
                actualColor={color}
                grow="right"
                position={{ x: 0, y: 0 }}
              />

              <DrawableTextCircle
                text={this.props.areas.topRight.label}
                color={color}
                actualColor={color}
                grow="left"
                position={{ x: width, y: 0 }}
              />

              <DrawableTextCircle
                text={this.props.areas.bottomLeft.label}
                color={color}
                actualColor={color}
                grow="right"
                position={{ x: 0, y: height }}
              />

              <DrawableTextCircle
                text={this.props.areas.bottomRight.label}
                color={color}
                actualColor={color}
                grow="left"
                position={{ x: width, y: height }}
              />
            </Layer>

            <Layer name="areas">
              <Rect
                name={this.props.areas.topLeft.label}
                {...this.areas.topLeft}
              />

              <Rect
                name={this.props.areas.topRight.label}
                {...this.areas.topRight}
              />

              <Rect
                name={this.props.areas.bottomLeft.label}
                {...this.areas.bottomLeft}
              />

              <Rect
                name={this.props.areas.bottomRight.label}
                {...this.areas.bottomRight}
              />
            </Layer>

            <Layer>
              {this.props.answers.map(answer => (
                <DrawableAvatar
                  key={answer.id}
                  shaking={!this.props.showResult}
                  area={this.getArea(answer.text)}
                  avatar={+answer.student.avatar}
                />
              ))}
            </Layer>
          </Stage>
        </div>
      </>
    );
  }

  private handleResize() {
    const canvas = this.refs.canvas as HTMLElement;

    const handler = new ResizeObserver(() => {
      const { clientWidth, clientHeight } = canvas;

      this.setState({
        canvas: {
          width: clientWidth,
          height: clientHeight
        }
      });
    });

    handler.observe(canvas);

    this.setState({
      resizeObserver: handler
    });
  }
}

export default withTheme()(FyraHörnInput);
