import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { Activity } from "friends-shared";
import { default as React, Fragment, useContext, useState } from "react";
import styled from "styled-components";
import { FriendsContext } from "../../context";
import { socket } from "../../socket";
import { FriendsButton, FriendsInput, FriendsInputLabel } from "../../theme";
import SaveActivities from "./SaveActivities";

const ActivitySummaryContainer = styled.div`
  margin: 1rem 0;
`;

interface Props {
  noSaveButton?: boolean;
}

const CreateActivityForm: React.FC = () => {
  const { workshop } = useContext(FriendsContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  return (
    <>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FriendsInputLabel shrink>Rubrik</FriendsInputLabel>
            <FriendsInput
              fullWidth
              placeholder="Skriv här..."
              value={title}
              onChange={event => setTitle(event.target.value)}
              inputProps={{ maxLength: 50 }}
              style={{
                backgroundColor: "white",
                boxSizing: "border-box",
                padding: ".5rem .75rem"
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FriendsInputLabel shrink>Aktivitet</FriendsInputLabel>

            <FriendsInput
              fullWidth
              multiline
              rows="5"
              placeholder="Skriv här..."
              value={description}
              onChange={event => setDescription(event.target.value)}
              inputProps={{ maxLength: 300 }}
              style={{
                backgroundColor: "white",
                boxSizing: "border-box",
                padding: "1rem",
                marginBottom: "1rem"
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <FriendsButton
        variant="contained"
        color="primary"
        disabled={
          !title || !description || workshop.exerciseActivities.length >= 3
        }
        style={{ display: "block", marginLeft: "auto" }}
        onClick={async () => {
          await socket.submitActivity({
            title,
            description
          });

          setTitle("");
          setDescription("");
        }}
      >
        Skapa aktivitet
      </FriendsButton>
    </>
  );
};

const ActivitySummary: React.FC<{ activity: Activity }> = props => {
  return (
    <ActivitySummaryContainer>
      <IconButton
        onClick={() => socket.deleteActivity(props.activity.id)}
        aria-label="Delete"
        style={{ float: "right" }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>

      <Typography
        gutterBottom
        variant="h6"
        color="textSecondary"
        style={{ wordBreak: "break-word" }}
      >
        {props.activity.title}
      </Typography>

      <Typography paragraph style={{ wordBreak: "break-word" }}>
        {props.activity.description}
      </Typography>
    </ActivitySummaryContainer>
  );
};

const CreatedActivities: React.FC<Props> = props => {
  const { workshop } = useContext(FriendsContext);

  if (workshop.exerciseActivities.length <= 0) {
    return null;
  }

  return (
    <>
      <Typography
        className="typography-bold"
        color="textSecondary"
        variant="h5"
        gutterBottom
      >
        Sparade aktiviteter
      </Typography>

      {workshop.exerciseActivities.map((activity, index) => (
        <Fragment key={activity.id}>
          {index > 0 && <Divider variant="fullWidth" />}

          <ActivitySummary activity={activity} />
        </Fragment>
      ))}

      {workshop.exercise.data && !props.noSaveButton && (
        <SaveActivities
          exercise={workshop.exercise.data}
          activities={workshop.exerciseActivities}
        >
          <FriendsButton
            variant="contained"
            color="primary"
            disabled={workshop.exerciseActivities.length === 0}
            style={{ marginTop: 24, display: "block", marginLeft: "auto" }}
          >
            Spara aktiviteter
          </FriendsButton>
        </SaveActivities>
      )}
    </>
  );
};

const Activities: React.FC<Props> = props => {
  return (
    <Grid container spacing={40}>
      <Grid item>
        <Typography
          className="typography-bold"
          color="primary"
          variant="h5"
          gutterBottom
        >
          2. Ta fram aktiviteter
        </Typography>

        <Typography paragraph>{props.children}</Typography>

        <CreateActivityForm />
      </Grid>

      <Grid item xs={12}>
        <CreatedActivities {...props} />
      </Grid>
    </Grid>
  );
};

export default Activities;
