import {
  Divider,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import { Answer } from "friends-shared";
import React, { Fragment, useContext, useState } from "react";
import styled from "styled-components";
import { Assets } from "../../../shared/assets";
import { ConfirmDeletion } from "../../../shared/components/ConfirmDeletion";
import { FriendsButton } from "../../../shared/theme";
import { FriendsContext } from "../context";

const CommentsButtonContainer = styled.div`
  > button {
    padding: 0;
    min-width: unset;

    transform: scale(0.75);

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        padding-top: 0.2rem;
      }

      & > h5 {
        margin-left: 0.2rem;
        font-weight: 600;
      }
    }
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 3em;
  height: 3em;
  right: 0;
  margin-right: -0.9rem;
  height: 3rem;
  width: 1rem;

  &:before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1rem 0 1rem 1rem;
    border-color: transparent transparent transparent white;
    transform: translateY(25%);
    filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.1));
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  button {
    padding: 0.25rem;
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
  }
`;

const CommentContainer = styled.div`
  overflow-y: auto;
  max-height: 15rem;
`;

const Comment = styled.div`
  padding: 0.75rem 0;
  max-width: 20rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;

  > * {
    margin: 0 !important;
  }
`;

export const CommentsButton: React.SFC<{
  title: string;
  answers: Answer[];
  center?: boolean;
}> = props => {
  const { actions } = useContext(FriendsContext);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [arrowAnchor, setArrowAnchor] = useState<HTMLElement | null>(null);
  const [commentsAnchor, setCommentsAnchor] =
    useState<HTMLElement | null>(null);

  const [commentMarkedForDeletion, setCommentMarkedForDeletion] =
    useState<Answer | null>(null);

  const deleteAnswerComment = (answer: Answer | null) => {
    if (answer) {
      actions.deleteAnswerComment(answer.id);
      setCommentMarkedForDeletion(null);
    }
  };

  return (
    <CommentsButtonContainer
      style={props.center ? { display: "flex", justifyContent: "center" } : {}}
    >
      <FriendsButton
        variant="text"
        color="primary"
        onClick={event => {
          setCommentsAnchor(event.currentTarget);
          setIsCommentsVisible(!isCommentsVisible);
        }}
      >
        <img src={Assets.Ikoner.comment} alt="Kommentarer" />
        <Typography variant="h5">{props.answers.length}</Typography>
      </FriendsButton>

      <Popper
        open={isCommentsVisible}
        anchorEl={commentsAnchor}
        placement="left"
        transition={true}
        modifiers={{
          flip: {
            enabled: true
          },
          arrow: {
            enabled: true,
            element: arrowAnchor
          }
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>
              <Arrow ref={setArrowAnchor} />
              <Paper style={{ padding: "1rem 2rem", minWidth: 150 }}>
                <Typography variant="h4" color="secondary">
                  {props.title}

                  <CommentContainer>
                    {props.answers.map((answer, index) => (
                      <Fragment key={answer.id}>
                        {index > 0 && <Divider variant="fullWidth" />}

                        <Comment key={answer.id}>
                          <Typography paragraph>{answer.comment}</Typography>

                          <IconButton
                            onClick={() => setCommentMarkedForDeletion(answer)}
                          >
                            <DeleteRounded />
                          </IconButton>
                        </Comment>
                      </Fragment>
                    ))}
                  </CommentContainer>
                </Typography>

                <CloseButtonContainer>
                  <FriendsButton
                    variant="text"
                    color="primary"
                    onClick={event => setIsCommentsVisible(false)}
                  >
                    <Icon>close</Icon>
                  </FriendsButton>
                </CloseButtonContainer>

                <ConfirmDeletion
                  onConfirmDeletion={() =>
                    deleteAnswerComment(commentMarkedForDeletion)
                  }
                  onCancel={() => setCommentMarkedForDeletion(null)}
                  content={
                    commentMarkedForDeletion
                      ? commentMarkedForDeletion.comment
                      : undefined
                  }
                />
              </Paper>
            </div>
          </Fade>
        )}
      </Popper>
    </CommentsButtonContainer>
  );
};
