"use strict";

exports.__esModule = true;

var Codes = function () {
  function Codes() {}

  return Codes;
}();

exports.Codes = Codes;