import { Slide } from "@material-ui/core";
import { Exercise, Övningstyp } from "friends-shared";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TestIndicator } from "../../../shared/components/TestIndicator";
import { FriendsColors } from "../../../shared/theme";
import { getBackendURL } from "../../../shared/util/backend-url";
import { FriendsContext } from "../context";
import { withScreen } from "../Screen";
import CreateWorkshopScreen from "./CreateWorkshopScreen";
import { ExerciseSelection } from "./ExerciseSelection";
import WelcomeScreen from "./WelcomeScreen";

type Step = "welcome" | "school-info" | "exercise-selection";

@withScreen({
  colors: {
    primary: {
      main: FriendsColors.purple
    },
    background: {
      default: FriendsColors.lightgrey
    }
  },
  headerLogo: "yellow"
})
class Screen extends React.Component {
  render() {
    return this.props.children;
  }
}

const useAvailableExercises = () => {
  const [exercises, setExercises] = useState<Exercise[]>([]);

  useEffect(() => {
    const host = getBackendURL();
    const url = `${host}/exercises/all`;

    const load = async () => {
      const response = await fetch(url);
      const data = await response.json();

      setExercises(data);
    };

    load();
  }, []);

  return exercises;
};

const useLärarhandledningUrl = () => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const host = getBackendURL();
    const uri = `${host}/lararhandledning`;

    const load = async () => {
      const response = await fetch(uri, { method: "head" });

      if (response.ok) {
        setUrl(uri);
      }
    };

    load();
  }, []);

  return url;
};

const Start: React.FC = props => {
  const availableExercises = useAvailableExercises();
  const { actions } = useContext(FriendsContext);
  const [step, setStep] = useState<Step>("welcome");

  const [municipality, setMunicipality] = useState("");
  const [test, setTest] = useState(false);
  const [exercises, setExercises] = useState<Övningstyp[]>(
    Object.values(Övningstyp)
  );

  const url = useLärarhandledningUrl();

  const submit = useCallback(() => {
    actions.createWorkshop({
      test: test,
      municipality: municipality,
      selectedExercises: exercises
    });
  }, [actions, municipality, test, exercises]);

  if (step === "welcome") {
    return (
      <WelcomeScreen
        lärarhandledningUrl={url}
        onProceed={() => setStep("school-info")}
      />
    );
  }

  return (
    <Screen>
      <Slide direction="down" in={test}>
        <TestIndicator />
      </Slide>

      {step === "school-info" && (
        <CreateWorkshopScreen
          test={test}
          onTestChange={setTest}
          municipality={municipality}
          onMunicipalityChange={setMunicipality}
          onPrev={() => setStep("welcome")}
          onNext={() => setStep("exercise-selection")}
        />
      )}

      {step === "exercise-selection" && (
        <ExerciseSelection
          availableExercises={availableExercises}
          exercises={exercises}
          onChange={setExercises}
          onPrev={() => setStep("school-info")}
          onNext={submit}
        />
      )}
    </Screen>
  );
};

export default Start;
