import { Grid } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import {
  Activity,
  Answer,
  Exercise,
  Slidetyp,
  Övningstyp
} from "friends-shared";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import { getBackendURL } from "../../../../shared/util/backend-url";
import PrintableActivities from "../../common/Printable/PrintableActivities";
import { PrintableRadarChart } from "../../common/Printable/PrintableRadarChart";
import PrintWordcloudAndActivities from "../../common/Printable/PrintableWordcloudAndActivities";
import { FriendsContext } from "../../context";
import { FriendsButton } from "../../theme";

interface WorkshopResult {
  exercise: Exercise;
  activities: Activity[];
  answers: Answer[];
}

interface Props {}

const printStyle = `
  @page {
    size: A4 landscape;
    margin: 0mm;
  }

  @media print {
    html, body {
      height: 100%;
      width: 100%;
      position: relative;
    }

    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;

const PrintContainer = styled.div`
  position: fixed;
  top: -10000px;
`;

const ExportResultsButton: React.FC = () => {
  const { workshop } = useContext(FriendsContext);
  const { id } = workshop;

  if (!id) {
    return null;
  }

  if (workshop.test) {
    return null;
  }

  const host = getBackendURL();
  const url = `${host}/export/${id}`;

  return (
    <a href={url} download style={{ color: "inherit", textDecoration: "none" }}>
      <FriendsButton fullWidth color="primary" variant="contained">
        Allt resultat i Excel
        <DownloadIcon
          style={{
            position: "absolute",
            right: "1rem"
          }}
        />
      </FriendsButton>
    </a>
  );
};

interface PrintExerciseProps {
  label: string;
  children: (ref: React.MutableRefObject<any>) => React.ReactNode;
}

const PrintExercise: FC<PrintExerciseProps> = props => {
  const componentRef = useRef<any>(null);

  return (
    <div>
      <ReactToPrint
        pageStyle={printStyle}
        trigger={() => (
          <FriendsButton fullWidth variant="contained" color="primary">
            {props.label}
            <DownloadIcon
              style={{
                position: "absolute",
                right: "1rem"
              }}
            />
          </FriendsButton>
        )}
        content={() => componentRef.current as any}
      />

      <PrintContainer>{props.children(componentRef)}</PrintContainer>
    </div>
  );
};

const getfirstExerciseActivity = <T extends Övningstyp>(
  exercise: Exercise,
  type: T
) => {
  if (exercise.type !== type) {
    return;
  }

  for (let i = 0; i < exercise.slides.length; i++) {
    const slide = exercise.slides[i];

    if (slide.typ === Slidetyp.Aktivitet) {
      type A = Extract<Exercise, { type: T }>["slides"][number];
      type B = Extract<A, { typ: Slidetyp.Aktivitet }>;

      return slide as unknown as B;
    }
  }
};

export const SaveExercises: React.FC<Props> = props => {
  const [results, setResults] = useState<WorkshopResult[]>([]);

  const { workshop } = useContext(FriendsContext);

  useEffect(() => {
    if (!workshop.id) {
      return;
    }

    const host = getBackendURL();
    const url = `${host}/workshop/${workshop.id}/results`;

    fetch(url).then(response => {
      response.json().then(setResults);
    });
  }, [workshop]);

  const skalFrågor = results.find(
    result => result.exercise.type === Övningstyp.Skalfrågor
  );

  const tryggKlass = results.find(
    result => result.exercise.type === Övningstyp.TryggKlass
  );

  const tryggaOchOtryggaPlatser = results.find(
    result => result.exercise.type === Övningstyp.TryggaOchOtryggaPlatser
  );

  return (
    <Grid
      container
      spacing={8}
      direction="column"
      justify="center"
      alignItems="stretch"
    >
      <Grid item>
        <ExportResultsButton />
      </Grid>

      {tryggKlass && (
        <Grid item>
          <PrintExercise label="Ordmoln + aktiviteter Trygg Klass">
            {ref => {
              return (
                <PrintWordcloudAndActivities
                  answers={tryggKlass.answers}
                  activities={tryggKlass.activities}
                  ref={ref}
                />
              );
            }}
          </PrintExercise>
        </Grid>
      )}

      {skalFrågor && (
        <Grid item>
          <PrintExercise label="Diagram Klassens Kompass">
            {ref => {
              const slide = getfirstExerciseActivity(
                skalFrågor.exercise,
                Övningstyp.Skalfrågor
              );

              if (!slide) {
                return null;
              }

              return (
                <PrintableRadarChart
                  answers={skalFrågor.answers}
                  slide={slide}
                  ref={ref}
                />
              );
            }}
          </PrintExercise>
        </Grid>
      )}

      {tryggaOchOtryggaPlatser && (
        <Grid item>
          <PrintExercise label="Aktiviteter Trygga/otrygga platser">
            {ref => {
              return (
                <PrintableActivities
                  exercise={tryggaOchOtryggaPlatser.exercise}
                  activities={tryggaOchOtryggaPlatser.activities}
                  ref={ref}
                />
              );
            }}
          </PrintExercise>
        </Grid>
      )}
    </Grid>
  );
};
