"use strict";

exports.__esModule = true;

var Activity = function () {
  function Activity() {}

  return Activity;
}();

exports.Activity = Activity;