import { Grid, Typography } from "@material-ui/core";
import { TryggaOchOtryggaPlatser } from "friends-shared";
import React from "react";
import styled from "styled-components";
import { FriendsColors } from "../../../../../theme";
import { PlatserOverview } from "./components/PlatserOverview";

const ProjectorSlide = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 5vh;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    margin: 0.5rem;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${props => props.color};
    border-radius: 100%;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: calc(110vh);
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

interface Props {
  slide: TryggaOchOtryggaPlatser.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  return (
    <ProjectorSlide>
      <Grid container spacing={24} direction="column">
        <Grid item xs={12}>
          <Typography
            className="typography-bold"
            variant="h3"
            color="primary"
            align="center"
          >
            Så här svarade ni
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={16} justify="center">
            <Grid item>
              <Box color={FriendsColors.lime}>
                <Typography variant="h6">Trygg</Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box color={FriendsColors.red}>
                <Typography variant="h6">Otrygg</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ flex: 1 }}>
          <Content>
            <PlatserOverview steg={props.slide} />
          </Content>
        </Grid>
      </Grid>
    </ProjectorSlide>
  );
};
