import { Grid, Typography } from "@material-ui/core";
import { TryggaOchOtryggaPlatser } from "friends-shared";
import React from "react";
import Activities from "../../../../lobby/Activities";
import { DiscussionBox, DiscussionText } from "../../../../lobby/Discussion";
import { Dashboard } from "../../common/Dashboard";
import { PlatserOverview } from "./components/PlatserOverview";

interface Props {
  slide: TryggaOchOtryggaPlatser.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  return (
    <Dashboard steg={props.slide}>
      <Grid item xs={3}>
        <Typography
          className="typography-bold"
          color="primary"
          variant="h5"
          gutterBottom
        >
          1. Diskutera resultatet
        </Typography>

        <Typography paragraph gutterBottom>
          Inled ett samtal om resultatet, eleverna har också möjlighet att göra
          anonyma inlägg. Ställ fördjupande frågor om de olika platserna, och
          låt eleverna komma med idéer på hur tryggheten kan öka. Kan ni enas om
          tre saker?
        </Typography>

        <DiscussionText />
        <DiscussionBox />
      </Grid>

      <Grid item xs={6}>
        <PlatserOverview />
      </Grid>

      <Grid item xs={3}>
        <Activities>
          Skriv de tre förslag som ni i klassen enats om. Exempelvis: Vi vill ha
          fler vuxna i korridorerna. Glöm inte att återkoppla till eleverna
          längre fram.
        </Activities>
      </Grid>
    </Dashboard>
  );
};
