import React from "react";
import { StateConsumer } from "../../context";
import ConnectedUsers from "./ConnectedUsers";

class Lobby extends React.Component<{}> {
  render() {
    return (
      <StateConsumer>
        {state => (
          <ConnectedUsers onComplete={() => state.actions.startWorkshop()} />
        )}
      </StateConsumer>
    );
  }
}

export default Lobby;
