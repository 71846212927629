import { FyraHörn } from "friends-shared";
import React from "react";
import { AktivitetAndResultat } from "./components/AktivitetAndResultat";

interface Props {
  slide: FyraHörn.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  return <AktivitetAndResultat slide={props.slide} />;
};
