import { CircularProgress, Fade, Grow, Typography } from "@material-ui/core";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { FriendsContext } from "../../../../context";
import { FriendsButton, FriendsInput } from "../../../../theme";

const Field = styled.div`
  position: relative;
  margin-bottom: 1rem;

  .discussion-input {
    background-color: white;
    box-sizing: border-box;
    padding: 1rem;
    transition: opacity 0.15s ease;
  }
`;

const Spinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DiskussionsInput: React.SFC = () => {
  const context = useContext(FriendsContext);
  const [text, setText] = useState("");
  const ref = useRef<HTMLTextAreaElement>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = () => {
    setIsSubmitting(true);

    context.actions.submitMessage(text);

    setTimeout(() => {
      setIsSubmitting(false);
      setText("");

      if (ref.current) {
        ref.current.focus();
      }
    }, 750);
  };

  return (
    <Grow in>
      <div style={{ paddingTop: "1rem" }}>
        <Typography variant="h6" color="primary">
          Tyck till om resultatet
        </Typography>

        <Typography
          className="typography-bold"
          variant="h5"
          gutterBottom
          color="primary"
        >
          Diskutera i klassen
        </Typography>

        <Typography paragraph>
          Här kan du bidra till diskussionen. Du kan göra flera inlägg. Dina
          svar är anonyma och visas endast för läraren. Kom ihåg att inte skriva
          några namn.
        </Typography>

        <Field>
          <FriendsInput
            inputRef={ref}
            className="discussion-input"
            fullWidth
            multiline
            rows="5"
            autoFocus
            placeholder="Skriv här..."
            value={text}
            onChange={event => setText(event.target.value)}
            inputProps={{ maxLength: 300 }}
            style={{
              opacity: isSubmitting ? 0.5 : 1
            }}
          />

          <Fade in={isSubmitting} mountOnEnter unmountOnExit>
            <Spinner>
              <CircularProgress color="primary" />
            </Spinner>
          </Fade>
        </Field>

        <FriendsButton
          fullWidth
          color="secondary"
          variant="contained"
          onClick={submit}
          disabled={!text || isSubmitting}
        >
          Skicka
        </FriendsButton>
      </div>
    </Grow>
  );
};
