import { Button } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

export const FriendsColors = {
  lightbeige: "#fff29c",
  lightgrey: "#f8f8f8",
  purple: "#941d80",
  red: "#d60e51",
  lime: "#009c8f",
  blue: "#38a8e0",
  white: "#fff",
  yellow: "#fad501",
  orange: "#f7b233"
};

export const FriendsButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0.4rem 1.75rem"
    },
    label: {
      textTransform: "none"
    },
    contained: {
      color: theme.palette.background.default
    },
    text: {
      borderRadius: 3,
      border: 0
    }
  })
)(Button);
