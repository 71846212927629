import { Socket } from '../../shared/socket';


class ProjectorSocket extends Socket {
  async joinWorkshop(code: string) {
    return this.emit('join workshop as projector', code);
  }

  requestAnswersForPreviousSlide() {
    return this.emit('request answers for previous slide')
  }
}

const socket = new ProjectorSocket();

export { socket };

