import { FyraHörn, Övningstyp } from "friends-shared";
import React, { useContext } from "react";
import { Assets } from "../../../../../../../shared/assets";
import { FriendsContext } from "../../../../../context";
import { useCurrentSituationIndex } from "../../../../../hooks";
import InstruktionerSlide from "../../common/Instruktioner";
import IntroduktionSlide from "../../common/Introduktion";
import { Situation } from "./components/Scenario";

interface Props {
  slide: FyraHörn.Slide.Instruktioner;
}

export const Instruktioner: React.SFC<Props> = props => {
  const { workshop } = useContext(FriendsContext);
  const situation = useCurrentSituationIndex();

  if (!workshop.exercise.data) {
    return null;
  }

  switch (workshop.exercise.currentSlideIndex) {
    case 0:
      return (
        <IntroduktionSlide
          steg={props.slide}
          image={Assets.Uppdrag[Övningstyp.FyraHörn].cover}
        />
      );
    case 1:
      return (
        <InstruktionerSlide
          steg={props.slide}
          images={{
            bottom: Assets.Uppdrag[Övningstyp.FyraHörn].förklaring2,
            right: Assets.Uppdrag[Övningstyp.FyraHörn].förklaring1
          }}
        />
      );
  }

  return <Situation slide={props.slide} situation={situation} />;
};
