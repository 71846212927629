import {
  Answer,
  Student,
  Workshop,
  WorkshopProgress,
  WorkshopStatus
} from "friends-shared";
import * as React from "react";
import SessionUrlHandler, {
  getSessionIdFromURL
} from "../../shared/url-session";
import { Log } from "../../shared/util/logging";
import { getQueryParam, removeQueryParam } from "../../shared/util/queryParams";
import { socket } from "./socket";

interface ClientActions {
  joinWorkshop(code: string): Promise<void>;
  leaveWorkshop(): Promise<void>;
}

export interface ClientState {
  actions: ClientActions;
  sessionId: string | null;
  workshop: {
    id: number | null;
    municipality: string;
    studentCode: string;
    exercise: WorkshopProgress;
    exerciseAnswers: Answer[];
    status: WorkshopStatus | null;
    answers: Answer[];
    students: Student[];
  };
}

export const FriendsContext = React.createContext({} as ClientState);

class StateProvider extends React.Component<{}, ClientState> {
  componentWillMount() {
    const sessionId = getSessionIdFromURL("projektor");

    Log.session("session from url", sessionId);

    this.setState({
      sessionId
    });
  }

  componentDidMount() {
    socket.connect();

    socket.on<Student[]>("connected students change").subscribe(students => {
      this.setWorkshopStudents(students);
    });

    socket.on<WorkshopProgress>("slide").subscribe(slide => {
      this.setWorkshopSlide(slide);
    });

    socket.on<Workshop>("joined workshop").subscribe(workshop => {
      this.setWorkshop(workshop);
    });

    socket.on<Answer[]>("exercise answers").subscribe(answers => {
      this.setWorkshopExerciseAnswers(answers);
    });

    socket.on<WorkshopStatus>("workshop status change").subscribe(status => {
      this.setWorkshopStatus(status);
    });

    socket.on<Answer[]>("answers from previous slide").subscribe(answers => {
      this.setWorkshopAnswers(answers);
    });

    socket.on<string>("authenticated").subscribe(sessionId => {
      this.setSessionId(sessionId);
    });

    socket.on<void>("authentication failed").subscribe(() => {
      this.setSessionId(null);
      this.leaveWorkshop();
    });

    if (this.state.sessionId) {
      socket.authenticate(this.state.sessionId);
    }

    if (socket.instance) {
      socket.instance.on("reconnect", () => {
        if (this.state.sessionId) {
          socket.authenticate(this.state.sessionId);
        }
      });
    }

    // If "?code=123456" is set we try to connect to it on initial load
    (async () => {
      const code = getQueryParam("code");

      if (!code) {
        return;
      }

      try {
        await this.joinWorkshop(code);
      } catch (error) {
        console.error(error);
      }

      removeQueryParam(["code"]);
    })();
  }

  componentWillUnmount() {
    socket.disconnect();
  }

  constructor(props: {}) {
    super(props);

    this.state = {
      sessionId: null,
      workshop: {
        id: null,
        municipality: "",
        studentCode: "",
        exercise: {
          data: null,
          currentExerciseIndex: 0,
          currentSlideIndex: 0,
          totalExercises: 0,
          totalSlides: 0
        },
        status: null,
        exerciseAnswers: [],
        answers: [],
        students: []
      },
      actions: {
        joinWorkshop: this.joinWorkshop.bind(this),
        leaveWorkshop: this.leaveWorkshop.bind(this)
      }
    };
  }

  render() {
    return (
      <FriendsContext.Provider value={this.state}>
        <SessionUrlHandler
          basename="projektor"
          sessionId={this.state.sessionId}
          sessionIdChange={sessionId => this.setSessionId(sessionId)}
        />
        {this.props.children}
      </FriendsContext.Provider>
    );
  }

  async joinWorkshop(code: string): Promise<void> {
    await socket.joinWorkshop(code);
  }

  async leaveWorkshop(): Promise<void> {
    this.setSessionId(null);
    socket.leaveWorkshop().then();

    this.setState({
      workshop: {
        id: null,
        municipality: "",
        studentCode: "",
        exercise: {
          data: null,
          currentExerciseIndex: 0,
          currentSlideIndex: 0,
          totalExercises: 0,
          totalSlides: 0
        },
        status: null,
        exerciseAnswers: [],
        answers: [],
        students: []
      }
    });
  }

  private setWorkshopStudents(students: Student[]) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        students
      }
    });
  }

  private setWorkshop(workshop: Workshop) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        id: workshop.id,
        municipality: workshop.municipality,
        studentCode: workshop.codes.student,
        status: workshop.status
      }
    });
  }

  private setWorkshopSlide(exercise: WorkshopProgress) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        exercise
      }
    });
  }

  private setWorkshopStatus(status: WorkshopStatus) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        status
      }
    });
  }

  private setWorkshopExerciseAnswers(answers: Answer[]) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        exerciseAnswers: answers
      }
    });
  }

  private setWorkshopAnswers(answers: Answer[]) {
    if (!this.state.workshop.exercise) {
      return;
    }

    this.setState({
      workshop: {
        ...this.state.workshop,
        answers: answers
      }
    });
  }

  private setSessionId(sessionId: string | null) {
    this.setState({
      sessionId
    });
  }
}

const StateConsumer = FriendsContext.Consumer;

export { StateProvider, StateConsumer };
