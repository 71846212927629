import { Typography } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import React from "react";
import { ResponsiveScreen } from "../../Screen";
import { FriendsColors } from "../../theme";

const colors: PaletteOptions = {
  background: {
    default: FriendsColors.red
  },
  primary: {
    main: FriendsColors.lightbeige
  },
  secondary: {
    main: FriendsColors.purple
  }
};

const Finished: React.FC = () => {
  return (
    <ResponsiveScreen fixedHeight logotype="yellow" colors={colors}>
      <Typography
        color="primary"
        className="typography-bold"
        variant="h2"
        gutterBottom
        style={{ whiteSpace: "nowrap", paddingTop: "1rem" }}
      >
        Bra jobbat!
      </Typography>

      <Typography color="primary" paragraph>
        Behöver du någon att prata med? Du kan kontakta Friends råd och stöd på
        radgivning@friends.se eller 08-545 519 90.
      </Typography>

      <Typography color="primary" paragraph>
        Du kan också ringa till Bris för att få prata med en kurator. Numret är
        116 111. Det är gratis och du är självklart anonym om du vill.
      </Typography>
    </ResponsiveScreen>
  );
};

export default Finished;
