import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { FriendsConfiguration } from "../../../shared/configuration";
import {
  FriendsButton,
  FriendsInputLabel,
  FriendsMenuItem,
  FriendsSelect
} from "../theme";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 350px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  .test-dialog {
    background: #eeeeee;
    margin-bottom: 16px;

    border: 1px solid rgba(0, 0, 0, 0.04);
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: 8px;

    label {
      user-select: none;
    }
  }
`;

interface Props {
  municipality: string;
  test: boolean;

  onMunicipalityChange: (municipality: string) => void;
  onTestChange: (test: boolean) => void;
  onNext: () => void;
  onPrev: () => void;
}

class CreateWorkshopScreen extends React.Component<Props> {
  render() {
    return (
      <Container>
        <Grid container justify="center" alignItems="center" spacing={32}>
          <Grid item xs={12}>
            <Typography
              paragraph
              align="center"
              style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
            >
              För att få en bild av hur verktyget används runtom i landet, ber
              vi dig fylla i din kommun.
            </Typography>
          </Grid>

          <Grid item xs={12} className="test-dialog">
            <FormControl fullWidth>
              <Typography
                paragraph
                align="center"
                style={{
                  fontFamily: "inherit",
                  margin: 0,
                  fontSize: "0.8rem"
                }}
              >
                Om du vill prova köra igenom workshopen för att se hur allt
                fungerar utan att någon data sparas så kan du slå på testläget:
              </Typography>

              <FormControlLabel
                style={{
                  justifyContent: "center"
                }}
                control={
                  <Checkbox
                    color="primary"
                    checked={this.props.test}
                    onChange={event =>
                      this.props.onTestChange(event.target.checked)
                    }
                  />
                }
                label="Testläge"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FriendsInputLabel shrink>Kommun</FriendsInputLabel>
              <FriendsSelect
                displayEmpty
                value={this.props.municipality}
                onChange={change =>
                  this.props.onMunicipalityChange(change.target.value)
                }
              >
                <FriendsMenuItem value="" disabled>
                  Välj kommun
                </FriendsMenuItem>

                {FriendsConfiguration.municipalities.map(municipality => (
                  <FriendsMenuItem key={municipality} value={municipality}>
                    {municipality}
                  </FriendsMenuItem>
                ))}
              </FriendsSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FriendsButton
              fullWidth
              variant="text"
              color="primary"
              onClick={() => this.props.onPrev()}
            >
              Bakåt
            </FriendsButton>

            <FriendsButton
              fullWidth
              disabled={!this.props.test && !this.props.municipality}
              variant="contained"
              color="primary"
              onClick={() => this.props.onNext()}
            >
              Vidare
            </FriendsButton>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default CreateWorkshopScreen;
