import { Typography } from "@material-ui/core";
import { TryggKlass } from "friends-shared";
import React from "react";
import styled from "styled-components";
import FriendsWordcloud from "./components/WordCloud";

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface Props {
  slide: TryggKlass.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  return (
    <Container>
      <Typography
        className="typography-bold"
        align="center"
        variant="h1"
        color="primary"
      >
        Så här valde ni
      </Typography>

      <FriendsWordcloud steg={props.slide} />
    </Container>
  );
};
