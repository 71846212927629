import { TryggKlass } from "friends-shared";
import React from "react";
import TitleAndSubtitle from "../../common/TitleAndSubtitle";

interface Props {
  slide: TryggKlass.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  return (
    <TitleAndSubtitle
      title={props.slide.descriptions.projector.value}
      subtitle="Välj 1-5 alternativ"
    />
  );
};
