import { Grid, Typography } from "@material-ui/core";
import { AbstractSlide, FyraHörn, Slidetyp } from "friends-shared";
import React, { useContext } from "react";
import { CommentsButton } from "../../../../../../common/CommentsButton";
import { ClientState, FriendsContext } from "../../../../../../context";
import FyraHornInput from "./FyraHörnInput";

const getLastSituatuinAnswers = (
  { workshop }: ClientState,
  slide: FyraHörn.Slide
) => {
  if (!workshop.exercise.data || slide.typ !== Slidetyp.Aktivitet) {
    return [];
  }

  const slides = workshop.exercise.data.slides as AbstractSlide[];
  const slideIndex = slides.indexOf(slide);

  return workshop.exerciseAnswers.filter(answer => {
    return answer.slideIndex === slideIndex && answer.comment;
  });
};

interface Props {
  slide: FyraHörn.Slide;
}

export const FyraHörnOverview: React.SFC<Props> = props => {
  const state = useContext(FriendsContext);

  if (props.slide.typ !== Slidetyp.Aktivitet) {
    return null;
  }

  const { areas } = props.slide.questions[0];
  const answers = getLastSituatuinAnswers(state, props.slide);

  return (
    <Grid container spacing={32} wrap="nowrap">
      <Grid item xs={4}>
        <Grid
          container
          spacing={32}
          direction="column"
          wrap="nowrap"
          justify="space-between"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Typography
              align="center"
              paragraph
              style={{ marginBottom: ".25rem" }}
            >
              {areas.topLeft.text}
            </Typography>
            <CommentsButton
              center
              title={areas.topLeft.label}
              answers={answers.filter(
                answer => answer.text === areas.topLeft.label
              )}
            />
          </Grid>

          <Grid item>
            <Typography
              align="center"
              paragraph
              style={{ marginBottom: ".25rem" }}
            >
              {areas.bottomLeft.text}
            </Typography>
            <CommentsButton
              center
              title={areas.bottomLeft.label}
              answers={answers.filter(
                answer => answer.text === areas.bottomLeft.label
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4} style={{ position: "relative" }}>
        <FyraHornInput areas={areas} />
      </Grid>

      <Grid item xs={4}>
        <Grid
          container
          spacing={32}
          direction="column"
          wrap="nowrap"
          justify="space-between"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Typography
              align="center"
              paragraph
              style={{ marginBottom: ".25rem" }}
            >
              {areas.topRight.text}
            </Typography>
            <CommentsButton
              center
              title={areas.topRight.label}
              answers={answers.filter(
                answer => answer.text === areas.topRight.label
              )}
            />
          </Grid>

          <Grid item>
            <Typography
              align="center"
              paragraph
              style={{ marginBottom: ".25rem" }}
            >
              {areas.bottomRight.text}
            </Typography>
            <CommentsButton
              center
              title={areas.bottomRight.label}
              answers={answers.filter(
                answer => answer.text === areas.bottomRight.label
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
