import { Grid, Typography } from "@material-ui/core";
import React from "react";
import FriendsHeart from "../../../../shared/components/Heart";
import { ScreenProps, withScreen } from "../../Screen";
import { FriendsColors } from "../../theme";

@withScreen({
  buttons: [],
  colors: {
    background: FriendsColors.purple,
    primary: FriendsColors.yellow,
    secondary: FriendsColors.lightbeige,
    textPrimary: FriendsColors.yellow,
    textSecondary: FriendsColors.white
  },
  headerLogo: "lightyellow"
})
export default class WaitForStart extends React.Component<ScreenProps> {
  render() {
    return (
      <Grid container direction="column">
        <Grid item>
          <FriendsHeart />
        </Grid>

        <Grid item>
          <Typography
            className="typography-bold"
            variant="h3"
            align="center"
            gutterBottom
            color="textPrimary"
          >
            Bra!
          </Typography>

          <Typography variant="h6" align="center" color="textPrimary">
            Din lärare startar strax ert första uppdrag.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}
