import { Activity, Exercise } from "friends-shared";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import PrintableActivities from "../../common/Printable/PrintableActivities";

const PrintContainer = styled.div`
  position: fixed;
  top: -10000px;
`;

const printStyle = `
  @page {
    size: A4 landscape;
    margin: 0mm;
  }

  @media print {
    html, body {
      height: 100%;
      width: 100%;
      position: relative;
    }

    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;

interface Props {
  exercise: Exercise;
  activities: Activity[];
}

const SaveActivities: React.FC<Props> = props => {
  const componentRef = useRef<any>(null);

  return (
    <div>
      <ReactToPrint
        pageStyle={printStyle}
        trigger={() => <div>{props.children}</div>}
        content={() => componentRef.current as any}
      />

      <PrintContainer>
        <PrintableActivities
          exercise={props.exercise}
          activities={props.activities}
          ref={componentRef}
        />
      </PrintContainer>
    </div>
  );
};

export default SaveActivities;
