import { ButtonBase, withTheme, WithTheme } from "@material-ui/core";
import React from "react";
import styled, { keyframes } from "styled-components";
import HeartAsset from "../assets/heart/Friends_iconheart_color_white.svg";

const rotate = keyframes`
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.02) skewY(-1deg) rotate(1deg);
  }

  100% {
    transform: scale(1);
  }
`;

const Container = styled.div`
  animation: ${rotate} 2.5s cubic-bezier(0, 0, 0, 0.9) infinite;
  margin: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Heart = styled.div`
  height: 12.5rem;
  width: 12.5rem;
`;

interface Props extends WithTheme {
  color?: string;
}

const FriendsHeart: React.SFC<Props> = props => {
  return (
    <Container>
      <ButtonBase
        style={{
          backgroundColor: props.color || props.theme.palette.primary.main,
          maskImage: `url(${HeartAsset})`,
          WebkitMaskImage: `url(${HeartAsset})`
        }}
      >
        <Heart />
      </ButtonBase>
    </Container>
  );
};

export default withTheme()(FriendsHeart);
