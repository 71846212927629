import Konva from "konva";
import React, { ReactInstance } from "react";
import { animated, Spring } from "react-spring/konva";
import useImage from "use-image";
import { FriendsAvatarCollection } from "../../../../../../../../shared/components/Avatar";

interface Props extends Partial<Konva.ImageConfig> {
  avatar: HTMLImageElement;
}

interface State {
  hover: boolean;
  dragging: boolean;
}

class Avatar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hover: false,
      dragging: false
    };
  }

  componentDidMount() {
    const ref: Konva.Image = (this as any).imageRef;

    if (!ref) {
      return;
    }

    ref.on("mouseover", () => {
      document.body.style.cursor = "pointer";
      this.setState({ hover: true });
    });

    ref.on("mouseout", () => {
      document.body.style.cursor = "default";
      this.setState({ hover: false });
    });

    ref.on("mousedown", () => {
      if (this.state.hover) {
        this.setState({ dragging: true });
      }
    });

    ref.on("mouseup", () => {
      if (this.state.dragging) {
        this.setState({ dragging: false });
      }
    });

    ref.on("touchstart", () => {
      document.body.style.cursor = "pointer";
      this.setState({ hover: true, dragging: true });
    });

    ref.on("touchend", () => {
      document.body.style.cursor = "default";
      this.setState({ hover: false, dragging: false });
    });
  }

  render() {
    return (
      <Spring
        native
        config={{ tension: 450, friction: 10 }}
        from={{}}
        to={{
          scaleX: this.state.dragging ? 1.1 : this.state.hover ? 1.025 : 1,
          scaleY: this.state.dragging ? 1.1 : this.state.hover ? 1.025 : 1
        }}
      >
        {(props: any) => (
          <animated.Image
            ref={(ref: ReactInstance) => ((this as any).imageRef = ref)}
            image={this.props.avatar}
            {...props as any}
            x={this.props.x}
            y={this.props.y}
            width={this.props.width}
            height={this.props.height}
            offsetX={this.props.offsetX}
            offsetY={this.props.offsetY}
            draggable={this.props.draggable}
            dragBoundFunc={this.props.dragBoundFunc}
          />
        )}
      </Spring>
    );
  }
}

const DrawableAvatar: React.SFC<
  { avatar: number } & Partial<Konva.ImageConfig>
> = props => {
  const [image] = useImage(FriendsAvatarCollection[props.avatar]);

  return <Avatar {...props} avatar={image} />;
};

export default DrawableAvatar;
