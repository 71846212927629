import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Assets } from "../../../../../shared/assets";
import { ResponsiveScreen, ScreenProps } from "../../../Screen";
import { FriendsButton } from "../../../theme";

interface Props extends ScreenProps {
  submit(): void;
}

const Image = styled.div`
  flex: 1;

  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: -100%;
  margin-bottom: -3rem;

  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: url(${Assets.Illustrationer.redHands});

  @media screen and (min-width: 500px) {
    display: none;
  }
`;

export const CreateUserIntro: React.FC<Props> = props => {
  const Footer = (
    <FriendsButton
      fullWidth
      color="primary"
      variant="contained"
      onClick={props.submit}
    >
      Fortsätt
    </FriendsButton>
  );

  return (
    <ResponsiveScreen fixedHeight logotype="yellow" footer={Footer}>
      <Image />

      <Typography
        className="typography-bold"
        gutterBottom
        variant="h2"
        color="secondary"
      >
        Hej!
      </Typography>

      <Typography paragraph>
        Snart börjar första uppdraget. Men först kommer du att få fylla i lite
        uppgifter om dig själv. Det är bara du och läraren som ser dina val.
      </Typography>
    </ResponsiveScreen>
  );
};
