import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  pointer-events: none;
  user-select: none;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;

  &:before {
    content: "";

    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    height: 8px;
    background: #f7b233;
  }
`;

const Indicator = styled.div`
  display: flex;
  align-items: center;

  padding: 6px 24px 8px 24px;
  border-bottom-left-radius: calc(-10% + 50px);
  border-bottom-right-radius: calc(-10% + 50px);

  background-color: #f7b233;

  color: #494949;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.0185rem;

  svg {
    margin-right: 0.2em;
    transform: translateY(0.02em);
  }
`;

export const TestIndicator: React.FC = props => {
  return (
    <Container>
      <Indicator>
        <InfoIcon /> Testläge
      </Indicator>
    </Container>
  );
};
