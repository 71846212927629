import { SkalFrågor, Slidetyp } from "friends-shared";
import React, { Component } from "react";
import { FriendsColors } from "../../../../../../shared/theme";
import { withScreen } from "../../../../Screen";
import { Aktivitet } from "./slides/Aktivitet";
import { Instruktioner } from "./slides/Introduktion";
import { Resultat } from "./slides/Resultat";

interface Props {
  slide: SkalFrågor.Slide;
}

@withScreen({
  width: "large",
  spacing: "medium",
  headerLogo: "yellow",
  introColors: {
    primary: {
      main: FriendsColors.yellow
    },
    secondary: {
      main: FriendsColors.yellow
    },
    background: {
      default: FriendsColors.purple
    },
    text: {
      primary: FriendsColors.lightbeige
    }
  },
  colors: {
    primary: {
      main: FriendsColors.lime
    },
    secondary: {
      main: FriendsColors.purple
    },
    background: {
      default: FriendsColors.lightgrey
    },
    text: {
      primary: FriendsColors.purple
    }
  }
})
export default class extends Component<Props> {
  render() {
    const { slide } = this.props;

    switch (slide.typ) {
      case Slidetyp.Instruktioner:
        return <Instruktioner slide={slide} />;

      case Slidetyp.Aktivitet:
        return <Aktivitet slide={slide} />;

      case Slidetyp.Resultat:
        return <Resultat slide={slide} />;

      default:
        return null;
    }
  }
}
