import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import Heart from "../../../../shared/components/Heart";
import { withScreen } from "../../Screen";
import { FriendsColors } from "../../theme";

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

@withScreen({
  headerLogo: "yellow",
  spacing: "medium",
  colors: {
    primary: {
      main: FriendsColors.yellow
    },
    secondary: {
      main: FriendsColors.yellow
    },
    background: {
      default: FriendsColors.red
    },
    text: {
      primary: FriendsColors.yellow,
      secondary: FriendsColors.lightbeige
    }
  }
})
class Setup extends React.Component {
  render() {
    return (
      <Container>
        <Heart />

        <Typography variant="h5" color="primary">
          Den här vyn visas för alla på stor skärm
        </Typography>
      </Container>
    );
  }
}

export default Setup;
