import { Grid, Typography } from "@material-ui/core";
import { SkalFrågor, Slidetyp } from "friends-shared";
import React, { useContext } from "react";
import styled from "styled-components";
import { FriendsContext } from "../../../../../context";
import { useLatestSlide } from "../../../../../hooks";
import { DiscussionBox, DiscussionText } from "../../../../lobby/Discussion";
import { Dashboard } from "../../common/Dashboard";
import { QuestionsOverview } from "./components/QuestionsOverview";
import SaveRadarChart from "./components/SaveRadarChart";

const Container = styled.div`
  max-width: 875px;
  margin: 0 auto;
`;

interface Props {
  slide: SkalFrågor.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  const state = useContext(FriendsContext);

  const aktivitet = useLatestSlide<SkalFrågor.Slide.Aktivitet>(
    Slidetyp.Aktivitet
  );

  if (!aktivitet) {
    return null;
  }

  return (
    <Dashboard
      steg={props.slide}
      subtitle={props.slide.descriptions.teacher.value}
    >
      <Grid item xs={12}>
        <Container>
          <Grid container spacing={32}>
            <Grid item xs={6}>
              <QuestionsOverview steg={aktivitet} />
            </Grid>

            <Grid item xs={6}>
              <Typography
                className="typography-bold"
                color="primary"
                variant="h5"
                gutterBottom
              >
                Diskutera resultatet
              </Typography>

              <DiscussionText />
              <DiscussionBox />
            </Grid>

            <Grid item xs={6}>
              <SaveRadarChart
                slide={aktivitet}
                answers={state.workshop.exerciseAnswers}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Dashboard>
  );
};
