export const getBackendURL = () => {
  if (process.env.NODE_ENV === "development") {
    const port = process.env.REACT_APP_DEVELOPMENT_SOCKET_PORT;
    const { protocol, hostname } = window.location;

    return `${protocol}//${hostname}:${port}/api`;
  } else {
    return `${window.location.origin}/api`;
  }
};
