import { Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import FriendsHeart from "../../../../../../shared/components/Heart";
import { FriendsContext } from "../../../../context";
import { useCurrentQuestion } from "../../../../hooks";
import { FriendsButton } from "../../../../theme";

interface Props {
  component: JSX.Element;
  idleText: string;
  idleHeader: string;
}

export const AktivitetScreen: React.SFC<Props> = props => {
  const context = useContext(FriendsContext);
  const question = useCurrentQuestion();

  if (question) {
    return props.component;
  }

  // Ifall alla frågor på en slide är besvarade
  // så visar vi en "vänta"-skärm!
  return (
    <Grid container direction="column">
      <Grid item>
        <FriendsHeart />
      </Grid>

      <Grid item>
        <Typography
          className="typography-bold"
          variant="h1"
          align="center"
          gutterBottom
          color="textPrimary"
        >
          {props.idleHeader}
        </Typography>

        <Typography variant="h6" align="center" color="textPrimary">
          {props.idleText}
        </Typography>
      </Grid>

      <Grid item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem"
          }}
        >
          <FriendsButton
            variant="outlined"
            color="primary"
            onClick={() => context.actions.questions.previous()}
          >
            Ändra svar
          </FriendsButton>
        </div>
      </Grid>
    </Grid>
  );
};
