import React from "react";
import { StateConsumer } from "../../context";
import WorkshopSetupScreen from "./Setup";

class Setup extends React.Component<{}> {
  render() {
    return (
      <StateConsumer>
        {state => (
          <WorkshopSetupScreen
            onComplete={() => state.actions.completeSetup()}
          />
        )}
      </StateConsumer>
    );
  }
}

export default Setup;
