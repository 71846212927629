import { CreateWorkshopData, Student } from "friends-shared";
import { Socket } from "../../shared/socket";

class TeacherSocket extends Socket {
  async createWorkshop(metadata: CreateWorkshopData) {
    return await this.emit("create workshop", metadata);
  }

  async joinWorkshop(id: string) {
    return await this.emit("join workshop as teacher", id);
  }

  async completeSetup() {
    return await this.emit("complete setup");
  }

  async startWorkshop() {
    return await this.emit("start workshop");
  }

  async advanceWorkshop() {
    return await this.emit("advance workshop");
  }

  async retractWorkshop() {
    return await this.emit("retract workshop");
  }

  async kickStudent(student: Student) {
    return await this.emit("kick student", student);
  }

  async deleteMessage(messageId: number) {
    return await this.emit("delete message", messageId);
  }

  async deleteAnswerComment(answerId: number) {
    return await this.emit("delete answer comment", answerId);
  }

  async submitActivity(activity: { title: string; description: string }) {
    return await this.emit("submit activity", activity);
  }

  async deleteActivity(id: number) {
    return await this.emit("delete activity", id);
  }

  async requestWorkshopResults() {
    return await this.emit("request workshop results");
  }
}

const socket = new TeacherSocket();

export { socket };

