import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";
import { FriendsFonts } from "../../clients/Teacher/theme";

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

const MessagePreview = styled.div`
  background: rgba(0, 0, 0, 0.05);
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 24px;

  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;

  color: rgba(0, 0, 0, 0.87);
  font-family: ${FriendsFonts.Arial};
  font-weight: 400;
  line-height: 1.5;
`;

interface Props {
  content?: string | undefined;
  onCancel: () => void;
  onConfirmDeletion: () => void;
}

export const ConfirmDeletion: FC<Props> = props => {
  return (
    <Dialog open={!!props.content} onBackdropClick={props.onCancel}>
      <DialogContent>
        <Typography gutterBottom align="center">
          Är du säker på att du vill ta bort följande inlägg?
        </Typography>

        <MessagePreview>{props.content}</MessagePreview>

        <Actions>
          <Button
            variant="contained"
            color="secondary"
            onClick={props.onConfirmDeletion}
          >
            Ja, ta bort inlägget
          </Button>
          <Button onClick={props.onCancel}>Avbryt</Button>
        </Actions>
      </DialogContent>
    </Dialog>
  );
};
