import { Grid, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { Assets } from "../../../shared/assets";
import FriendsLogotype from "../../../shared/components/Logotype";
// } from "../theme";
import { withScreen } from "../../Student/Screen";
import { ClientState, StateConsumer } from "../context";
import { FriendsButton, FriendsColors, FriendsInput } from "../theme";

interface State {
  code: string;
  error: string | null;
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogotypeContainer = styled.div`
  height: 3rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
`;

const Image = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 35vw;
  background-repeat: no-repeat;
  background-position: bottom left;
`;

@withScreen({
  buttons: [],
  colors: {
    primary: FriendsColors.lightbeige,
    secondary: FriendsColors.purple,
    background: FriendsColors.red,
    textPrimary: FriendsColors.lightbeige
  },
  headerLogo: null
})
class ConnectToWorkshop extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      code: "",
      error: null
    };
  }

  render() {
    return (
      <StateConsumer>
        {state => (
          <>
            <Image
              style={{
                backgroundImage: `url(${Assets.Illustrationer.doubleHands})`
              }}
            />

            <Container>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                spacing={16}
              >
                <Grid item>
                  <LogotypeContainer>
                    <FriendsLogotype color="lightyellow" />
                  </LogotypeContainer>
                </Grid>

                <Grid item>
                  <Typography
                    className="typography-bold"
                    color="textPrimary"
                    variant="h1"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    Uppdrag Trygghet
                  </Typography>
                </Grid>

                <Grid item style={{ maxWidth: "20rem", marginTop: "2rem" }}>
                  <FriendsInput
                    color="primary"
                    fullWidth
                    placeholder="Skriv koden här"
                    // label={this.state.error || "Ange koden här"}
                    value={this.state.code}
                    error={!!this.state.error}
                    onChange={event => this.handleCodeChange(event)}
                  />
                </Grid>

                <Grid item style={{ maxWidth: "20rem" }}>
                  <FriendsButton
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => this.joinWorkshop(state)}
                  >
                    Nästa
                  </FriendsButton>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </StateConsumer>
    );
  }

  handleCodeChange(event: ChangeEvent<any>) {
    this.setState({
      code: event.target.value,
      error: null
    });
  }

  async joinWorkshop(state: ClientState) {
    try {
      await state.actions.joinWorkshop(this.state.code);
    } catch {
      this.setState({ error: "Ogiltig kod" });
    }
  }
}

export default ConnectToWorkshop;
