import { Typography } from "@material-ui/core";
import { AbstractSlide } from "friends-shared";
import * as React from "react";
import styled from "styled-components";
import { FriendsContext } from "../../../../context";

const Container = styled.div`
  max-width: 160vh;
  padding: 0 4rem;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80%;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  background-image: ${(props: { url: string }) => `url(${props.url})`};
  background-repeat: no-repeat;
`;

const ImageBottom = styled(Image)`
  background-position: bottom;

  position: absolute;
  left: 0;
  right: 40%;
  bottom: -3vmin;
  height: 30vmin;
`;

const ImageRight = styled(Image)`
  background-position: center center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 10%;
  width: 35vmin;
`;

const Text = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 45vmin;
  max-width: 50rem;
  bottom: 50%;
`;

interface Props {
  steg: AbstractSlide;
  images: {
    right: string;
    bottom: string;
  };
}

const Instruktioner: React.FC<Props> = props => {
  const { workshop } = React.useContext(FriendsContext);
  const exercise = workshop.exercise.data;

  if (!exercise) {
    return null;
  }

  const title = exercise.name;
  const index = workshop.exercise.currentExerciseIndex + 1;

  return (
    <Container>
      <Content>
        <Text>
          <Typography
            gutterBottom
            className="typography-bold"
            variant="h3"
            color="primary"
          >
            Uppdrag {index}
          </Typography>

          <Typography
            className="typography-bold"
            variant="h2"
            gutterBottom
            color="primary"
          >
            {title}
          </Typography>

          <Typography paragraph>
            {props.steg.descriptions.projector.value}
          </Typography>
        </Text>

        <ImageBottom url={props.images.bottom} />
        <ImageRight url={props.images.right} />
      </Content>
    </Container>
  );
};

export default Instruktioner;
