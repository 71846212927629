import { TryggaOchOtryggaPlatser, Övningstyp } from "friends-shared";
import React from "react";
import { Assets } from "../../../../../../../shared/assets";
import { withScreen } from "../../../../../Screen";
import { FriendsColors } from "../../../../../theme";
import Header from "../../common/Header";

@withScreen({
  buttons: [],
  colors: {
    primary: FriendsColors.purple,
    secondary: FriendsColors.purple,
    background: FriendsColors.lightbeige,
    textPrimary: FriendsColors.lightbeige,
    textSecondary: FriendsColors.white
  },
  headerLogo: "yellow"
})
class CustomHeader extends Header {}

interface Props {
  slide: TryggaOchOtryggaPlatser.Slide.Instruktioner;
}

export const Instruktioner: React.SFC<Props> = props => {
  return (
    <CustomHeader
      steg={props.slide}
      image={Assets.Uppdrag[Övningstyp.TryggaOchOtryggaPlatser].cover}
    />
  );
};
