import { withTheme, WithTheme } from "@material-ui/core";
import Konva from "konva";
import React from "react";
import { Text } from "react-konva";
import { animated, Spring } from "react-spring/konva";

interface Props extends WithTheme {
  grow: "left" | "right";
  active?: boolean;
  text: string;
  color: "primary" | "secondary";
  actualColor?: "primary" | "secondary";
  position: Konva.Vector2d;
}

class DrawableTextCircle extends React.Component<Props> {
  private strokeWidth = 4;
  private containerRadius = 30;
  private padding = 15;
  private fontSize = 30;

  private get textHeight() {
    return this.refs.placeholder
      ? (this.refs.placeholder as any).textHeight
      : 0;
  }

  private get textWidth() {
    return this.refs.placeholder ? (this.refs.placeholder as any).textWidth : 0;
  }

  private get textProps() {
    let offset = this.textWidth / 2;

    if (this.props.text.length > 1) {
      if (this.props.grow === "left") {
        offset = this.textWidth - this.padding;
      } else {
        offset = this.props.position.x + this.padding;
      }
    }

    return {
      ref: "text" as any,
      x: this.props.position.x,
      y: this.props.position.y,
      offsetX: offset,
      offsetY: this.textHeight / 2 - this.textHeight * 0.05,
      text: this.props.text,
      fontFamily: "Capitaine",
      fontStyle: "600",
      fill: this.props.theme.palette.primary.main,
      fontSize: this.fontSize
    };
  }

  private renderTextPlaceholder() {
    return (
      <Text
        ref="placeholder"
        x={this.props.position.x}
        y={this.props.position.y}
        offsetX={this.textWidth / 2}
        offsetY={this.textHeight / 2}
        text={this.props.text}
        fontFamily="Capitaine"
        fontStyle="600"
        fill="transparent"
        fontSize={this.fontSize}
      />
    );
  }

  private renderLetterContainer() {
    const sharedComponentProps = {
      x: this.props.position.x + this.textWidth / 2,
      y: this.props.position.y + this.textHeight / 2,
      offsetX: this.textWidth / 2,
      offsetY: this.textHeight / 2,
      radius: this.containerRadius
    };

    const sharedAnimationProps = {
      scaleX: this.props.active ? 1.1 : 1,
      scaleY: this.props.active ? 1.1 : 1
    };

    const color = this.props.actualColor || "primary";

    return (
      <>
        {this.renderTextPlaceholder()}
        {this.refs.placeholder && (
          <>
            <Spring
              native
              config={{ tension: 450, friction: 30 }}
              from={{}}
              to={{
                ...sharedAnimationProps,
                fill: this.props.active
                  ? this.props.theme.palette[color].main
                  : "rgba(0, 0, 0, 0)"
              }}
            >
              {props => (
                <>
                  <animated.Circle
                    {...props}
                    {...sharedComponentProps}
                    fill="black"
                    globalCompositeOperation="destination-out"
                  />

                  <animated.Circle
                    {...props}
                    {...sharedComponentProps}
                    stroke={this.props.theme.palette[color].main}
                    strokeWidth={this.strokeWidth}
                  />
                </>
              )}
            </Spring>

            <Spring
              native
              config={{ tension: 450, friction: 30 }}
              from={{}}
              to={{
                ...sharedAnimationProps,
                fill: this.props.active
                  ? this.props.theme.palette.text[this.props.color]
                  : this.props.theme.palette[color].main
              }}
            >
              {props => <animated.Text {...this.textProps} {...props} />}
            </Spring>
          </>
        )}
      </>
    );
  }

  private renderWordContainer() {
    const height = this.containerRadius * 2;

    const sharedComponentProps = {
      x: this.props.position.x - this.padding,
      y: this.props.position.y + this.textHeight / 2 - height / 2,
      width: this.textWidth + this.padding * 2,
      height: height,
      cornerRadius: 100,
      offsetY: this.textHeight / 2,
      offsetX:
        this.props.grow === "left"
          ? this.textWidth - this.padding
          : this.props.position.x + this.padding
    };

    const sharedAnimationProps = {
      scaleX: this.props.active ? 1.1 : 1,
      scaleY: this.props.active ? 1.1 : 1
    };

    const color = this.props.actualColor || "primary";

    return (
      <>
        {this.renderTextPlaceholder()}
        {this.refs.placeholder && (
          <>
            <Spring
              native
              config={{ tension: 450, friction: 30 }}
              from={{}}
              to={{
                ...sharedAnimationProps,
                fill: this.props.active
                  ? this.props.theme.palette[color].main
                  : "rgba(0, 0, 0, 0)"
              }}
            >
              {props => (
                <>
                  <animated.Rect
                    {...props}
                    {...sharedComponentProps}
                    fill="black"
                    globalCompositeOperation="destination-out"
                  />

                  <animated.Rect
                    {...props}
                    {...sharedComponentProps}
                    stroke={this.props.theme.palette[color].main}
                    strokeWidth={this.strokeWidth}
                  />
                </>
              )}
            </Spring>

            <Spring
              native
              config={{ tension: 450, friction: 30 }}
              from={{}}
              to={{
                ...sharedAnimationProps,
                fill: this.props.active
                  ? this.props.theme.palette.text[this.props.color]
                  : this.props.theme.palette[color].main
              }}
            >
              {props => <animated.Text {...this.textProps} {...props} />}
            </Spring>
          </>
        )}
      </>
    );
  }

  render() {
    if (this.props.text.length > 1) {
      return this.renderWordContainer();
    } else {
      return this.renderLetterContainer();
    }
  }
}

export default withTheme()(DrawableTextCircle);
