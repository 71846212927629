import { Typography } from "@material-ui/core";
import { AbstractSlide } from "friends-shared";
import * as React from "react";
import styled from "styled-components";
import { StateConsumer } from "../../../../context";
import { FriendsButton } from "../../../../theme";

const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Text = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  top: 5rem;
  width: 70%;
  margin: 5rem;
`;

const PhotoBottom = styled.div`
  position: fixed;
  bottom: 0;
  width: 50%;
  right: 10%;
  top: 60%;
  background-position: bottom;
  background-repeat: no-repeat;
`;

const PhotoRight = styled.div`
  position: fixed;
  bottom: 40%;
  width: 50%;
  right: 10%;
  top: 40%;
  background-position: bottom;
  background-repeat: no-repeat;
`;

interface Props {
  steg: AbstractSlide;
  image: string;
  imageRight?: string;
  onStart?(): void;
}

class Introduktion extends React.Component<Props> {
  render() {
    const { steg } = this.props;

    return (
      <StateConsumer>
        {state => {
          const exercise = state.workshop.exercise.data;

          if (exercise) {
            const title = exercise.name;
            const index = state.workshop.exercise.currentExerciseIndex + 1;

            return (
              <Container>
                {this.props.imageRight && (
                  <PhotoRight
                    style={{
                      backgroundImage: `url(${this.props.imageRight})`
                    }}
                  />
                )}

                <Text>
                  <Typography
                    variant="h3"
                    color="primary"
                    style={{ marginBottom: 10 }}
                  >
                    Uppdrag {index}
                  </Typography>

                  <Typography
                    gutterBottom
                    className="typography-bold"
                    variant="h2"
                    color="primary"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {title}
                  </Typography>

                  <Typography paragraph gutterBottom>
                    {steg.descriptions.teacher.value}
                  </Typography>

                  <FriendsButton
                    color="primary"
                    variant="contained"
                    style={{ marginTop: "1rem" }}
                    onClick={() => state.actions.advanceWorkshop()}
                  >
                    Starta uppdrag
                  </FriendsButton>
                </Text>

                <PhotoBottom
                  style={{
                    backgroundImage: `url(${this.props.image})`
                  }}
                />
              </Container>
            );
          }
        }}
      </StateConsumer>
    );
  }
}

export default Introduktion;
