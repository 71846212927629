"use strict";

exports.__esModule = true;
var Övningstyp;

(function (Övningstyp) {
  Övningstyp["TryggKlass"] = "trygg_klass";
  Övningstyp["TryggaOchOtryggaPlatser"] = "trygga_och_utrygga_platser";
  Övningstyp["Skalfr\xE5gor"] = "skal_fragor";
  Övningstyp["FyraH\xF6rn"] = "fyra_horn";
})(Övningstyp = exports.Övningstyp || (exports.Övningstyp = {}));

var Slidetyp;

(function (Slidetyp) {
  Slidetyp["Instruktioner"] = "instructions";
  Slidetyp["Aktivitet"] = "activity";
  Slidetyp["Resultat"] = "results";
})(Slidetyp = exports.Slidetyp || (exports.Slidetyp = {}));