import { Grid, Typography } from "@material-ui/core";
import { FyraHörn, Slidetyp } from "friends-shared";
import React from "react";
import styled from "styled-components";
import { getAsset } from "../../../../../../../../shared/util/clients";
import { useWindowSize } from "../../../../../../../../shared/util/hooks";

interface Props {
  slide: FyraHörn.Slide;
  situation: number;
}

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const VideoAlignment = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  video {
    max-height: 100%;
    max-width: 100%;

    border-radius: 1rem;
    /* box-shadow: 0 1rem 2rem -1.5rem #3a0d3269; */
  }
`;

const Container = styled.div`
  position: absolute;
  padding: 7rem 3rem 0 3rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 70rem;
  margin: 0 auto;
`;

export const Situation: React.SFC<Props> = props => {
  const { width } = useWindowSize();

  const videoUrl =
    props.slide.typ === Slidetyp.Instruktioner
      ? props.slide.videoUrl
      : undefined;

  const isSmallScreenWithVideo = videoUrl && width < 1200;

  return (
    <Container>
      <Grid
        container
        spacing={32}
        direction="column"
        justify="space-between"
        alignContent="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Grid container spacing={isSmallScreenWithVideo ? 16 : 32}>
            <Grid item xs={12}>
              <Typography
                className="typography-bold"
                variant={isSmallScreenWithVideo ? "h4" : "h3"}
                color="primary"
                align="center"
              >
                Situation {props.situation}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                paragraph
                color="secondary"
                align="center"
                style={{
                  fontSize: isSmallScreenWithVideo ? "1.5rem" : undefined
                }}
              >
                {props.slide.descriptions.projector.value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {videoUrl && (
          <Grid item style={{ flexGrow: 1 }}>
            <VideoContainer>
              <VideoAlignment>
                <video
                  src={getAsset(videoUrl)}
                  muted
                  autoPlay
                  loop
                  controls={false}
                ></video>
              </VideoAlignment>
            </VideoContainer>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
