import { AbstractSlide, Slidetyp, Övningstyp } from "friends-shared";
import { useContext } from "react";
import { FriendsContext } from "./context";

export const useCurrentSituationIndex = () => {
  const { workshop } = useContext(FriendsContext);

  if (!workshop.exercise.data) {
    return null;
  }

  const slides = workshop.exercise.data.slides as AbstractSlide[];
  const completedResultatSlides = slides.filter((slide, index) => {
    const isAnyPreviousSlide = index < workshop.exercise.currentSlideIndex;
    const isResultatSlide = slide.typ === Slidetyp.Resultat;

    return isAnyPreviousSlide && isResultatSlide;
  });

  return completedResultatSlides.length + 1;
};

export const useCurrentSlideIndex = () => {
  const { workshop } = useContext(FriendsContext);

  return workshop.exercise.currentSlideIndex;
};

export const useLatestSlide = <T extends AbstractSlide>(
  slideType: Slidetyp
): T | null => {
  const { workshop } = useContext(FriendsContext);
  const data = workshop.exercise.data;

  if (!data) {
    return null;
  }

  for (let i = workshop.exercise.currentSlideIndex; i > 0; i--) {
    const slide = data.slides[i];

    if (slide.typ === slideType) {
      return slide as T;
    }
  }

  return null;
};

export const useIsSlidetyp = (typ: Slidetyp) => {
  const { workshop } = useContext(FriendsContext);
  const data = workshop.exercise.data;

  if (!data) {
    return false;
  }

  const index = workshop.exercise.currentSlideIndex;
  const slide = data.slides[index];

  return slide.typ === typ;
};

export const useIsÖvningstyp = (typ: Övningstyp) => {
  const { workshop } = useContext(FriendsContext);
  const data = workshop.exercise.data;

  if (!data) {
    return false;
  }

  return data.type === typ;
};

export const useIsLastSlide = () => {
  const { workshop } = useContext(FriendsContext);

  if (!workshop.exercise.data) {
    return false;
  }

  const isLastExercise =
    workshop.exercise.currentExerciseIndex ===
    workshop.exercise.totalExercises - 1;
  const isLastSlide =
    workshop.exercise.currentSlideIndex ===
    workshop.exercise.data.slides.length - 1;

  return isLastExercise && isLastSlide;
};
