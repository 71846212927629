import { Fab, Typography, withTheme, WithTheme } from "@material-ui/core";
import React from "react";
import { CircleSlider as CircularSlider } from "react-circle-slider";
import styled from "styled-components";

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 180px;
    height: 180px;

    background: none !important;
    box-shadow: none !important;
  }
`;

const Container = styled.div`
  width: 180px;
  height: 180px;

  position: relative;

  h1 {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface Props extends WithTheme {
  min: number;
  max: number;
  value: number;
  onChange(value: number): void;
}

class CircleSlider extends React.Component<Props> {
  private incrementValue = () => {
    const nextValue =
      this.props.value >= this.props.max
        ? this.props.min
        : this.props.value + 1;

    this.props.onChange(nextValue);
  };

  render() {
    return (
      <Overlay>
        <Fab color="secondary" aria-label="Edit" onClick={this.incrementValue}>
          <Container>
            <CircularSlider
              value={(99.75 / this.props.max) * this.props.value}
              circleColor="transparent"
              progressColor={this.props.theme.palette.secondary.main}
              knobRadius={0}
            />

            <Typography
              className="typography-bold"
              variant="h1"
              color="secondary"
            >
              {`${this.props.value}`}
            </Typography>
          </Container>
        </Fab>
      </Overlay>
    );
  }
}

export default withTheme()(CircleSlider);
