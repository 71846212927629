import { Grid, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { Assets } from "../../../shared/assets";
import FriendsLogotype from "../../../shared/components/Logotype";
import { ClientState, StateConsumer } from "../context";
import { ScreenProps, withScreen } from "../Screen";
import { FriendsButton, FriendsColors, FriendsInput } from "../theme";

const LogotypeContainer = styled.div`
  height: 3rem;
  display: flex;
  justify-content: center;
`;

const Image = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 0 -15vw;
  background-size: 100%;
`;

interface State {
  code: string;
  error: string | null;
}

@withScreen({
  buttons: [],
  colors: {
    primary: FriendsColors.lightbeige,
    secondary: FriendsColors.purple,
    background: FriendsColors.red,
    textPrimary: FriendsColors.lightbeige
  },
  headerLogo: null,
  preventVerticalOverflow: true
})
class ConnectToWorkshop extends React.Component<ScreenProps, State> {
  constructor(props: ScreenProps) {
    super(props);

    this.state = {
      code: "",
      error: null
    };
  }

  render() {
    return (
      <StateConsumer>
        {state => (
          <>
            <Grid
              container
              justify="center"
              alignItems="stretch"
              direction="column"
              spacing={16}
            >
              <Grid item>
                <LogotypeContainer>
                  <FriendsLogotype color="lightyellow" />
                </LogotypeContainer>
              </Grid>

              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h4"
                  gutterBottom
                  className="typography-bold"
                  style={{
                    textAlign: "center",
                    fontSize: "9vmin"
                  }}
                >
                  Uppdrag Trygghet
                </Typography>
              </Grid>

              <Grid item>
                <FriendsInput
                  color="primary"
                  fullWidth
                  placeholder="Skriv koden här"
                  // label={this.state.error || "Ange koden här"}
                  value={this.state.code}
                  error={!!this.state.error}
                  onChange={event => this.handleCodeChange(event)}
                />
              </Grid>

              <Grid item>
                <FriendsButton
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() => this.joinWorkshop(state)}
                >
                  Nästa
                </FriendsButton>
              </Grid>
            </Grid>

            <Image
              style={{
                backgroundImage: `url(${Assets.Illustrationer.oppositeHands})`
              }}
            />
          </>
        )}
      </StateConsumer>
    );
  }

  handleCodeChange(event: ChangeEvent<any>) {
    this.setState({
      code: event.target.value,
      error: null
    });
  }

  async joinWorkshop(state: ClientState) {
    try {
      await state.actions.joinWorkshop(this.state.code);
    } catch {
      this.setState({ error: "Ogiltig kod" });
    }
  }
}

export default ConnectToWorkshop;
