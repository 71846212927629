import React from "react";
import Helmet from "react-helmet";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Projector from "./clients/Projector";
import Student from "./clients/Student";
import Teacher from "./clients/Teacher";
import "./index.scss";
import { Client, getClientRoute } from "./shared/util/clients";
import loadable from "@loadable/component";

const Exporter = loadable(() => import("./exporter"));

const App: React.SFC = () => {
  const route = {
    student: getClientRoute(Client.Student),
    teacher: getClientRoute(Client.Teacher),
    projector: getClientRoute(Client.Projector)
  };

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#222" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Helmet>

      <Router>
        <Switch>
          <Route path="/" exact>
            <Redirect to={route.student} />
          </Route>

          <Route path={route.student} component={Student} />
          <Route path={route.teacher} component={Teacher} />
          <Route path={route.projector} component={Projector} />

          <Route exact path="/export" component={Exporter} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
