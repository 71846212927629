import { WithTheme, withTheme } from "@material-ui/core";
import { Answer } from "friends-shared";
import React from "react";
import WordCloud from "react-wordcloud";
import { FriendsColors } from "../theme";
import { useWindowSize } from "../util/hooks";

interface Word {
  text: string;
  value: number;
}

interface Props extends WithTheme {
  answers: Answer[];
  size?: "large" | "small";
  disableTransition?: boolean | undefined;
}

const parseAnswers = (answers: Answer[]): Word[] => {
  const words: Word[] = [];

  const parsedAnswers = answers
    .map(answer => answer.text.split(","))
    .reduce((acc, answers) => acc.concat(answers), []);

  for (const parsedAnswer of parsedAnswers) {
    const match = words.find(word => {
      return word.text === parsedAnswer;
    });

    if (match) {
      match.value++;
    } else {
      words.push({
        text: parsedAnswer,
        value: 1
      });
    }
  }

  return words;
};

interface InternalProps {
  color: string;
  answers: Answer[];
  size: "large" | "small";
  disableTransition?: boolean | undefined;
}

const useSize = (props: InternalProps): [number, number] => {
  const windowSize = useWindowSize();

  if (props.size === "small") {
    return [12, 30];
  }

  if (windowSize.width < 1200) {
    return [20, 60];
  }

  return [20, 80];
};

const InternalCloud = React.memo<InternalProps>(props => {
  const size = useSize(props);
  const padding = props.size === "small" ? 2 : 3;
  const words = parseAnswers(props.answers);

  return (
    <WordCloud
      words={words}
      options={{
        fontFamily: "Capitaine",
        fontSizes: size,
        colors: [
          FriendsColors.lime,
          FriendsColors.purple,
          FriendsColors.red,
          FriendsColors.orange
        ],
        rotationAngles: [0, 0, 0, 0, -90, 90],
        rotations: 6,
        scale: "sqrt",
        spiral: "archimedean",
        fontWeight: "600",
        padding,
        transitionDuration: props.disableTransition ? 0 : 500
      }}
    />
  );
});

const Cloud: React.SFC<Props> = props => {
  if (!props.answers.length) {
    return null;
  }

  // const colors =
  const color = props.theme.palette.secondary.main;
  const size = props.size || "large";

  return (
    <InternalCloud
      color={color}
      size={size}
      answers={props.answers}
      disableTransition={props.disableTransition}
    />
  );
};

export const FriendsWordcloud = React.memo(withTheme()(Cloud));
