export const getQueryParam = (name: string, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const removeQueryParam = (removeParams: string[]) => {
  const deleteRegex = new RegExp(removeParams.join('=|') + '=')
  const params = window.location.search.slice(1).split('&')

  let search = []
  for (let i = 0; i < params.length; i++) if (deleteRegex.test(params[i]) === false) search.push(params[i])

  window.history.replaceState({}, document.title, window.location.pathname + (search.length ? '?' + search.join('&') : '') + window.location.hash)
}