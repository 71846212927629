import { Övningstyp } from "friends-shared";
import IllustrationDoubleHands from "./Common/Friends_illu_doublehands_mobiles.svg";
import IllustrationRedHands from "./Common/Friends_illu_mobilehands_double_red.svg";
import IllustrationOppositeHands from "./Common/Friends_illu_oppositehands_mobiles.svg";
import TryggaOchOtryggaPlatserInstruktion from "./Common/Miljoillustrationer_instruktionsbild-01.svg";
import IkonComment from "./Ikoner/outline-mode_comment-24px.svg";
import IllustrationMobile from "./Illustrationer/Mobilhand_single.svg";
import FyraHörnFörklaring2 from "./Uppdrag/Fyra Hörn/ovningsvyer_forklaring_hands_uppdrag4.svg";
import FyraHörnFörklaring1 from "./Uppdrag/Fyra Hörn/ovningsvyer_forklaring_uppdrag4.svg";
import FyraHörnCover from "./Uppdrag/Fyra Hörn/Tjej_mobil.svg";
import SkalfrågorFörklaring2 from "./Uppdrag/Skalfrågor/ovningsvyer_forklaring_hands_uppdrag3.svg";
import SkalfrågorFörklaring1 from "./Uppdrag/Skalfrågor/ovningsvyer_forklaring_uppdrag3.svg";
import SkalfrågorCover from "./Uppdrag/Skalfrågor/Rastvakt.svg";
import TryggKlassCover from "./Uppdrag/Trygg Klass/Friends_illu_embrace.svg";
import TryggaOchOtryggaPlatserCover from "./Uppdrag/Trygga Och Otrygga Platser/Friends_Illus_girl.svg";
import TryggaOchOtryggaPlatserFörklaring2 from "./Uppdrag/Trygga Och Otrygga Platser/ovningsvyer_forklaring_hands_uppdrag2.svg";
import TryggaOchOtryggaPlatserFörklaring1 from "./Uppdrag/Trygga Och Otrygga Platser/ovningsvyer_forklaring_uppdrag2.svg";

export const Assets = {
  Uppdrag: {
    [Övningstyp.TryggKlass]: {
      cover: TryggKlassCover
    },
    [Övningstyp.TryggaOchOtryggaPlatser]: {
      cover: TryggaOchOtryggaPlatserCover,
      förklaring1: TryggaOchOtryggaPlatserFörklaring1,
      förklaring2: TryggaOchOtryggaPlatserFörklaring2,
      instruktion: TryggaOchOtryggaPlatserInstruktion
    },
    [Övningstyp.Skalfrågor]: {
      cover: SkalfrågorCover,
      förklaring1: SkalfrågorFörklaring1,
      förklaring2: SkalfrågorFörklaring2
    },
    [Övningstyp.FyraHörn]: {
      cover: FyraHörnCover,
      förklaring1: FyraHörnFörklaring1,
      förklaring2: FyraHörnFörklaring2
    }
  },
  Illustrationer: {
    mobile: IllustrationMobile,
    redHands: IllustrationRedHands,
    doubleHands: IllustrationDoubleHands,
    oppositeHands: IllustrationOppositeHands
  },
  Ikoner: {
    comment: IkonComment
  }
};
