import {
  Activity,
  Answer,
  CreateWorkshopData,
  Message,
  Student,
  Workshop,
  WorkshopProgress,
  WorkshopStatus
} from "friends-shared";
import * as React from "react";
import { TestIndicator } from "../../shared/components/TestIndicator";
import SessionUrlHandler, {
  getSessionIdFromURL
} from "../../shared/url-session";
import { Log } from "../../shared/util/logging";
import { socket } from "./socket";

interface ClientActions {
  createWorkshop(metadata: CreateWorkshopData): Promise<void>;
  joinWorkshop(id: string): Promise<void>;
  completeSetup(): Promise<void>;
  startWorkshop(): Promise<void>;
  advanceWorkshop(): Promise<void>;
  retractWorkshop(): Promise<void>;
  leaveWorkshop(): Promise<void>;
  deleteMessage(messageId: number): Promise<void>;
  deleteAnswerComment(answerId: number): Promise<void>;
}

export interface ClientState {
  actions: ClientActions;
  sessionId: string | null;
  workshop: {
    id: number | null;
    test: boolean;
    municipality: string;
    status: WorkshopStatus | null;
    codes: {
      student: string;
      projector: string;
    };
    exercise: WorkshopProgress;
    exerciseActivities: Activity[];
    exerciseAnswers: Answer[];
    slideMessages: Message[];
    students: Student[];
  };
}

export const FriendsContext = React.createContext({} as ClientState);

class StateProvider extends React.Component<{}, ClientState> {
  componentWillMount() {
    const sessionId = getSessionIdFromURL("handledare");

    Log.session("session from url", sessionId);

    this.setState({
      sessionId
    });
  }

  componentDidMount() {
    socket.connect();

    socket
      .on<Workshop>(["joined workshop", "workshop created"])
      .subscribe(workshop => {
        this.setWorkshop(workshop);
      });

    socket.on<Student[]>("connected students change").subscribe(students => {
      this.setWorkshopStudents(students);
    });

    socket.on<WorkshopStatus>("workshop status change").subscribe(status => {
      this.setWorkshopStatus(status);
    });

    socket.on<WorkshopProgress>("slide").subscribe(slide => {
      this.setWorkshopSlide(slide);
    });

    socket.on<Activity[]>("exercise activities").subscribe(activities => {
      this.setWorkshopExerciseActivities(activities);
    });

    socket.on<Answer[]>("exercise answers").subscribe(answers => {
      this.setWorkshopExerciseAnswers(answers);
    });

    socket.on<Message[]>("latest messages").subscribe(messages => {
      this.addWorkshopSlideMessages(messages);
    });

    socket.on<string>("authenticated").subscribe(sessionId => {
      this.setSessionId(sessionId);
    });

    socket.on<void>("authentication failed").subscribe(() => {
      Log.session("no socket found");
      this.setSessionId(null);
      this.leaveWorkshop();
    });

    if (this.state.sessionId) {
      socket.authenticate(this.state.sessionId);
    }

    if (socket.instance) {
      socket.instance.on("reconnect", () => {
        if (this.state.sessionId) {
          socket.authenticate(this.state.sessionId);
        }
      });
    }
  }

  componentWillUnmount() {
    socket.disconnect();
  }

  constructor(props: {}) {
    super(props);

    this.state = {
      sessionId: null,
      workshop: {
        id: null,
        test: false,
        status: null,
        municipality: "",
        codes: {
          student: "",
          projector: ""
        },
        exercise: {
          data: null,
          currentExerciseIndex: 0,
          currentSlideIndex: 0,
          totalExercises: 0,
          totalSlides: 0
        },
        exerciseActivities: [],
        exerciseAnswers: [],
        slideMessages: [],
        students: []
      },
      actions: {
        createWorkshop: this.createWorkshop.bind(this),
        joinWorkshop: this.joinWorkshop.bind(this),
        completeSetup: this.completeSetup.bind(this),
        startWorkshop: this.startWorkshop.bind(this),
        advanceWorkshop: this.advanceWorkshop.bind(this),
        retractWorkshop: this.retractWorkshop.bind(this),
        leaveWorkshop: this.leaveWorkshop.bind(this),
        deleteMessage: this.deleteMessage.bind(this),
        deleteAnswerComment: this.deleteAnswerComment.bind(this)
      }
    };
  }

  render() {
    return (
      <FriendsContext.Provider value={this.state}>
        <SessionUrlHandler
          basename="handledare"
          sessionId={this.state.sessionId}
          sessionIdChange={sessionId => this.setSessionId(sessionId)}
        />
        {this.props.children}

        {this.state.workshop && this.state.workshop.test && <TestIndicator />}
      </FriendsContext.Provider>
    );
  }

  private async createWorkshop(metadata: CreateWorkshopData): Promise<void> {
    await socket.createWorkshop(metadata);
  }

  private async completeSetup(): Promise<void> {
    await socket.completeSetup();
  }

  private async startWorkshop(): Promise<void> {
    await socket.startWorkshop();
  }

  private async deleteMessage(messageId: number): Promise<void> {
    await socket.deleteMessage(messageId);
  }

  private async deleteAnswerComment(answerId: number): Promise<void> {
    await socket.deleteAnswerComment(answerId);
  }

  private async advanceWorkshop(): Promise<void> {
    await socket.advanceWorkshop();
  }

  private async retractWorkshop(): Promise<void> {
    await socket.retractWorkshop();
  }

  private async joinWorkshop(id: string): Promise<void> {
    await socket.joinWorkshop(id);
  }

  private async leaveWorkshop(): Promise<void> {
    await socket.leaveWorkshop();

    this.setSessionId(null);

    this.setState({
      workshop: {
        id: null,
        test: false,
        status: null,
        municipality: "",
        codes: {
          student: "",
          projector: ""
        },
        exercise: {
          data: null,
          currentExerciseIndex: 0,
          currentSlideIndex: 0,
          totalExercises: 0,
          totalSlides: 0
        },
        exerciseActivities: [],
        exerciseAnswers: [],
        slideMessages: [],
        students: []
      }
    });
  }

  private setWorkshop(workshop: Workshop) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        test: workshop.test,
        municipality: workshop.municipality,
        codes: workshop.codes,
        id: workshop.id,
        status: workshop.status
      }
    });
  }

  private setWorkshopStudents(students: Student[]) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        students
      }
    });
  }

  private setWorkshopStatus(status: WorkshopStatus) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        status
      }
    });
  }

  private setWorkshopSlide(exercise: WorkshopProgress) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        exercise,
        slideMessages: []
      }
    });
  }

  private setWorkshopExerciseActivities(activities: Activity[]) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        exerciseActivities: activities
      }
    });
  }

  private setWorkshopExerciseAnswers(answers: Answer[]) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        exerciseAnswers: answers
      }
    });
  }

  private addWorkshopSlideMessages(messages: Message[]) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        slideMessages: messages
      }
    });
  }

  private setSessionId(sessionId: string | null) {
    this.setState({
      sessionId
    });
  }
}

const StateConsumer = FriendsContext.Consumer;

export { StateProvider, StateConsumer };
