import { Typography } from "@material-ui/core";
import { TryggKlass } from "friends-shared";
import React from "react";
import { AktivitetScreen } from "../../common/AktivitetScreen";
import ValbaraOrdInput from "./components/ValbaraOrd";

interface Props {
  slide: TryggKlass.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  return (
    <AktivitetScreen
      idleHeader="Bra!"
      idleText="Din lärare startar strax ert nästa uppdrag."
      component={
        <>
          <Typography
            variant="h6"
            color="primary"
            align="center"
            style={{
              padding: "1rem 0",
              borderTop: ".1rem dotted lightgrey",
              borderBottom: ".1rem dotted lightgrey",
              marginBottom: "1rem"
            }}
          >
            {props.slide.descriptions.student.value}
          </Typography>

          <Typography color="primary" paragraph align="center">
            Välj 1-5 alternativ
          </Typography>

          <ValbaraOrdInput questions={props.slide.questions} />
        </>
      }
    />
  );
};
