import { Typography } from "@material-ui/core";
import { Activity, Exercise } from "friends-shared";
import React from "react";
import styled from "styled-components";
import FriendsLogotype from "../../../../shared/components/Logotype";
import { FriendsColors } from "../../theme";

const A4 = styled.div`
  background-color: white;
  position: relative;
  min-width: 297mm;
  max-height: 209mm;

  width: 100%;
  height: 100%;
`;

const PrintSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
`;

const PrintTitle = styled(PrintSection)`
  top: 5%;
  bottom: 90%;
`;

const PrintActivities = styled(PrintSection)`
  top: 20%;
  bottom: 0%;
  left: 5%;
  right: 5%;

  flex-wrap: wrap;
  align-items: baseline;
  align-content: baseline;
  justify-content: center;
`;

const PrintActivity = styled.div`
  min-width: 7.5cm;
  max-width: 12.5cm;
  padding: 16px;
  flex: 1;
`;

const PrintLogotype = styled(PrintSection)`
  top: 5%;
  left: 5%;
  width: 4%;
  height: 4%;
`;

interface Props {
  exercise: Exercise;
  activities: Activity[];
}

const purpleStyle = {
  color: FriendsColors.purple
};

export default class PrintableActivities extends React.Component<Props> {
  render() {
    return (
      <A4>
        <PrintTitle>
          <Typography
            className="typography-bold"
            variant="h3"
            align="center"
            style={purpleStyle}
          >
            {this.props.exercise.name}
          </Typography>
        </PrintTitle>

        <PrintActivities>
          {this.props.activities.map(activity => (
            <PrintActivity key={activity.id}>
              <Typography
                className="typography-bold"
                gutterBottom
                variant="h6"
                style={{ wordBreak: "break-word", ...purpleStyle }}
              >
                {activity.title}
              </Typography>

              <Typography
                paragraph
                style={{ wordBreak: "break-word", ...purpleStyle }}
              >
                {activity.description}
              </Typography>
            </PrintActivity>
          ))}
        </PrintActivities>

        <PrintLogotype>
          <FriendsLogotype color="yellow" />
        </PrintLogotype>
      </A4>
    );
  }
}
