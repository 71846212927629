import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useRef } from "react";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import { FriendsWordcloud } from "../../../../../../../../shared/components/Wordcloud";
import Print from "../../../../../../common/Printable/PrintableWordcloudAndActivities";
import { FriendsContext } from "../../../../../../context";
import { FriendsButton } from "../../../../../../theme";

const PrintContainer = styled.div`
  position: fixed;
  top: -10000px;
`;

const printStyle = `
  @page {
    size: A4 landscape;
    margin: 0mm;
  }

  @media print {
    html, body {
      height: 100%;
      width: 100%;
      position: relative;
    }

    body {
      -webkit-print-color-adjust: exact;
    }
  }
`;

const SaveWordcloudAndActivites: React.SFC = () => {
  const state = useContext(FriendsContext);
  const componentRef = useRef<Print>(null);

  return (
    <Grid container spacing={32}>
      <Grid item>
        <Typography
          className="typography-bold"
          color="primary"
          variant="h5"
          gutterBottom
        >
          3. Spara ordmoln och aktiviteter
        </Typography>

        <Typography paragraph>
          Skriv gärna ut ordmoln och aktiviteter så att alla i klassen kan ta
          del av det. På så sätt kan ni senare fördjupa och följa upp ert
          arbete.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FriendsWordcloud
          size="small"
          answers={state.workshop.exerciseAnswers}
        />
      </Grid>

      <Grid item xs={12}>
        {
          <ReactToPrint
            pageStyle={printStyle}
            trigger={() => (
              <FriendsButton
                style={{ float: "right" }}
                variant="contained"
                color="primary"
              >
                Spara aktiviteter + ordmoln
              </FriendsButton>
            )}
            content={() => componentRef.current as any}
          />
        }

        <PrintContainer>
          <Print
            answers={state.workshop.exerciseAnswers}
            activities={state.workshop.exerciseActivities}
            ref={componentRef}
          />
        </PrintContainer>
      </Grid>
    </Grid>
  );
};

export default SaveWordcloudAndActivites;
