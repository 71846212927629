import { Grid, Typography } from "@material-ui/core";
import { Student } from "friends-shared";
import React from "react";
import styled from "styled-components";
import {
  FriendsAvatar,
  FriendsAvatarCollection
} from "../../../../shared/components/Avatar";
import { StateConsumer } from "../../context";
import { withScreen } from "../../Screen";
import { FriendsColors } from "../../theme";

const AvatarsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AvatarCell = styled.div`
  width: ${(props: { width: number }) => props.width + "%"};
  padding-bottom: ${(props: { width: number }) => props.width + "%"};
  box-sizing: border-box;

  position: relative;

  > * {
    transform: scale(0.9);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const EmptyCircle = styled.div`
  border: 2px solid ${props => props.color};
  border-radius: 50%;
`;

@withScreen({
  headerLogo: "yellow",
  spacing: "medium",
  colors: {
    primary: {
      main: FriendsColors.yellow
    },
    secondary: {
      main: FriendsColors.yellow
    },
    background: {
      default: FriendsColors.red
    },
    text: {
      primary: FriendsColors.yellow,
      secondary: FriendsColors.lightbeige
    }
  }
})
class Lobby extends React.Component {
  private renderAvatars(students: Student[]) {
    const columns = 9;
    const rows = 6;

    const avatars = [];

    for (let cell = 0; cell < columns * rows; cell++) {
      avatars.push(students[cell] && +students[cell].avatar);
    }

    return avatars.map((avatar, index) => (
      <AvatarCell key={index} width={100 / columns}>
        {FriendsAvatarCollection[avatar] ? (
          <FriendsAvatar index={"" + avatar}>{""}</FriendsAvatar>
        ) : (
          <EmptyCircle color="#fad501" />
        )}
      </AvatarCell>
    ));
  }

  render() {
    const studentLink = window.location.origin;

    return (
      <StateConsumer>
        {state => (
          <Grid container wrap="nowrap" spacing={32}>
            <Grid item>
              <Grid container direction="column" spacing={32}>
                <Grid item>
                  <Typography
                    className="typography-bold"
                    variant="h1"
                    gutterBottom
                    color="textPrimary"
                  >
                    Välkomna!
                  </Typography>

                  <Typography gutterBottom paragraph color="textSecondary">
                    Idag ska ni tillsammans göra några uppdrag om trygghet i
                    klassen. Använd din mobil eller surfplatta för att delta.
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h3" gutterBottom color="textPrimary">
                    Logga in på:
                  </Typography>
                  <Typography variant="h4" gutterBottom color="textSecondary">
                    {studentLink}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h3" gutterBottom color="textPrimary">
                    Ange koden:
                  </Typography>
                  <Typography variant="h4" gutterBottom color="textSecondary">
                    {state.workshop.studentCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ width: "100%" }}>
              <Typography
                className="typography-bold"
                variant="h3"
                gutterBottom
                color="textPrimary"
              >
                Deltagare {state.workshop.students.length} st
              </Typography>

              <AvatarsContainer>
                {this.renderAvatars(state.workshop.students)}
              </AvatarsContainer>
            </Grid>
          </Grid>
        )}
      </StateConsumer>
    );
  }
}

export default Lobby;
