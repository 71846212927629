import { Button, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  createMuiTheme,
  createStyles,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

export const FriendsFonts = {
  Arial: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
  Capitaine: "Capitaine"
};

export const FriendsColors = {
  lightbeige: "#fff29c",
  lightgrey: "#f8f8f8",
  purple: "#941d80",
  red: "#d60e51",
  lime: "#009c8f",
  blue: "#38a8e0",
  white: "#fff",
  yellow: "#fad501"
};

const sliderStyling: any = {
  MuiSlider: {
    thumb: {
      width: 36,
      height: 36
    },
    track: {
      height: 5
    }
  }
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: FriendsColors.purple
    },
    secondary: {
      main: FriendsColors.purple
    },
    text: {
      primary: "#404040"
    }
  },
  typography: {
    fontFamily: FriendsFonts.Capitaine,
    useNextVariants: true,
    fontSize: 18
  },
  overrides: {
    ...sliderStyling,

    MuiButton: {
      label: {
        fontSize: "1rem",
        fontWeight: 500
      }
    },

    MuiChip: {
      root: {
        fontSize: "1rem"
      }
    },

    MuiTypography: {
      h1: {
        fontSize: 50
      },
      h2: {
        fontSize: 40
      },
      h3: {
        fontSize: 34
      },
      h4: {
        fontSize: 32
      },
      h5: {
        fontSize: 26
      },
      h6: {
        fontSize: 22,
        lineHeight: 1.2
      },
      paragraph: {
        fontFamily: FriendsFonts.Arial
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 14
      }
    },
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: "1.75rem"
        }
      }
    }
  }
});

export const updateTheme = (override: ThemeOptions): Theme => {
  return createMuiTheme({
    ...theme,
    ...override
  });
};

export const FriendsInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      fontFamily: FriendsFonts.Arial
    },
    error: {},
    disabled: {},
    focused: {},
    underline: {
      "&:before": {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        opacity: 0.75
      },
      "&:after": {
        borderBottom: `2px solid ${theme.palette.primary.main}`
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: `2px solid ${theme.palette.primary.light}`,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          borderBottom: `1px solid ${theme.palette.primary.main}`
        }
      }
    }
  })
)(Input);

export const FriendsButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: ".4rem 2.3rem"
    },
    label: {
      textTransform: "none"
    },
    contained: {
      boxShadow: "none",
      color: theme.palette.background.default,
      // "&:hover": {
      //   background: "rgb(207 197 139)"
      // }
      transition: theme.transitions.create(["filter"], { duration: 180 }),

      "&:hover, &:active": {
        filter: "saturate(0.85) brightness(0.825)",
        boxShadow: "none"
      }
    },
    containedPrimary: {
      "&:hover": {
        background: theme.palette.primary.main
      }
    },
    containedSecondary: {
      "&:hover": {
        background: theme.palette.secondary.main
      }
    },
    text: {
      borderRadius: 3,
      border: 0
    }
  })
)(Button);

export const FriendsSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      fontFamily: FriendsFonts.Arial
    },
    select: {
      padding: ".75rem 1rem",
      color: theme.palette.primary.main
    },
    icon: {
      color: theme.palette.primary.main,
      marginRight: ".5rem"
    }
  })
)(Select);

export const FriendsInputLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.7)"
    }
  })
)(InputLabel);

export const FriendsMenuItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: ".75rem",
      color: theme.palette.primary.main,
      fontSize: 16,
      fontFamily: FriendsFonts.Arial
    }
  })
)(MenuItem);
