import { FyraHörn, Övningstyp } from "friends-shared";
import React from "react";
import { Assets } from "../../../../../../../shared/assets";
import { withScreen } from "../../../../../Screen";
import { FriendsColors } from "../../../../../theme";
import Header from "../../common/Header";

@withScreen({
  buttons: [],
  colors: {
    primary: FriendsColors.lightbeige,
    secondary: FriendsColors.lightbeige,
    background: FriendsColors.red,
    textPrimary: FriendsColors.lightbeige
  },
  headerLogo: "yellow"
})
class CustomHeader extends Header {}

interface Props {
  slide: FyraHörn.Slide.Instruktioner;
}

export const Instruktioner: React.SFC<Props> = props => {
  return (
    <CustomHeader
      steg={props.slide}
      image={Assets.Uppdrag[Övningstyp.FyraHörn].cover}
    />
  );
};
