import {
  Grid,
  MuiThemeProvider,
  Typography,
  WithTheme,
  withTheme
} from "@material-ui/core";
import { Slider } from "@material-ui/lab";
import * as React from "react";
import { animated, Transition } from "react-spring";
import styled from "styled-components";
import { getAsset } from "../../../../../../../../shared/util/clients";
import {
  FriendsButton,
  FriendsColors,
  updateTheme
} from "../../../../../../theme";
import { FriendsFormProps, withFriendsForm } from "../../../common/Form";

interface Question {
  min: number;
  max: number;
  default: number;
  location: {
    name: string;
    imageUrl: string;
  };
}

const Photo = styled.div`
  padding-top: 30vh;

  margin-left: -2rem;
  margin-right: -2rem;

  margin-top: 1rem;
  margin-bottom: 1.5rem;

  background-image: ${(props: { url: string }) => `url(${props.url})`};
  background-size: cover;
  background-position: center center;
`;

const ranges = [
  {
    name: "Trygg",
    color: FriendsColors.lime,
    start: 0,
    end: 0
  },
  {
    name: "Lite otrygg",
    color: FriendsColors.red,
    start: 0.1,
    end: 0.3
  },
  {
    name: "Otrygg",
    color: FriendsColors.red,
    start: 0.4,
    end: 0.6
  },
  {
    name: "Mycket otrygg",
    color: FriendsColors.red,
    start: 0.7,
    end: 1
  }
];

const StatusContainer = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;
`;

const rangeComponents = ranges.map(range => {
  return (style: React.CSSProperties) => (
    <animated.div
      style={{
        ...style,
        position: "absolute",
        left: 0,
        right: 0
      }}
    >
      <Typography
        className="typography-bold"
        variant="h5"
        align="center"
        style={{ color: range.color }}
      >
        {range.name}
      </Typography>
    </animated.div>
  );
});

interface Props extends WithTheme, FriendsFormProps<Question> {}

interface State {
  lastAction: "decrease" | "increase" | null;
}

class PlatserInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      lastAction: null
    };
  }

  private get currentRange() {
    const value = Number(this.props.answer) - this.props.question.min;

    const result = ranges.find(range => {
      const normalizedListLength =
        this.props.question.max - this.props.question.min;

      const isMoreThanStart =
        value >= +(range.start * normalizedListLength).toFixed(0);

      const isLessThanEnd =
        value <= +(range.end * normalizedListLength).toFixed(0);

      return isMoreThanStart && isLessThanEnd;
    });

    if (result) {
      return result;
    }

    return {
      name: "?",
      start: NaN,
      end: NaN,
      color: "#000"
    };
  }

  private get theme() {
    return updateTheme({
      palette: {
        primary: {
          main: this.currentRange.color
        }
      }
    });
  }

  componentWillMount() {
    this.props.setDefaultValue("1");
  }

  private handleSliderValueChange(value: number) {
    const lastAction = value > +this.props.answer ? "increase" : "decrease";

    this.setState({
      lastAction
    });

    this.props.setAnswer(`${value}`);
  }

  componentDidUpdate(prev: Props) {
    let action: State["lastAction"] = null;

    if (this.props.answer > prev.answer) {
      action = "increase";
    }

    if (this.props.answer < prev.answer) {
      action = "decrease";
    }

    if (action) {
      if (this.state.lastAction !== action) {
        this.setState({ lastAction: action });
      }
    }
  }

  render() {
    if (!this.props.question) {
      return null;
    }

    let from = "translate3d(0, 20%, 0)";

    if (this.state.lastAction) {
      from =
        this.state.lastAction === "increase"
          ? "translate3d(-100%,0,0)"
          : "translate3d(100%,0,0)";
    }

    const leave =
      this.state.lastAction === "increase"
        ? "translate3d(80%,0,0)"
        : "translate3d(-80%,0,0)";

    return (
      <>
        <Typography variant="h6" color="primary" align="center">
          Plats {this.props.questionIndex + 1} av {this.props.totalQuestions}
        </Typography>

        <Typography
          className="typography-bold"
          variant="h5"
          color="primary"
          align="center"
        >
          {this.props.question.location.name}
        </Typography>

        <Photo url={getAsset(this.props.question.location.imageUrl)} />

        <StatusContainer>
          <Transition
            native
            reset
            unique
            items={ranges.indexOf(this.currentRange)}
            enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
            from={{
              opacity: 0,
              transform: from
            }}
            leave={{
              opacity: 0,
              transform: leave
            }}
          >
            {(rangeIndex: number) => rangeComponents[rangeIndex]}
          </Transition>
        </StatusContainer>

        <MuiThemeProvider theme={this.theme}>
          <Slider
            step={1}
            min={this.props.question.min}
            max={this.props.question.max}
            value={+this.props.answer}
            onChange={(_, value) => this.handleSliderValueChange(value)}
            style={{
              padding: "1rem 1rem 3rem 1rem",
              boxSizing: "border-box",
              width: "100%",
              margin: "0 auto"
            }}
          />
        </MuiThemeProvider>

        <Grid container spacing={16} direction="column">
          <Grid item>
            <FriendsButton
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => this.props.setView("comment")}
            >
              Kommentera
            </FriendsButton>
          </Grid>

          <Grid item>
            <Grid container spacing={8}>
              <Grid item xs={5}>
                <FriendsButton
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  onClick={() => this.props.previous()}
                  disabled={this.props.isFirstQuestion}
                >
                  Backa
                </FriendsButton>
              </Grid>

              <Grid item xs={7}>
                <FriendsButton
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() => this.props.next()}
                >
                  {this.props.isLastQuestion ? "Slutför" : "Nästa"}
                </FriendsButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withFriendsForm(withTheme()(PlatserInput));
