import React from "react";
import styled from "styled-components";
import { FriendsColors } from "../theme";

// Size of the center spacing in percent
const BAR_SPACING = 1;

const DASHARRAY_BASE = 170.0;
const DASHARRAY_BAR_MAX = 132.5;
const DASHARRAY_BAR_MIN = 1.0;
const DASHARRAY_RANGE = DASHARRAY_BAR_MAX;

const STROKE_WIDTH = 6;
const HALF_STROKE_WIDTH = STROKE_WIDTH / 2;
const PATH_CENTER = 50 - HALF_STROKE_WIDTH;
const PATH_END = 100 - STROKE_WIDTH;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  box-sizing: border-box;

  svg {
    transform: translateX(-${BAR_SPACING}%);
  }

  &[data-position="left"] {
    transform: rotateY(180deg) rotateX(180deg) translateX(${50 + BAR_SPACING}%);
  }

  &[data-position="right"] {
    transform: translateX(${50 + BAR_SPACING}%) rotateX(180deg);
  }

  svg {
    /* transform: scale(0.95); */
    /* transform-origin: 5% 50%; */
  }
`;

// const PlatsRating = styled.div`
//   background-color: ${(props: PlatsRatingProps) => props.color};
//   transform: ${(props: PlatsRatingProps) => `scale(${props.scale})`};
//   border-radius: 50%;
//   width: 25%;
//   padding-top: 25%;
//   box-shadow: 0 0 10rem 0 rgba(0, 0, 0, 0.15);
// `;

interface Props {
  position: "left" | "right";
  rating: number | null; // 0..1
}

export const PlatsRating: React.SFC<Props> = props => {
  const color =
    props.position === "left" ? FriendsColors.red : FriendsColors.lime;
  return (
    <Container data-position={props.position}>
      {/* {!!props.rating.green && (
                  <PlatsRating
                    color={FriendsColors.lime}
                    scale={props.rating.green + 0.2}
                  />
                )} */}

      <svg viewBox="0 0 100 100" style={{}}>
        {/* <circle cx="50" cy="50" r="45" fill="#FDB900" /> */}

        <path
          fill="none"
          strokeLinecap="round"
          strokeWidth={STROKE_WIDTH}
          stroke="rgba(0, 0, 0, 0.1)"
          strokeDasharray={`${DASHARRAY_BAR_MAX}, ${DASHARRAY_BASE}`}
          d={`
            M0 ${HALF_STROKE_WIDTH}
            a ${PATH_CENTER} ${PATH_CENTER} 0 0 1 0 ${PATH_END}
            a ${PATH_CENTER} ${PATH_CENTER} 0 0 1 0 -${PATH_END}
          `}
        />

        {props.rating && props.rating * DASHARRAY_RANGE > DASHARRAY_BAR_MIN && (
          <path
            fill="none"
            strokeLinecap="round"
            strokeWidth={STROKE_WIDTH}
            stroke={color}
            strokeDasharray={`${
              props.rating * DASHARRAY_RANGE
            }, ${DASHARRAY_BASE}`}
            d={`
                M0 ${HALF_STROKE_WIDTH}
                a ${PATH_CENTER} ${PATH_CENTER} 0 0 1 0 ${PATH_END}
                a ${PATH_CENTER} ${PATH_CENTER} 0 0 1 0 -${PATH_END}
              `}
          />
        )}
      </svg>
    </Container>
  );
};
