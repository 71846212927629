import { TryggaOchOtryggaPlatser } from "friends-shared";
import React from "react";
import { AktivitetScreen } from "../../common/AktivitetScreen";
import PlatserInput from "./components/PlatserInput";

interface Props {
  slide: TryggaOchOtryggaPlatser.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  return (
    <AktivitetScreen
      idleHeader="Bra!"
      idleText="Din lärare startar strax ert nästa uppdrag."
      component={<PlatserInput questions={props.slide.questions} />}
    />
  );
};
