import * as React from "react";
import styled from "styled-components";
import { FriendsButton, FriendsChip } from "../../../../../../theme";
import { FriendsFormProps, withFriendsForm } from "../../../common/Form";

const WordsContainer = styled.div`
  padding: 1rem 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  div[role="button"] {
    flex-grow: 1;
    margin: 0.3rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    height: 2.5rem;
    border-radius: 2.5rem;

    transition-duration: 0.2s;
  }
`;

interface Question {
  words: string[];
}

class ValbaraOrdInput extends React.Component<FriendsFormProps<Question>> {
  render() {
    return (
      <>
        <WordsContainer>
          {this.props.question.words.map((word, index) => (
            <FriendsChip
              variant="outlined"
              key={index}
              label={word}
              onClick={() =>
                this.isLimited()
                  ? this.isSelected(word) && this.toggleOption(word)
                  : this.toggleOption(word)
              }
              clickable={this.isLimited() ? this.isSelected(word) : true}
              color={this.isSelected(word) ? "primary" : "default"}
            />
          ))}
        </WordsContainer>

        <FriendsButton
          fullWidth
          color="secondary"
          variant="contained"
          onClick={() => this.props.next()}
        >
          Klar
        </FriendsButton>
      </>
    );
  }

  private toggleOption = (option: string) => {
    const selections = this.answers;
    const index = selections.indexOf(option);

    if (index >= 0) {
      selections.splice(index, 1);
    } else {
      selections.push(option);
    }

    const answer = selections.join(",");

    this.props.setAnswer(answer);
  };

  private isSelected = (option: string) => {
    return this.answers.includes(option);
  };

  private isLimited = () => {
    return this.answers.length >= 5;
  };

  private get answers() {
    return this.props.answer.split(",").filter(answer => !!answer);
  }
}

export default withFriendsForm(ValbaraOrdInput);
