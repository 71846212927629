import { SkalFrågor, Övningstyp } from "friends-shared";
import React from "react";
import { Assets } from "../../../../../../../shared/assets";
import InstruktionerSlide from "../../common/Instruktioner";

interface Props {
  slide: SkalFrågor.Slide.Aktivitet;
}

export const Aktivitet: React.SFC<Props> = props => {
  return (
    <InstruktionerSlide
      steg={props.slide}
      images={{
        bottom: Assets.Uppdrag[Övningstyp.Skalfrågor].förklaring2,
        right: Assets.Uppdrag[Övningstyp.Skalfrågor].förklaring1
      }}
    />
  );
};
