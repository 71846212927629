import { Divider, Icon, IconButton, Typography } from "@material-ui/core";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import { Message } from "friends-shared";
import {
  default as React,
  Fragment,
  useContext,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import { ConfirmDeletion } from "../../../../shared/components/ConfirmDeletion";
import { FriendsContext } from "../../context";
import { FriendsFonts } from "../../theme";

export const Bullets = styled.ul`
  padding-left: 2rem;
  color: rgba(0, 0, 0, 0.87);
  font-family: ${FriendsFonts.Arial};
  font-weight: 400;
  line-height: 1.5;

  li + li {
    margin-top: 1rem;
  }
`;

const DiscussionBoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 25rem;
  padding: 0 1rem;
  background-color: white;
  word-break: break-word;
  box-sizing: border-box;
  overflow-y: auto;

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px -1px rgba(0, 0, 0, 0.05);
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 0.25rem 0.5rem 1rem;

  > * {
    margin: 0 !important;
  }
`;

const MessagePreview = styled.div`
  background: rgba(0, 0, 0, 0.05);
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 24px;

  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;

  color: rgba(0, 0, 0, 0.87);
  font-family: ${FriendsFonts.Arial};
  font-weight: 400;
  line-height: 1.5;
`;

const NoMessagesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h6,
  span {
    color: #888888;
  }

  span {
    margin-bottom: 0.75rem;
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

export const DiscussionText: React.FC = () => {
  return (
    <Typography paragraph gutterBottom>
      Här visas elevernas inlägg i realtid. Använd gärna dem som en del av
      diskussionen. Alla inlägg sparas i systemet.
    </Typography>
  );
};

export const DiscussionBox: React.FC = () => {
  const { workshop, actions } = useContext(FriendsContext);
  const messagesEnd = useRef<HTMLDivElement | null>(null);

  const [messageMarkedForDeletion, setMessageMarkedForDeletion] =
    useState<Message | null>(null);

  const scrollToBottom = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const deleteMessage = () => {
    if (messageMarkedForDeletion) {
      actions.deleteMessage(messageMarkedForDeletion.id);
      setMessageMarkedForDeletion(null);
    }
  };

  const cancelDeleteMessage = () => {
    setMessageMarkedForDeletion(null);
  };

  scrollToBottom();

  return (
    <>
      <DiscussionBoxContainer>
        {workshop.slideMessages.length === 0 && (
          <NoMessagesContainer>
            <Icon>subject</Icon>
            <Typography variant="h6">Inga inlägg ännu</Typography>
          </NoMessagesContainer>
        )}

        {workshop.slideMessages.map((message, index) => (
          <Fragment key={message.id}>
            <MessageContainer key={message.id}>
              <Typography paragraph>{message.text}</Typography>

              <IconButton onClick={() => setMessageMarkedForDeletion(message)}>
                <DeleteRounded />
              </IconButton>
            </MessageContainer>

            {index !== workshop.slideMessages.length && (
              <Divider variant="fullWidth" />
            )}
          </Fragment>
        ))}

        <div ref={messagesEnd} />
      </DiscussionBoxContainer>

      <ConfirmDeletion
        content={
          messageMarkedForDeletion ? messageMarkedForDeletion.text : undefined
        }
        onCancel={cancelDeleteMessage}
        onConfirmDeletion={deleteMessage}
      />
    </>
  );
};
