import { TryggaOchOtryggaPlatser, Övningstyp } from "friends-shared";
import React from "react";
import { Assets } from "../../../../../../../shared/assets";
import Introduktion from "../../common/Introduktion";

interface Props {
  slide: TryggaOchOtryggaPlatser.Slide.Instruktioner;
}

export const Instruktioner: React.SFC<Props> = props => {
  return (
    <Introduktion
      steg={props.slide}
      image={Assets.Uppdrag[Övningstyp.TryggaOchOtryggaPlatser].cover}
    />
  );
};
