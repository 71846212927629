export const copyToClipboard = (text: string) => {
  const listener = (event: ClipboardEvent): void => {
    if (event.clipboardData) {
      event.clipboardData.setData("text/plain", text);
    }

    event.preventDefault();
    document.removeEventListener("copy", listener);
  };

  document.addEventListener("copy", listener);
  document.execCommand("copy");
};
