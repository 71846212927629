import { Exercise, Övningstyp } from "friends-shared";
import * as React from "react";
import styled from "styled-components";
import { StateConsumer } from "../../../context";
import FyraHorn from "./Fyra Hörn";
import Skalfragor from "./Skalfrågor";
import TryggKlass from "./Trygg Klass";
import TryggaOchOtryggaPlatser from "./Trygga Och Otrygga Platser";

interface Props {
  exercise: Exercise;
  slideIndex: number;
}

class Steg extends React.Component<Props> {
  render() {
    const { exercise, slideIndex } = this.props;

    switch (exercise.type) {
      case Övningstyp.TryggKlass:
        return <TryggKlass slide={exercise.slides[slideIndex]} />;

      case Övningstyp.TryggaOchOtryggaPlatser:
        return <TryggaOchOtryggaPlatser slide={exercise.slides[slideIndex]} />;

      case Övningstyp.Skalfrågor:
        return <Skalfragor slide={exercise.slides[slideIndex]} />;

      case Övningstyp.FyraHörn:
        return <FyraHorn slide={exercise.slides[slideIndex]} />;

      default:
        return null;
    }
  }
}

const StegContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

class Övning extends React.Component {
  render() {
    return (
      <StateConsumer>
        {state => {
          const { exercise } = state.workshop;

          if (exercise.data) {
            return (
              <StegContainer>
                <Steg
                  exercise={exercise.data}
                  slideIndex={exercise.currentSlideIndex}
                />
              </StegContainer>
            );
          }
        }}
      </StateConsumer>
    );
  }
}

export default Övning;
