import { Divider, Grid, Typography } from "@material-ui/core";
import { AbstractSlide, Slidetyp, Övningstyp } from "friends-shared";
import React, { useContext } from "react";
import styled from "styled-components";
import { FriendsContext } from "../../../../context";
import {
  useIsLastSlide,
  useIsSlidetyp,
  useIsÖvningstyp
} from "../../../../hooks";
import { FriendsButton } from "../../../../theme";

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
`;

const Subtitle = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  padding: 1rem 0rem 1rem 0rem;
`;

const useNextButtonText = () => {
  const isAktivitet = useIsSlidetyp(Slidetyp.Aktivitet);
  const isResultat = useIsSlidetyp(Slidetyp.Resultat);
  const isFyraHörn = useIsÖvningstyp(Övningstyp.FyraHörn);
  const isLastSlide = useIsLastSlide();

  if (isLastSlide) {
    return "Avsluta";
  }

  if (isAktivitet) {
    return "Visa resultat";
  }

  if (isResultat) {
    if (isFyraHörn) {
      return "Nästa situation";
    }

    return "Nästa uppdrag";
  }

  return "Vidare";
};

interface Props {
  steg: AbstractSlide;
  subtitle?: string;
  onStart?(): void;
}

export const Dashboard: React.SFC<Props> = props => {
  const state = useContext(FriendsContext);
  const nextButtonText = useNextButtonText();

  const exercise = state.workshop.exercise.data;

  if (!exercise) {
    return null;
  }

  const title = exercise.name;
  const index = state.workshop.exercise.currentExerciseIndex + 1;
  const currentSlide = state.workshop.exercise.currentSlideIndex + 1;
  const totalSlides = exercise.slides.length;

  return (
    <Container>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="space-between">
            <div style={{ flex: 1 }} />

            <div>
              <Typography
                className="typography-bold"
                variant="h4"
                color="primary"
                align="center"
              >
                Uppdrag {index} - {title}, steg {currentSlide} av {totalSlides}
              </Typography>
            </div>

            <div style={{ flex: 1 }}>
              <Grid container justify="flex-end" wrap="nowrap" spacing={8}>
                <Grid item>
                  <FriendsButton
                    variant="text"
                    color="primary"
                    onClick={() => state.actions.retractWorkshop()}
                  >
                    Bakåt
                  </FriendsButton>
                </Grid>

                <Grid item>
                  <FriendsButton
                    variant="contained"
                    color="primary"
                    onClick={() => state.actions.advanceWorkshop()}
                  >
                    {nextButtonText}
                  </FriendsButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
      </Grid>

      {props.subtitle && (
        <Subtitle>
          <Typography variant="h6" color="primary" align="center">
            {props.subtitle}
          </Typography>
        </Subtitle>
      )}

      <Grid
        container
        spacing={40}
        style={{
          paddingTop: "2rem"
        }}
      >
        {props.children}
      </Grid>
    </Container>
  );
};
