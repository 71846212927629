import { TryggKlass } from "friends-shared";
import { Component, default as React } from "react";
import styled from "styled-components";
import { FriendsWordcloud } from "../../../../../../../../shared/components/Wordcloud";
import { StateConsumer } from "../../../../../../context";
import { socket } from "../../../../../../socket";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 10rem;

  > * {
    height: 100%;
  }
`;

interface Props {
  steg: TryggKlass.Slide;
}

export default class Wordcloud extends Component<Props> {
  async componentDidMount() {
    await socket.requestAnswersForPreviousSlide();
  }

  render() {
    return (
      <StateConsumer>
        {state => (
          <Container>
            <FriendsWordcloud size="large" answers={state.workshop.answers} />
          </Container>
        )}
      </StateConsumer>
    );
  }
}
