import { Övningstyp } from "friends-shared";
import * as React from "react";
import { FriendsContext } from "../../../context";
import FyraHorn from "./Fyra Hörn";
import Skalfragor from "./Skalfrågor";
import TryggKlass from "./Trygg Klass";
import TryggaOchOtryggaPlatser from "./Trygga Och Otrygga Platser";

const Ovning: React.SFC = () => {
  const context = React.useContext(FriendsContext);
  const exercise = context.workshop.exercise.data;
  const slideIndex = context.workshop.exercise.currentSlideIndex;

  if (!exercise) {
    return null;
  }

  switch (exercise.type) {
    case Övningstyp.TryggKlass:
      return <TryggKlass slide={exercise.slides[slideIndex]} />;

    case Övningstyp.TryggaOchOtryggaPlatser:
      return <TryggaOchOtryggaPlatser slide={exercise.slides[slideIndex]} />;

    case Övningstyp.Skalfrågor:
      return <Skalfragor slide={exercise.slides[slideIndex]} />;

    case Övningstyp.FyraHörn:
      return <FyraHorn slide={exercise.slides[slideIndex]} />;

    default:
      return null;
  }
};

export default Ovning;
