import { Answer, SkalFrågor } from "friends-shared";
import React, { FC } from "react";
import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";
import styled from "styled-components";
import { FriendsColors } from "../../../theme";

const RadarChartContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    overflow: visible;
    width: 70%;
    height: 70%;

    max-width: 35rem;
    max-height: 35rem;

    path {
      mix-blend-mode: multiply;
    }

    .shape {
      fill-opacity: 1;
    }

    .caption {
      fill: ${FriendsColors.purple} !important;
      font-weight: 600;
      font-size: 1em;
      /* transform: translateY(5px); */
      font-family: inherit;
    }
  }
`;

export const createRadarData = (
  slide: SkalFrågor.Slide.Aktivitet,
  slideAnswers: Answer[]
) => {
  const questions = slide.questions || [];
  let values: Record<number, number> = {};

  questions.forEach((question, questionIndex) => {
    const answers = slideAnswers.filter(answer => {
      return answer.questionIndex === questionIndex;
    });

    const total = answers.reduce((result, answer) => {
      result += Number(answer.text) / question.max;
      return result;
    }, 0);

    const value = total / answers.length;

    values[questionIndex] = value > 0 ? value : 0;
  });

  const captions = questions.reduce((captions, question, index) => {
    captions[index] = question.text.short;
    return captions;
  }, {} as Record<number, string>);

  return {
    values,
    captions
  };
};

interface Props {
  slide: SkalFrågor.Slide.Aktivitet;
  slideAnswers: Answer[];
}

export const Radar: FC<Props> = props => {
  const data = createRadarData(props.slide, props.slideAnswers);

  if (!data) {
    return null;
  }

  return (
    <RadarChartContainer>
      <RadarChart
        data={[
          {
            data: data.values,
            meta: {
              color: FriendsColors.purple
            }
          }
        ]}
        captions={data.captions}
        options={{
          scales: 10,
          captionProps: (caption: any) => {
            // Top angle      = 0
            // Bottom angle   = Math.PI
            // Left angle     > Math.PI
            // Right angle    > 0 && < Math.PI

            // Left
            if (caption.angle > Math.PI) {
              return {
                className: "caption",
                textAnchor: "end"
              };
            }

            // Right
            if (caption.angle > 0 && caption.angle < Math.PI) {
              return {
                className: "caption",
                textAnchor: "start"
              };
            }

            // Top/bottom
            return {
              className: "caption",
              textAnchor: "middle"
            };
          }
        }}
      />
    </RadarChartContainer>
  );
};
