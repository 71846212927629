"use strict";

exports.__esModule = true;

var Answer = function () {
  function Answer() {}

  return Answer;
}();

exports.Answer = Answer;