import { Grid, Typography } from "@material-ui/core";
import { SkalFrågor } from "friends-shared";
import React from "react";
import CircleSlider from "../../../../../../../../shared/components/CircleSlider";
import { FriendsButton } from "../../../../../../theme";
import { FriendsFormProps, withFriendsForm } from "../../../common/Form";

type Question = SkalFrågor.Slide.Aktivitet["questions"][number];

class FleraFrågorForm extends React.Component<FriendsFormProps<Question>> {
  componentWillMount() {
    this.props.setDefaultValue("1");
  }

  render() {
    return (
      <>
        <Grid container direction="column" spacing={32}>
          <Grid item>
            <Typography
              gutterBottom
              className="typography-bold"
              variant="h5"
              color="secondary"
              align="center"
            >
              Fråga {this.props.questionIndex + 1} av{" "}
              {this.props.totalQuestions}
            </Typography>

            <Typography
              variant="h6"
              color="textSecondary"
              align="center"
              style={{ minHeight: 55 }}
            >
              {this.props.question.text.full}
            </Typography>
          </Grid>

          <Grid item>
            <CircleSlider
              value={+this.props.answer}
              min={this.props.question.min}
              max={this.props.question.max}
              onChange={value => this.props.setAnswer("" + value)}
            />
          </Grid>

          <Grid item>
            <Grid container justify="space-between">
              <Grid item>
                <Typography paragraph color="textSecondary">
                  {this.props.question.min} = Håller inte med alls
                </Typography>
              </Grid>

              <Grid item>
                <Typography paragraph color="textSecondary">
                  {this.props.question.max} = Håller med helt
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={8}>
          <Grid item xs={4}>
            <FriendsButton
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => this.props.previous()}
              disabled={this.props.isFirstQuestion}
            >
              Bakåt
            </FriendsButton>
          </Grid>

          <Grid item xs={8}>
            <FriendsButton
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => this.props.next()}
            >
              {this.props.isLastQuestion ? "Slutför" : "Nästa"}
            </FriendsButton>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withFriendsForm(FleraFrågorForm);
