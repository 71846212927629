import { Typography } from "@material-ui/core";
import { Answer, SkalFrågor } from "friends-shared";
import React from "react";
import styled from "styled-components";
import FriendsLogotype from "../../../../shared/components/Logotype";
import { Radar } from "../../../../shared/components/plots/Radar/Radar";
import { FriendsColors } from "../../theme";

const A4 = styled.div`
  background-color: white;
  position: relative;
  min-width: 297mm;
  max-height: 209mm;

  width: 100%;
  height: 100%;
`;

const PrintSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
`;

const PrintTitle = styled(PrintSection)`
  top: 5%;
  bottom: 90%;
`;

const PrintRadarChart = styled(PrintSection)`
  top: 10%;
  bottom: 0%;

  svg {
    transform: scale(2.5);

    .scale {
      fill: white;
    }

    .axis,
    .scale {
      stroke: #b9b9b9;
      opacity: 0.3;
    }
  }
`;

const PrintLogotype = styled(PrintSection)`
  top: 6%;
  left: 7.5%;
  width: 4%;
  height: 4%;
`;

interface Props {
  answers: Answer[];
  slide: SkalFrågor.Slide.Aktivitet;
}

const purpleStyle = {
  color: FriendsColors.purple
};

export class PrintableRadarChart extends React.Component<Props> {
  render() {
    return (
      <A4>
        <PrintTitle>
          <Typography
            className="typography-bold"
            variant="h3"
            align="center"
            style={purpleStyle}
          >
            Klassens Kompass
          </Typography>
        </PrintTitle>

        <PrintRadarChart>
          <Radar slide={this.props.slide} slideAnswers={this.props.answers} />
        </PrintRadarChart>

        <PrintLogotype>
          <FriendsLogotype color="yellow" />
        </PrintLogotype>
      </A4>
    );
  }
}
