import { Grid, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { FriendsContext, StateConsumer } from "../../context";
import { withScreen } from "../../Screen";
import { FriendsButton, FriendsColors } from "../../theme";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 550px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  onComplete(): void;
}

@withScreen({
  colors: {
    primary: {
      main: FriendsColors.purple
    },
    background: {
      default: FriendsColors.lightgrey
    }
  },
  headerLogo: "yellow"
})
class WorkshopInstructionsScreen extends React.Component<Props> {
  static contextType = FriendsContext;
  context!: React.ContextType<typeof FriendsContext>;

  render() {
    return (
      <Container>
        <Grid container justify="center" alignItems="center" spacing={24}>
          <Grid item xs={12}>
            <Typography
              className="typography-bold"
              align="center"
              variant="h1"
              color="primary"
            >
              Starta uppdragen
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              color="primary"
              variant="h3"
              align="center"
              gutterBottom
              style={{ fontWeight: 400 }}
            >
              Anslutna användare
            </Typography>

            <StateConsumer>
              {state => (
                <Typography
                  className="typography-bold"
                  color="textSecondary"
                  variant="h1"
                  align="center"
                >
                  {state.workshop.students.length}
                </Typography>
              )}
            </StateConsumer>
          </Grid>

          <Grid item xs={12}>
            <Typography paragraph align="center" style={{ padding: "0 2rem" }}>
              När alla elever har anslutit sig, gå vidare för att presentera
              klassens första uppdrag.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <FriendsButton
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.onComplete()}
                >
                  Vidare
                </FriendsButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default WorkshopInstructionsScreen;
