import { Slidetyp } from "friends-shared";
import { useContext } from "react";
import { FriendsContext } from "./context";

export const useCurrentQuestion = () => {
  const { workshop } = useContext(FriendsContext);

  if (workshop.exercise.data) {
    const slide =
      workshop.exercise.data.slides[workshop.exercise.currentSlideIndex];

    if (slide.typ === Slidetyp.Aktivitet) {
      return slide.questions[workshop.questionIndex];
    }
  }
};
