import { Typography } from "@material-ui/core";
import { AbstractSlide } from "friends-shared";
import * as React from "react";
import styled from "styled-components";
import { StateConsumer } from "../../../../context";
import { ScreenProps } from "../../../../Screen";

interface Props extends ScreenProps {
  steg: AbstractSlide;
  image: string;
}

const Text = styled.div`
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  top: 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Photo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 50%;

  background-position: bottom;
  background-repeat: no-repeat;
`;

class Introduktion extends React.Component<Props> {
  render() {
    return (
      <StateConsumer>
        {state => {
          const exercise = state.workshop.exercise.data;

          if (exercise) {
            const title = exercise.name;
            const index = state.workshop.exercise.currentExerciseIndex + 1;

            return (
              <>
                <Text>
                  <Typography
                    gutterBottom
                    variant="h5"
                    color="primary"
                    align="center"
                  >
                    Uppdrag {index}
                  </Typography>

                  <Typography
                    className="typography-bold"
                    variant="h2"
                    align="center"
                    color="primary"
                  >
                    {title}
                  </Typography>
                </Text>

                <Photo
                  style={{
                    backgroundImage: `url(${this.props.image})`
                  }}
                />
              </>
            );
          }
        }}
      </StateConsumer>
    );
  }
}

export default Introduktion;
