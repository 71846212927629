import React from "react";
import { StateConsumer } from "../context";
import Finished from "./finished/Finished";
import Lobby from "./lobby/Lobby";
import Setup from "./setup";
import Ovning from "./övning/övningar/Övning";

class Workshop extends React.Component {
  render() {
    return (
      <StateConsumer>
        {state => {
          const { workshop } = state;

          if (workshop.status) {
            switch (workshop.status) {
              case "setup":
                return <Setup />;

              case "waiting":
                return <Lobby />;

              case "running":
                return <Ovning />;

              case "finished":
                return <Finished />;
            }
          }

          return <Lobby />;
        }}
      </StateConsumer>
    );
  }
}

export default Workshop;
