import { Grid, Typography } from "@material-ui/core";
import { SkalFrågor, Slidetyp } from "friends-shared";
import React, { useContext } from "react";
import "react-svg-radar-chart/build/css/index.css";
import styled from "styled-components";
import { Radar } from "../../../../../../../shared/components/plots/Radar/Radar";
import { FriendsContext } from "../../../../../context";
import { useLatestSlide } from "../../../../../hooks";

const Container = styled.div`
  max-width: 1900px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  > * {
    height: 100%;
  }
`;

interface Props {
  slide: SkalFrågor.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  const { workshop } = useContext(FriendsContext);
  const slide = useLatestSlide<SkalFrågor.Slide.Aktivitet>(Slidetyp.Aktivitet);

  if (!slide) {
    return null;
  }

  return (
    <Container>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            className="typography-bold"
            color="primary"
            align="center"
            style={{ marginBottom: "1.5rem" }}
          >
            Så här svarade ni
          </Typography>

          <Grid container spacing={24} alignItems="center">
            <Grid item xs={12}>
              <Radar slide={slide} slideAnswers={workshop.exerciseAnswers} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
