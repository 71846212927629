import {
  Grid,
  Grow,
  MuiThemeProvider,
  Theme,
  Typography,
  withTheme,
  WithTheme
} from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { Slidetyp, WorkshopProgress } from "friends-shared";
import React, { Component, ComponentType } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import FriendsLogotype, {
  FriendsLogotypeColor
} from "../../shared/components/Logotype";
import { StateConsumer } from "./context";
import { updateTheme } from "./theme";

const spacings = {
  small: "2rem",
  medium: "4rem",
  large: "8rem"
};

const widths = {
  small: "600px",
  medium: "800px",
  large: "1700px"
};

const ScreenContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  background-color: ${props => props.color};
`;

interface ScreenContentProps {
  width?: keyof typeof widths;
  spacing?: keyof typeof spacings;
}

const ScreenContent = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${(props: ScreenContentProps) => widths[props.width || "large"]};
  margin-top: ${(props: ScreenContentProps) =>
    props.spacing && spacings[props.spacing]};
`;

const ScreenHeader = styled.header`
  height: 2.75rem;
  margin-bottom: 2rem;

  display: flex;
  justify-content: space-between;
`;

export interface ScreenProps {}

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  margin: 0.2rem;
  border-radius: 50%;
  display: inline-block;
`;

const ExerciseIndicator: React.SFC<
  WithTheme & { isActive: boolean }
> = props => {
  return (
    <Indicator
      style={{
        backgroundColor: props.isActive
          ? props.theme.palette.text.primary
          : "rgba(0, 0, 0, 0.3)"
      }}
    />
  );
};

const ThemedExerciseIndicator = withTheme()(ExerciseIndicator);

export function withScreen(configuration: {
  width?: keyof typeof spacings;
  spacing?: keyof typeof spacings;
  headerLogo: FriendsLogotypeColor | null;
  colors: Partial<PaletteOptions>;
  introColors?: Partial<PaletteOptions>;
}): <P extends ScreenProps>(WrappedComponent: ComponentType<P>) => void {
  return <P extends ScreenProps>(WrappedComponent: ComponentType<P>) =>
    class ScreenComponent extends Component<P> {
      private lastPalette?: Partial<PaletteOptions>;
      private lastTheme?: Theme;

      private createTheme({ data, currentSlideIndex }: WorkshopProgress) {
        let palette = configuration.colors;

        if (data && configuration.introColors) {
          if (data.slides[currentSlideIndex].typ === Slidetyp.Instruktioner) {
            if (currentSlideIndex === 0) {
              palette = configuration.introColors;
            }
          }
        }

        if (JSON.stringify(palette) === JSON.stringify(this.lastPalette)) {
          if (this.lastTheme) {
            return this.lastTheme;
          }
        }

        this.lastPalette = palette;

        const theme = updateTheme({
          palette
        });

        this.lastTheme = theme;

        return theme;
      }

      private renderExerciseIndicator(progress: WorkshopProgress) {
        const indicators = [];

        if (!progress.data) {
          return null;
        }

        for (
          let indicator = 0;
          indicator < progress.data.slides.length;
          indicator++
        ) {
          const isActive = progress.currentSlideIndex === indicator;

          indicators.push(
            <ThemedExerciseIndicator isActive={isActive} key={indicator} />
          );
        }

        return indicators;
      }

      render() {
        return (
          <StateConsumer>
            {state => {
              const theme = this.createTheme(state.workshop.exercise);

              return (
                <MuiThemeProvider theme={theme}>
                  <Helmet>
                    <meta
                      name="theme-color"
                      content={theme.palette.background.default}
                    />
                  </Helmet>

                  <Grow in={true}>
                    <ScreenContainer color={theme.palette.background.default}>
                      <ScreenHeader>
                        {configuration.headerLogo && (
                          <FriendsLogotype color={configuration.headerLogo} />
                        )}

                        {state.workshop.status === "running" && (
                          <Grid
                            container
                            spacing={16}
                            justify="flex-end"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h5" color="textPrimary">
                                Uppdrag{" "}
                                {state.workshop.exercise.currentExerciseIndex +
                                  1}
                              </Typography>
                            </Grid>

                            {this.renderExerciseIndicator(
                              state.workshop.exercise
                            )}
                          </Grid>
                        )}
                      </ScreenHeader>

                      <ScreenContent
                        width={configuration.width}
                        spacing={configuration.spacing}
                      >
                        <WrappedComponent {...this.props} />
                      </ScreenContent>
                    </ScreenContainer>
                  </Grow>
                </MuiThemeProvider>
              );
            }}
          </StateConsumer>
        );
      }
    };
}
