"use strict";

exports.__esModule = true;

var Workshop = function () {
  function Workshop() {}

  return Workshop;
}();

exports.Workshop = Workshop;