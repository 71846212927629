import {
  Answer,
  Slidetyp,
  Student,
  Workshop,
  WorkshopProgress,
  WorkshopStatus
} from "friends-shared";
import * as React from "react";
import SessionUrlHandler, {
  getSessionIdFromURL
} from "../../shared/url-session";
import { Log } from "../../shared/util/logging";
import { socket } from "./socket";

interface ClientActions {
  joinWorkshop(code: string): Promise<void>;
  leaveWorkshop(): Promise<void>;
  createStudent(student: Student): Promise<void>;
  submitMessage(message: string): Promise<void>;
  questions: {
    next(): void;
    previous(): void;
    submit(answer: {
      text: string;
      comment?: string;
      question: number;
    }): Promise<void>;
  };
}

export interface ClientState {
  actions: ClientActions;
  sessionId: string | null;
  workshop: {
    id: number | null;
    municipality: string;
    exercise: WorkshopProgress;
    slideAnswers: Answer[];
    questionIndex: number;
    student: {
      id: number | null;
      grade: string;
      sex: string;
      avatar: string;
    };
    status: WorkshopStatus | null;
  };
}

export const FriendsContext = React.createContext({} as ClientState);

class StateProvider extends React.Component<{}, ClientState> {
  componentWillMount() {
    const sessionId = getSessionIdFromURL("elev");

    Log.session("session from url", sessionId);

    this.setState({
      sessionId
    });
  }

  componentDidMount() {
    socket.connect();

    socket.on<Workshop>("kicked").subscribe(() => {
      this.handleWorkshopKick();
    });

    socket.on<WorkshopProgress>("slide").subscribe(slide => {
      this.setWorkshopSlide(slide);
    });

    socket.on<Workshop>("joined workshop").subscribe(workshop => {
      this.setWorkshop(workshop);
    });

    socket.on<Student>(["student", "created student"]).subscribe(student => {
      this.setStudent(student);
    });

    socket.on<WorkshopStatus>("workshop status change").subscribe(status => {
      this.setWorkshopStatus(status);
    });

    socket.on<Answer[]>("slide answers").subscribe(answers => {
      this.setWorkshopSlideAnswers(answers);
    });

    socket.on<string>("authenticated").subscribe(sessionId => {
      this.setSessionId(sessionId);
    });

    socket.on<void>("authentication failed").subscribe(() => {
      this.setSessionId(null);
      this.leaveWorkshop();
    });

    if (this.state.sessionId) {
      socket.authenticate(this.state.sessionId);
    }

    if (socket.instance) {
      socket.instance.on("reconnect", () => {
        if (this.state.sessionId) {
          socket.authenticate(this.state.sessionId);
        }
      });
    }
  }

  componentWillUnmount() {
    socket.disconnect();
  }

  constructor(props: {}) {
    super(props);

    this.state = {
      sessionId: null,
      workshop: {
        id: null,
        municipality: "",
        student: {
          id: null,
          grade: "",
          avatar: "",
          sex: ""
        },
        exercise: {
          data: null,
          currentExerciseIndex: 0,
          currentSlideIndex: 0,
          totalExercises: 0,
          totalSlides: 0
        },
        slideAnswers: [],
        questionIndex: 0,
        status: null
      },
      actions: {
        joinWorkshop: this.joinWorkshop.bind(this),
        leaveWorkshop: this.leaveWorkshop.bind(this),
        createStudent: this.createStudent.bind(this),
        submitMessage: this.submitMessage.bind(this),
        questions: {
          next: this.nextQuestion.bind(this),
          previous: this.previousQuestion.bind(this),
          submit: this.submitQuestionAnswer.bind(this)
        }
      }
    };
  }

  nextQuestion() {
    const exercise = this.state.workshop.exercise.data;

    if (!exercise) {
      return;
    }

    const slide =
      exercise.slides[this.state.workshop.exercise.currentSlideIndex];

    if (!slide) {
      return;
    }

    if (slide.typ !== Slidetyp.Aktivitet) {
      return;
    }

    const questions = slide.questions as unknown[];
    const questionIndex = this.state.workshop.questionIndex + 1;

    if (questionIndex > questions.length - 1) {
      console.warn("Question index out of bounds!");
    }

    this.setState({
      workshop: {
        ...this.state.workshop,
        questionIndex
      }
    });
  }

  previousQuestion() {
    const exercise = this.state.workshop.exercise.data;

    if (!exercise) {
      return;
    }

    const slide =
      exercise.slides[this.state.workshop.exercise.currentSlideIndex];

    if (!slide) {
      return;
    }

    if (slide.typ !== Slidetyp.Aktivitet) {
      return;
    }

    const questions = slide.questions as unknown[];
    const questionIndex = this.state.workshop.questionIndex - 1;

    if (questionIndex < 0) {
      console.error("Question index out of bounds!");
      return;
    }

    this.setState({
      workshop: {
        ...this.state.workshop,
        questionIndex
      }
    });
  }

  submitQuestionAnswer(answer: {
    text: string;
    comment?: string;
    question: number;
  }) {
    return socket.submitAnswer(answer);
  }

  render() {
    return (
      <FriendsContext.Provider value={this.state}>
        <SessionUrlHandler
          basename="elev"
          sessionId={this.state.sessionId}
          sessionIdChange={sessionId => this.setSessionId(sessionId)}
        />
        {this.props.children}
      </FriendsContext.Provider>
    );
  }

  async createStudent(student: Student): Promise<void> {
    await socket.createStudent(student);
  }

  async submitMessage(message: string): Promise<void> {
    await socket.submitMessage(message);
  }

  async joinWorkshop(code: string): Promise<void> {
    await socket.joinWorkshop(code);
  }

  async handleWorkshopKick(): Promise<void> {
    this.setState({
      workshop: {
        id: null,
        municipality: "",
        student: {
          id: null,
          grade: "",
          avatar: "",
          sex: ""
        },
        exercise: {
          data: null,
          currentExerciseIndex: 0,
          currentSlideIndex: 0,
          totalExercises: 0,
          totalSlides: 0
        },
        questionIndex: 0,
        slideAnswers: [],
        status: null
      }
    });
  }

  async leaveWorkshop(): Promise<void> {
    this.setSessionId(null);
    socket.leaveWorkshop().then();

    this.setState({
      workshop: {
        id: null,
        municipality: "",
        student: {
          id: null,
          grade: "",
          avatar: "",
          sex: ""
        },
        exercise: {
          data: null,
          currentExerciseIndex: 0,
          currentSlideIndex: 0,
          totalExercises: 0,
          totalSlides: 0
        },
        questionIndex: 0,
        slideAnswers: [],
        status: null
      }
    });
  }

  async setStudent(student: Student): Promise<void> {
    this.setState({
      workshop: {
        ...this.state.workshop,
        student: {
          id: student.id,
          grade: student.grade,
          avatar: student.avatar,
          sex: student.sex
        }
      }
    });
  }

  private setWorkshop(workshop: Workshop) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        id: workshop.id,
        municipality: workshop.municipality,
        status: workshop.status
      }
    });
  }

  private setWorkshopSlide(exercise: WorkshopProgress) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        exercise,
        questionIndex: 0
      }
    });
  }

  private getFirstNonAnsweredQuestion(answers: Answer[]) {
    let questionIndex = 0;

    const sortedQuestionIndexes = answers
      .map(answer => answer.questionIndex)
      .sort((a, b) => a - b);

    for (let index of sortedQuestionIndexes) {
      if (index === questionIndex) {
        questionIndex++;
      }
    }

    return questionIndex;
  }

  private setWorkshopSlideAnswers(slideAnswers: Answer[]) {
    if (this.state.workshop.questionIndex === 0) {
      const questionIndex = this.getFirstNonAnsweredQuestion(slideAnswers);

      this.setState({
        workshop: {
          ...this.state.workshop,
          slideAnswers,
          questionIndex
        }
      });
    } else {
      this.setState({
        workshop: {
          ...this.state.workshop,
          slideAnswers
        }
      });
    }
  }

  private setWorkshopStatus(status: WorkshopStatus) {
    this.setState({
      workshop: {
        ...this.state.workshop,
        status
      }
    });
  }

  private setSessionId(sessionId: string | null) {
    this.setState({
      sessionId
    });
  }
}

const StateConsumer = FriendsContext.Consumer;

export { StateProvider, StateConsumer };
