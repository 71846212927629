import {
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import ArrowDown from "@material-ui/icons/KeyboardArrowDownRounded";
import ArrowUp from "@material-ui/icons/KeyboardArrowUpRounded";
import { Exercise, Övningstyp } from "friends-shared";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Assets } from "../../../shared/assets";
import { getBackendURL } from "../../../shared/util/backend-url";
import { FriendsButton, FriendsFonts } from "../theme";
import ArrowImage from "./arrow.svg";

interface Props {
  availableExercises: Exercise[];
  exercises: Övningstyp[];
  onChange: (exercises: Övningstyp[]) => void;
  onPrev: () => void;
  onNext: () => void;
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 600px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Actions = styled.div`
  max-width: 350px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

interface ExerciseCardProps {
  type: Övningstyp;
  title: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ExerciseCardContainer = styled.div`
  button {
    padding-top: 65%;
    height: 0;
    color: #941d80;
  }
`;

const ExerciseCardImage = styled.div<{ imageUrl: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #009c8f1f;
  background-image: ${props => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: 50% 30%;
  overflow: hidden;
  transition: all 0.3s ease;

  &:before {
    content: "";
    width: 100%;
    height: 200%;
    display: block;

    background: linear-gradient(0deg, transparent, #941d801f);
    transform: translateY(-100%);

    transition: all 0.3s ease;
  }

  &[data-disabled="true"] {
    /* filter: grayscale(0.9) brightness(0.4); */
    opacity: 0.7;

    &:before {
      transform: translateY(0%);
    }
  }
`;

const ExerciseCard: React.FC<ExerciseCardProps> = props => {
  const imageUrl = Assets.Uppdrag[props.type].cover;

  return (
    <ExerciseCardContainer>
      <FormControl fullWidth>
        <ButtonBase onClick={() => props.onChange(!props.checked)}>
          <ExerciseCardImage
            imageUrl={imageUrl}
            data-disabled={!props.checked}
          />
        </ButtonBase>

        <FormControlLabel
          style={{
            justifyContent: "center"
          }}
          control={
            <Checkbox
              color="primary"
              checked={props.checked}
              onChange={event => props.onChange(event.target.checked)}
            />
          }
          label={props.title}
        />
      </FormControl>
    </ExerciseCardContainer>
  );
};

const useAvailableExercises = () => {
  const [exercises, setExercises] = useState<Exercise[]>([]);

  useEffect(() => {
    const host = getBackendURL();
    const url = `${host}/exercises/all`;

    const load = async () => {
      const response = await fetch(url);
      const data = await response.json();

      setExercises(data);
    };

    load();
  }, []);

  return exercises;
};

interface OrderProps {
  availableExercises: Exercise[];
  exercises: Övningstyp[];
  onChange: (exercises: Övningstyp[]) => void;
}

const OrderContainer = styled.div<{ imageUrl: string }>`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  box-sizing: border-box;

  width: 100%;
  margin: 0 auto;

  /* background: #941d8012; */
  background: #941d8017;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 5rem;
  padding-top: 4px;
  padding-bottom: 4px;

  position: relative;

  &:before {
    content: "";

    position: absolute;
    top: 0;
    left: 10%;
    bottom: 0;
    right: 30%;

    background: ${props => `url(${props.imageUrl})`};
    background-size: cover;
    background-position: 50% 30%;
    opacity: 0.1;
    z-index: -1;
  }

  & > span {
    text-shadow: 0 0 20px #ffffff;
    /* font-family: ${FriendsFonts.Arial}; */
    font-size: 1.2rem;
    /* font-weight: 600; */
    /* color: #524121; */

    white-space: nowrap;
  }
`;

const OrderWrapper = styled.div`
  max-width: 425px;
  margin: 0 auto;
  position: relative;

  &:after {
    content: "Första övningen";

    background-image: url(${ArrowImage});
    background-size: 100%;
    background-repeat: no-repeat;

    position: absolute;
    top: 0;
    right: 0;
    width: 100px;

    color: #f7b233;
    text-align: right;
    background-position: 0% 58%;
    padding-bottom: 100px;
    transform: translate(110px, -24px) rotate(17deg);
    text-align: center;
  }

  h3 {
    transform: translateY(-0.03em);
  }
`;

const OrderActions = styled.div`
  background: #941d80;
  margin: -4px;
  border-radius: 20rem;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: -32px;

  color: white;

  & svg {
    transition: all 0.15s ease;
    transform: scale(1.5);
  }

  & button:first-child {
    margin-right: -8px;
  }

  & button:hover svg {
    transform: scale(1.75);
  }
`;

const Order: React.FC<OrderProps> = props => {
  return (
    <OrderWrapper>
      <Typography variant="h6" align="center" style={{ marginBottom: "1rem" }}>
        Välj ordning på uppdragen
      </Typography>

      <Grid container spacing={8}>
        {props.exercises.map((type, index) => {
          const exercise = props.availableExercises.find(
            exercise => exercise.type === type
          );

          if (!exercise) {
            return null;
          }

          const imageUrl = Assets.Uppdrag[type].cover;

          const isTop = index === 0;
          const isBottom = index === props.exercises.length - 1;

          const moveUp = () => {
            const copy = [...props.exercises];

            copy.splice(index, 1);
            copy.splice(index - 1, 0, type);

            props.onChange(copy);
          };

          const moveDown = () => {
            const copy = [...props.exercises];

            copy.splice(index, 1);
            copy.splice(index + 1, 0, type);

            props.onChange(copy);
          };

          return (
            <Grid item key={type} xs={12}>
              <Grid container alignItems="center" spacing={32}>
                <Grid item xs={1}>
                  <Typography variant="h3" color="primary" align="right">
                    {index + 1}.
                  </Typography>
                </Grid>

                <Grid item xs={11}>
                  <OrderContainer imageUrl={imageUrl}>
                    <Typography variant="caption">{exercise.name}</Typography>

                    <OrderActions>
                      <IconButton
                        color="inherit"
                        disabled={isTop}
                        onClick={moveUp}
                      >
                        <ArrowUp />
                      </IconButton>

                      <IconButton
                        color="inherit"
                        disabled={isBottom}
                        onClick={moveDown}
                      >
                        <ArrowDown />
                      </IconButton>
                    </OrderActions>
                  </OrderContainer>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </OrderWrapper>
  );
};

enum Step {
  Selection,
  Order
}

export const ExerciseSelection: React.FC<Props> = props => {
  const availableExercises = useAvailableExercises();

  const [step, setStep] = useState(Step.Selection);

  const add = (type: Övningstyp) => {
    props.onChange([...props.exercises, type]);
  };

  const remove = (type: Övningstyp) => {
    const copy = [...props.exercises];
    const index = props.exercises.indexOf(type);
    copy.splice(index, 1);

    props.onChange(copy);
  };

  const prev = () => {
    if (step === Step.Order) {
      setStep(Step.Selection);
    } else {
      props.onPrev();
    }
  };

  const next = () => {
    if (step === Step.Selection) {
      if (props.exercises.length === 1) {
        props.onNext();
      } else {
        setStep(Step.Order);
      }
    } else {
      props.onNext();
    }
  };

  return (
    <Container>
      <Grid container justify="center" alignItems="center" spacing={32}>
        <Grid item xs={12}>
          <Typography
            className="typography-bold"
            align="center"
            variant="h2"
            color="primary"
          >
            Vilka uppdrag vill du göra idag?
          </Typography>
        </Grid>

        <Grid item>
          <Typography paragraph gutterBottom>
            För mer information om uppdragen, titta i lärarhandledningen.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {step === Step.Selection && (
            <Grid container spacing={32}>
              {availableExercises.map(exercise => (
                <Grid item xs={6} key={exercise.type}>
                  <ExerciseCard
                    type={exercise.type}
                    checked={props.exercises.includes(exercise.type)}
                    title={exercise.name}
                    onChange={checked => {
                      if (checked) {
                        add(exercise.type);
                      } else {
                        remove(exercise.type);
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {step === Step.Order && (
            <Order
              availableExercises={props.availableExercises}
              exercises={props.exercises}
              onChange={props.onChange}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Actions>
            <FriendsButton
              fullWidth
              variant="text"
              color="primary"
              onClick={prev}
            >
              Bakåt
            </FriendsButton>

            <FriendsButton
              fullWidth
              disabled={props.exercises.length === 0}
              variant="contained"
              color="primary"
              onClick={next}
            >
              Vidare
            </FriendsButton>
          </Actions>
        </Grid>
      </Grid>
    </Container>
  );
};
