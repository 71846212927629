import { AbstractSlide, Slidetyp } from "friends-shared";
import { useContext } from "react";
import { FriendsContext } from "./context";

export const useCurrentSituationIndex = () => {
  const { workshop } = useContext(FriendsContext);

  if (!workshop.exercise.data) {
    return -1;
  }

  const slides = workshop.exercise.data.slides as AbstractSlide[];
  const completedResultatSlides = slides.filter((slide, index) => {
    const isAnyPreviousSlide = index < workshop.exercise.currentSlideIndex;
    const isResultatSlide = slide.typ === Slidetyp.Resultat;

    return isAnyPreviousSlide && isResultatSlide;
  });

  return completedResultatSlides.length + 1;
};

export const useCurrentSlideIndex = () => {
  const { workshop } = useContext(FriendsContext);

  return workshop.exercise.currentSlideIndex;
};

export const useLatestSlide = <T extends AbstractSlide>(
  slideType: Slidetyp
): T | null => {
  const { workshop } = useContext(FriendsContext);
  const data = workshop.exercise.data;

  if (!data) {
    return null;
  }

  for (let i = workshop.exercise.currentSlideIndex; i > 0; i--) {
    const slide = data.slides[i];

    if (slide.typ === slideType) {
      return slide as T;
    }
  }

  return null;
};
