import { Övningstyp } from "friends-shared";
import React, { useContext } from "react";
import { FriendsContext } from "../../../context";
import FyraHorn from "./Fyra Hörn";
import Skalfragor from "./Skalfrågor";
import TryggKlass from "./Trygg Klass";
import TryggaOchOtryggaPlatser from "./Trygga Och Otrygga Platser";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

const Ovning: React.SFC = () => {
  const context = useContext(FriendsContext);
  const exercise = context.workshop.exercise.data;
  const slideIndex = context.workshop.exercise.currentSlideIndex;

  if (!exercise) {
    return null;
  }

  switch (exercise.type) {
    case Övningstyp.TryggKlass:
      return <TryggKlass slide={exercise.slides[slideIndex]} />;

    case Övningstyp.TryggaOchOtryggaPlatser:
      return <TryggaOchOtryggaPlatser slide={exercise.slides[slideIndex]} />;

    case Övningstyp.Skalfrågor:
      return <Skalfragor slide={exercise.slides[slideIndex]} />;

    case Övningstyp.FyraHörn:
      return <FyraHorn slide={exercise.slides[slideIndex]} />;

    default:
      return null;
  }
};

const Code = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;

  margin-right: 1rem;
  padding-bottom: 0.75rem;

  display: flex;

  transition: all 0.3s cubic-bezier(0.3, 1, 0.4, 0.95);
  transform-origin: bottom right;

  opacity: 0.5;

  &:hover {
    transform: scale(2);
    opacity: 1;
  }

  > div {
    position: relative;
  }

  span {
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    position: absolute;
    right: 0;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%) scale(3);

    opacity: 0.05;
  }

  h6 {
    font-weight: 600;
  }
`;

const OvningContainer: React.SFC = () => {
  const { workshop } = React.useContext(FriendsContext);

  return (
    <>
      <Ovning />
      <Code>
        <div>
          <Icon color="primary">vpn_key</Icon>
          <Typography color="primary" variant="h6">
            {workshop.codes.student}
          </Typography>
        </div>
      </Code>
    </>
  );
};

export default OvningContainer;
