import { CircularProgress } from "@material-ui/core";
import React from "react";
import { StateConsumer } from "./context";
import Start from "./start/Start";
import Workshop from "./workshop/Workshop";

class App extends React.Component {
  render() {
    return (
      <StateConsumer>
        {state => {
          if (state.sessionId && state.workshop.id === null) {
            return <CircularProgress style={{ margin: "5rem" }} />;
          }

          if (state.workshop.id === null) {
            return <Start />;
          } else {
            return <Workshop />;
          }
        }}
      </StateConsumer>
    );
  }
}

export default App;
