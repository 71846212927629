import React from "react";
import styled from "styled-components";
import Heart from "../../../../shared/components/Heart";
import { withScreen } from "../../Screen";
import { FriendsColors } from "../../theme";

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

@withScreen({
  buttons: [],
  colors: {
    background: FriendsColors.purple,
    primary: FriendsColors.yellow,
    secondary: FriendsColors.lightbeige,
    textPrimary: FriendsColors.yellow,
    textSecondary: FriendsColors.white
  },
  headerLogo: "lightyellow"
})
class Setup extends React.Component {
  render() {
    return (
      <Container>
        <Heart />
      </Container>
    );
  }
}

export default Setup;
