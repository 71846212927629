import {
  Answer,
  Slidetyp,
  TryggaOchOtryggaPlatser,
  WorkshopProgress
} from "friends-shared";
import React, { Component } from "react";
import { animated, Transition } from "react-spring";
import styled from "styled-components";
import { Plats } from "../../../../../../../../shared/components/Plats";
import { getAsset } from "../../../../../../../../shared/util/clients";
import { FriendsContext } from "../../../../../../context";
import { socket } from "../../../../../../socket";

interface Props {
  steg: TryggaOchOtryggaPlatser.Slide;
}

function loadImages(urls: string[]) {
  const resolves = urls.map(url => {
    return new Promise<void>(resolve => {
      const image = new Image();

      image.onload = () => resolve();
      image.onerror = () => resolve();
      image.oncancel = () => resolve();

      image.src = getAsset(url);
    });
  });

  return Promise.all(resolves);
}

interface State {
  isAllImagesLoaded: boolean;
}

const Container = styled.div`
  position: absolute;
  top: 9vh;
  left: 2.5vw;
  right: 2.5vw;
  bottom: 0;

  @media screen and (max-width: 1024px) {
    right: 5vw;
    left: 5vw;
    top: 10vh;
  }

  @media screen and (min-width: 1024px) {
    top: 10vh;
  }

  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(5, 15vw);

  grid-template-rows: repeat(2, 20vw);
  grid-auto-flow: row;
  column-gap: 2.5vmin;
  row-gap: 2.5vmin;

  & > div {
    // Top row vertical
    &:nth-child(2) > * {
      transform: translateY(-3vmax);
    }

    &:nth-child(4) > * {
      transform: translateY(-3vmax);
    }

    // Center bottom row horizontally
    &:nth-child(6) > *,
    &:nth-child(7) > *,
    &:nth-child(8) > *,
    &:nth-child(9) > * {
      margin-right: calc(-50% - (2.5vw / 2));
      padding-left: calc(50% + (2.5vw / 2));
    }

    // Bottom row vertical
    &:nth-child(7) > * {
      transform: translateY(-2vw);
    }

    &:nth-child(8) > * {
      transform: translateY(-2vw);
    }
  }
`;

export class PlatserOverview extends Component<Props, State> {
  static contextType = FriendsContext;
  context!: React.ContextType<typeof FriendsContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isAllImagesLoaded: false
    };
  }

  private async preloadImages() {
    const { exercise } = this.context.workshop;
    const platser = this.getPlatserFromPreviousSlide(exercise);
    const images = platser.map(plats => plats.location.imageUrl);

    await loadImages(images);

    this.setState({
      isAllImagesLoaded: true
    });
  }

  async componentWillMount() {
    await socket.requestAnswersForPreviousSlide();
    await this.preloadImages();
  }

  getPlatserFromPreviousSlide(exercise: WorkshopProgress) {
    if (!exercise.data) {
      return [];
    }

    const index = exercise.currentSlideIndex;

    for (let i = index; i > 0; i--) {
      const slide = exercise.data.slides[i] as TryggaOchOtryggaPlatser.Slide;

      if (!slide) {
        continue;
      }

      if (slide.typ === Slidetyp.Aktivitet) {
        return slide.questions;
      }
    }

    return [];
  }

  getPlatserWithAnswers(exercise: WorkshopProgress, answers: Answer[]) {
    const questions = this.getPlatserFromPreviousSlide(exercise);

    return questions.map((question, questionIndex) => {
      const questionAnswers = answers.filter(
        answer => answer.questionIndex === questionIndex
      );

      let tryggaSvar = 0;
      let otryggaSvar = 0;

      for (const answer of questionAnswers) {
        const value = Number(answer.text);

        if (value === question.min) {
          tryggaSvar += 1;
        }

        if (value > question.min) {
          otryggaSvar += value * 0.5;
        }
      }

      const maxValue = Math.max(tryggaSvar, otryggaSvar);

      const normalizedResults = {
        trygga: tryggaSvar / maxValue,
        otrygga: otryggaSvar / maxValue
      };

      const totalValue = normalizedResults.trygga + normalizedResults.otrygga;

      return {
        title: question.location.name,
        image: question.location.imageUrl,
        rating: {
          green: normalizedResults.trygga / totalValue,
          red: normalizedResults.otrygga / totalValue
        }
      };
    });
  }

  render() {
    if (!this.state.isAllImagesLoaded) {
      return null;
    }

    const items = this.getPlatserWithAnswers(
      this.context.workshop.exercise,
      this.context.workshop.answers
    );

    return (
      <Container>
        <Transition
          native
          items={items}
          keys={(item: typeof items[number]) => item.title}
          trail={100}
          config={{ tension: 450, friction: 20 }}
          from={{ transform: "scale(0)" }}
          enter={{ transform: "scale(1)" }}
        >
          {(plats: typeof items[number]) => (props: React.CSSProperties) => {
            return (
              <animated.div style={props}>
                <Plats
                  fullWidth
                  image={plats.image}
                  key={plats.image}
                  title={plats.title}
                  rating={plats.rating}
                />
              </animated.div>
            );
          }}
        </Transition>
      </Container>
    );
  }
}
