import { Grid, Typography } from "@material-ui/core";
import { TryggKlass } from "friends-shared";
import React from "react";
import Activities from "../../../../lobby/Activities";
import {
  Bullets,
  DiscussionBox,
  DiscussionText
} from "../../../../lobby/Discussion";
import { Dashboard } from "../../common/Dashboard";
import SaveWordcloudAndActivites from "./components/SaveWordcloudAndActivities";

interface Props {
  slide: TryggKlass.Slide.Resultat;
}

export const Resultat: React.SFC<Props> = props => {
  return (
    <Dashboard steg={props.slide}>
      <Grid item xs={4}>
        <Grid container spacing={16}>
          <Grid item>
            <Typography
              className="typography-bold"
              color="primary"
              variant="h5"
              gutterBottom
            >
              1. Diskutera resultatet
            </Typography>

            <Typography paragraph>
              Inled ett samtal om resultatet, eleverna har också möjlighet att
              göra anonyma tillägg.
            </Typography>

            <Bullets>
              <li>
                Vad betyder de olika orden och vad är viktigt i just er klass?
              </li>
              <li>
                Vilka saker vill ni fokusera på, kan ni enas om tre saker?
              </li>
              <li>Hur kan ni arbeta för att uppnå detta?</li>
            </Bullets>
          </Grid>

          <Grid item>
            <DiscussionText />
            <DiscussionBox />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Activities noSaveButton>
          Skriv de tre saker som ni i klassen enats om och kort om hur ni ska
          arbeta för det. Exempelvis: "Vi ska påminna varandra om att ha ett
          schysst språkbruk och följa upp med en diskussion om hur det går på
          varje mentorstimme."
        </Activities>
      </Grid>

      <Grid item xs={4}>
        <SaveWordcloudAndActivites />
      </Grid>
    </Dashboard>
  );
};
